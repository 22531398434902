import React from "react";
import { useNavigate } from "react-router-dom";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ReactPlayer from "react-player";
import myVideo from "../../../assets/images/ToDoBoom-Video_Guide.mp4";

function UserGuide() {
  const navigate = useNavigate();
  return (
    <>
      <div className="wrapper innerPagesMain">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <NavigateBeforeIcon onClick={() => navigate(-1)} />
          </div>
          <h1>User Guide</h1>
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}

        <div className="policyContentMain">
          <div className="policyInrCont">
            <p className="moreContTx">
              Welcome to the{" "}
              <b style={{ color: "#333" }}>ToDoBoom User Guide!</b> Whether
              you're new to the app or need a quick refresher, this guide will
              help you make the most out of ToDoBoom's powerful features.
            </p>
          </div>

          <div className="policyInrCont">
            <ReactPlayer
              url={myVideo}
              // url="https://www.w3schools.com/html/mov_bbb.mp4"
              controls={true}
              playing={true}
              loop={true}
              volume={0.8}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default UserGuide;
