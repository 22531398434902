import React, { useState, useRef, useContext, useEffect } from "react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { json, useNavigate } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import "react-medium-image-zoom/dist/styles.css";
import FilterIcon from "@mui/icons-material/Filter";
import { format } from "date-fns";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { makePostRequest } from "../../../utils/utils";
import CloseIcon from "@mui/icons-material/Close";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

function NotificationPage() {
  const navigate = useNavigate();

  const swipeNSelection = (event) => {
    forSelectList(event);
    resetSwipeDirection(event);
  };

  const forSelectList = () => {
    var element = document.getElementById("listID");
    element.classList.toggle("selectedList");
  };

  // For Swipe Left Right
  const [swipeDirection, setSwipeDirection] = useState(null);
  const startXRef = useRef(null);
  const threshold = 50;

  const handleTouchStart = (e) => {
    startXRef.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    const currentX = e.touches[0].clientX;
    const distance = currentX - startXRef.current;

    if (Math.abs(distance) > threshold) {
      if (distance > 0) {
        setSwipeDirection("right");
      } else {
        setSwipeDirection("left");
      }
    }
  };

  const navigateToDetail = () => {
    const userType = localStorage.getItem("userType");
    if (userType == "Admin") {
      navigate("/SchedulPreview");
    } else {
      navigate("//ShiftSwapAndOffer");
    }
  };

  const handleTouchEnd = () => {
    // Optionally, you could reset the swipeDirection here if you want to clear it after some time
    // setSwipeDirection(null);
  };

  const resetSwipeDirection = () => {
    setSwipeDirection(null);
  };
  // Swipe Left Right End

  const [noti, setNoti] = React.useState([]);
  const getNotifications = async () => {
    let bodyFormData = {
      to_emp_id: JSON.parse(localStorage.getItem("userId")),
    };
    try {
      await makePostRequest("schedular/getNotifications", bodyFormData)
        .then((response) => {
          // alert(JSON.stringify(response.data.data));
          if (response.data.data) {
            console.log(response.data.data);
            setNoti(response.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error(`Error while requesting swap`, error);
    }
  };

  const delNotification=async(noti_id)=>{
    let bodyFormData = {
      noti_id: noti_id,
    };
    try {
      await makePostRequest("schedular/delNotification", bodyFormData)
        .then((response) => {
          // alert(JSON.stringify(response.data.data));
          if (response.data) {
            getNotifications();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error(`Error while requesting swap`, error);
    }
  }

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <>
      <div className="wrapper notificationPageContMain innerPagesMain">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <NavigateBeforeIcon onClick={() => navigate(-1)} />
          </div>
          <h1>Notifications</h1>
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}

        <div className="addAdminEmployeeMain">
          <h1 className="dayOfNotification">Today</h1>
          <div className="employyeListingMain notiNewOutDi">
            <>
              {noti.length > 0 &&
                noti.map((item, index) => {
                  return (
                    <div
                      //  onClick={()=>navigateToDetail()}
                      key={index}
                      className={`swipe-container ${
                        swipeDirection === "right" ? "swiped-right" : ""
                      }`}
                      onTouchStart={handleTouchStart}
                      onTouchMove={handleTouchMove}
                      onTouchEnd={handleTouchEnd}
                    >
                      <div className="deleEdiBtLeft">
                        <IconButton>
                          <DeleteIcon className="DeleteIcon" onClick={()=>delNotification(item.noti_id)}/>
                        </IconButton>
                      </div>
                      <div
                        className="emploTaskListInr"
                        id="listID"
                        onClick={swipeNSelection}
                      >
                        <div className="notificationInrData">
                          <h1>
                            {item.title}{" "}
                            <span>
                              <CalendarMonthIcon /> {format(new Date(item.datetime), "EEE, MMM d, yyyy")}{" "}
                              <em>
                                <AccessTimeIcon />{format(new Date(item.datetime), "hh:mm a")}{" "}
                              </em>
                            </span>
                          </h1>
                          <p>
                            {item.message}
                            {/* A swap request has been
                            initiated for location Main,
                            <b>shift time 3:00 PM-5:00 PM on Tuesday</b>,
                            2025-01-28. Please review and respond. */}
                          </p>
                        </div>
                        {/* <div>{item.message}</div> */}
                      </div>
                      <div className="deleEdiBtRight">
                        <IconButton>
                          <DeleteIcon className="DeleteIcon" />
                        </IconButton>
                      </div>
                    </div>
                  );
                })}
            </>

            {/* Commented By Kailas */}
            {/* <div
              className={`swipe-container ${
                swipeDirection === "right" ? "swiped-right" : ""
              }`}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            >
              <div className="deleEdiBtLeft">
                <IconButton>
                  <DeleteIcon className="DeleteIcon" />
                </IconButton>
              </div>
              <div
                className="emploTaskListInr"
                id="listID"
                onClick={swipeNSelection}
              >
                <div className="notificationInrData">
                  <h1>
                    New Swap Request
                    <span>
                      <CalendarMonthIcon /> 28-01-2025{" "}
                      <em>
                        <AccessTimeIcon /> 3:00PM - 5:00PM
                      </em>
                    </span>
                  </h1>
                  <p>
                    A swap request has been initiated for
                    location Main,
                    <b>shift time 3:00 PM-5:00 PM on Tuesday</b>, 2025-01-28.
                    Please review and respond.
                  </p>
                </div>
              </div>
              <div className="deleEdiBtRight">
                <IconButton>
                  <DeleteIcon className="DeleteIcon" />
                </IconButton>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default NotificationPage;
