import React, { useEffect, useState } from "react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import avtarPic from "../../../assets/images/avtarPic.png";
import avtarPic2 from "../../../assets/images/Profile-Picture.jpg";

// Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
// End

import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import picPro from "../../../assets/images/avtarPic.png";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Button, Grid } from "@mui/material";

import { makePostRequest } from "../../../utils/utils";
import moment from "moment";
import swal from "sweetalert";

import Slider from "react-slick";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SwapVerticalCircleIcon from "@mui/icons-material/SwapVerticalCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import picAv from "../../../assets/images/Profile-Picture.jpg";
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";
import ArrowDownwardTwoToneIcon from "@mui/icons-material/ArrowDownwardTwoTone";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import UserBottom from "../UserBottom/UserBottom";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import swap from "../../../assets/images/swap.svg";
import forword from "../../../assets/images/forwardall.svg";
import swapWhite from "../../../assets/images/swapwhite.svg";
import forwordWhite from "../../../assets/images/offer-white.svg";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { disableTime } from "rsuite/esm/internals/utils/date";
import { TrendingUp } from "@mui/icons-material";
import WavingHandIcon from "@mui/icons-material/WavingHand";
import SwipeIcon from "@mui/icons-material/Swipe";

function ShiftSwapAndOffer() {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = React.useState(false);
  // Function to handle scroll
  const scrollToTarget = (targetId) => {
    // Get the target element
    const target = document.getElementById(targetId);

    // Scroll smoothly to the target element
    target.scrollIntoView({
      behavior: "smooth",
      block: "start", // Scroll to the top of the target section
    });

    // After scrolling, adjust the scroll position to stop at a fixed height
    setTimeout(() => {
      // Set a fixed offset to stop the scroll at a fixed height (e.g., 100px from the top)
      const fixedOffset = 150; // 100px offset from the top
      window.scrollBy(0, -fixedOffset); // Scroll up by 100px to stop at the desired position
    }, 1000); // Delay to ensure scroll finishes before adjusting
  };

  // function swapDrawerCl() {
  //   var element = document.getElementById("swapId");
  //   element.classList.toggle("addSwap");
  // }

  // Function to toggle the drawer
  const swapDrawerCl = (reqtype) => {
    const element = document.getElementById("swapId");
    // if (element) {
    //   element.classList.toggle("addSwap");
    // }

    if (element) {
      if (reqtype == "swap") {
        console.log("Adding 'addSwap' class");
        element.classList.add("addSwap");
        element.classList.add("openBotDrawCl");
      } else {
        console.log("Removing 'addSwap' class");
        element.classList.remove("addSwap");
        element.classList.remove("openBotDrawCl");
        swal("Info", "Please select a shift to offer", "info");
      }
    } else {
      console.error("Element with ID 'swapId' not found");
    }
  };

  function openBotDraw() {
    var element = document.getElementById("swapId");
    element.classList.toggle("openBotDrawCl");
  }

  function hideShowdrwrdata() {
    var element = document.getElementById("hideShoId");
    element.classList.toggle("hideShowdrwrSt");
  }

  // For Toggle Button
  const [alignment, setAlignment] = React.useState("");
  const [reqtype, setReqType] = React.useState("");
  // const handleChange = (event, newAlignment) => {
  //   setAlignment(newAlignment);
  //   setReqType(event.target.value);
  //   if(event.target.value=='offer')
  //   {
  //     swapDrawerCl();
  //   }
  // };

  // Handle button toggle and request type
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment); // Set active button
    setReqType(newAlignment); // Set reqtype based on the selected button
    //  alert(newAlignment)
    // Only open the drawer when "swap" is clicked
    // if (newAlignment == "swap") {
    swapDrawerCl(newAlignment);
    // }
  };

  // End
  const searchEmp = (e, location) => {
    const searchTerm = e.target.value.toLowerCase(); // Convert to lowercase for case-insensitive matching

    // If the search term is empty, restore the original data for the specified location
    if (!searchTerm) {
      setViewData((prevData) => {
        const updatedData = { ...prevData };
        updatedData[location] = originalData[location]; // Restore original data for the location
        return updatedData;
      });
      return;
    }

    // Apply filter for the specified location
    setViewData((prevData) => {
      const updatedData = { ...prevData };

      // Filter employees for the specified location based on the search term
      updatedData[location] = originalData[location].filter((employee) =>
        employee.full_name.toLowerCase().includes(searchTerm)
      );

      return updatedData;
    });
  };

  const [srchloc, setSrchLoc] = useState("");
  const searchSlide = (index, location) => {
    // var element = document.getElementById("searchSlideId"+index);
    // element.classList.toggle("searchSlideClass");
    setSrchLoc(location);
    var element = document.getElementById("searchSlideId" + index);
    if (element) {
      element.classList.toggle("searchSlideClass");
    } else {
      console.error(`Element with ID "searchSlideId${index}" not found.`);
    }
  };

  const settings = {
    adaptiveHeight: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // For Schedul Preview
  const [Preview, setOpenPreview] = React.useState(false);

  const handleClickPreview = () => {
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };
  // End

  const [loginshiftchecked, setLoginShiftChecked] = React.useState("");
  const [othershiftchecked, setOtherShiftChecked] = React.useState("");
  const [shiftdisabled, setShiftDisabled] = React.useState(true);
  const handleShiftSwapChecked = (event, flag) => {
    setShiftDisabled(!event.target.checked);

    if (event.target.checked) {
      if (flag == "source") {
        setLoginShiftChecked(event.target.value);
      } else {
        setOtherShiftChecked(event.target.value);
      }
    } else {
      if (flag == "source") {
        setLoginShiftChecked("");
      } else {
        setOtherShiftChecked("");
      }
    }
  };

  const convertStartStringTime = () => {
    let consplit = localStorage.getItem("loginEmpShiftTime");
    if (consplit != "") {
      let splitstring = consplit.split("-");
      const startTime = splitstring[0].trim(); // Output: "4:00 PM"
      const formattedTime = moment(startTime, "h:mm A").format("HH:mm:ss");
      return formattedTime;
    } else {
      return "";
    }
  };

  const convertEndStringTime = () => {
    let consplit = localStorage.getItem("loginEmpShiftTime");
    if (consplit != "") {
      let splitstring = consplit.split("-");
      const endTime = splitstring[1].trim(); // Output: "6:00 PM"
      const formattedTime = moment(endTime, "h:mm A").format("HH:mm:ss");
      return formattedTime;
    } else {
      return "";
    }
  };

  const handleRequestSwap = async (sourceswap, destswap) => {
    let bodyFormData = {};
    let msg = "";
    let msgsuccess = "";

    console.log(sourceswap);
    console.log(destswap);

    setShowLoader(true);
    // return false;

    if (reqtype == "offer") {
      msg = `You want to ${reqtype} this shift?`;
      msgsuccess = `Offer request is send to all employees successfully, You will be notified soon once any of the employee accepts the request and your manager approves it.`;

      bodyFormData = {
        request_type: reqtype,
        requestfromempid: sourceswap.id,
        sourcelocation: sourceswap.location,
        destlocation: sourceswap.location,
        sourceshifttime: loginshiftchecked,
        destshifttime: loginshiftchecked,
        sourceweekday: sourceswap.weekday,
        destweekday: sourceswap.weekday,
        sourceweekdate: sourceswap.weekdate,
        destweekdate: sourceswap.weekdate,
        requesttoempid: 0,
      };
    } else {
      msg = `You want to ${reqtype} this shift with ${destswap.name}.`;
      msgsuccess = `Swap request is send to ${destswap.name} successfully, you will be notified soon once ${destswap.name} accepts the request and your manager approves it.`;

      bodyFormData = {
        request_type: reqtype,
        requestfromempid: sourceswap.id,
        sourcelocation: sourceswap.location,
        destlocation: destswap.location,
        sourceshifttime: loginshiftchecked,
        destshifttime: othershiftchecked,
        sourceweekday: sourceswap.weekday,
        destweekday: destswap.weekday,
        sourceweekdate: sourceswap.weekdate,
        destweekdate: destswap.weekdate,
        requesttoempid: destswap.id,
      };
    }

    console.log(bodyFormData);

    swal({
      title: "Are you sure?",
      text: msg,
      icon: "info",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          await makePostRequest("schedular/InsertSwapRequest", bodyFormData)
            .then((response) => {
              if (response.data) {
                setShowLoader(false);
                swal("Success", msgsuccess, "success", {
                  // buttons: true,
                });
                //  window.location.reload();
              }
              window.location.reload();
              // getWeeklyEmployeeStatusConsolidatedView("",dateRange.startDate,dateRange.endDate);
              //  getSwapOfferRequests();
              // setAlignment("");
              // setReqType("");
              // handleCancel();
            })
            .catch((err) => {
              console.log(err);
              setShowLoader(false);
              window.location.reload();
            });
        } catch (error) {
          console.error(`Error while requesting swap`, error);
          setShowLoader(false);
          window.location.reload();
        }
      } else {
        //swal("Your file is safe!");
        setShowLoader(false);
        window.location.reload();
      }
    });
  };

  const handleCancel = () => {
    window.location.reload();
    // setLoginShiftChecked("");
    // setOtherShiftChecked("");
    // setSourceSwap({});
    // setDestinationSwap({});
    // setAlignment("");
    // setReqType("");
    // hideShowdrwrdata();
    // getWeeklyEmployeeStatusConsolidatedView(
    //   "",
    //   dateRange.startDate,
    //   dateRange.endDate
    // );
  };

  const requestClosed = async (empswapofferid, usertype) => {
    let obj = {
      empswapofferid,
      usertype,
    };

    await makePostRequest("schedular/swapRequestClosedUser", obj)
      .then((response) => {
        if (response.data) {
          handleCancel();
          getSwapOfferRequests();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const CancelRequest = async (empswapofferid) => {
    let empidaction = localStorage.getItem("userId");
    let obj = {
      empswapofferid,
    };

    await makePostRequest("schedular/CancelRequest", obj)
      .then((response) => {
        let message;

        if (response.data) {
          swal("Success", "Request cancelled successfully", "success");
          // window.location.reload();
        }
        handleCancel();
        getSwapOfferRequests();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const requestSwapActionUser = async (empswapofferid, status) => {
    let empidaction = localStorage.getItem("userId");
    let obj = {
      empswapofferid,
      empidaction,
      status,
    };

    await makePostRequest("schedular/swapRequestApprovalUser", obj)
      .then((response) => {
        let message;
        if (status)
          message = `Request accepted successfully, you will be notified soon once your manager approves it.`;
        else message = `Request rejected`;

        if (response.data) {
          swal("Success", message, "success");
        }
        handleCancel();
        getSwapOfferRequests();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [viewdata, setViewData] = React.useState([]);
  const [originalData, setOriginalData] = useState(null); // State to hold original data
  const [loc, setLoc] = React.useState([]);
  let arr = [];
  const getWeeklyEmployeeStatusConsolidatedView = async (
    location,
    startDate,
    endDate
  ) => {
    let bodyFormData = {
      location: location,
      startDate: startDate,
      endDate: endDate,
    };
    await makePostRequest(
      "schedular/getWeeklyEmployeeStatusConsolidatedView",
      bodyFormData
    )
      .then((response) => {
        if (response.data) {
          console.log(">>> ", response.data.data.data);
          setViewData(response.data.data.data);
          setOriginalData(response.data.data.data);
          Object.keys(response.data.data.data).forEach((location) => {
            arr.push(location);
          });
          setLoc(arr);
        } else {
          setViewData([]);
          setOriginalData([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setViewData([]);
      });
  };

  const [request, setRequest] = React.useState([]);
  const [requestfrom, setRequestFrom] = React.useState([]);

  const [shiftStartTime, setShiftStartTime] = useState(
    convertStartStringTime()
  );
  const [shiftEndTime, setShiftEndTime] = useState(convertEndStringTime());
  const [currentTime, setCurrentTime] = useState(moment().format("HH:mm:ss"));

  const getSwapOfferRequests = async () => {
    let bodyFormData = {
      emp_id: parseInt(localStorage.getItem("userId")),
    };

    //  console.log(bodyFormData);

    await makePostRequest("schedular/getSwapOfferRequests", bodyFormData)
      .then((response) => {
        if (response.data) {
          console.log(response.data.data);
          setRequest(response.data.data.requestto);
          setRequestFrom(response.data.data.requestfrom);
        } else {
          setRequest([]);
          setRequestFrom([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Define start and end dates

  // Define `dateRange` with initial values as today's week range
  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf("week").add(1, "days").format("YYYY-MM-DD"),
    endDate: moment().endOf("week").add(1, "days").format("YYYY-MM-DD"),
  });
  const startDate = dateRange.startDate;
  const endDate = dateRange.endDate;

  // Loop through dates from start to end
  const start = moment(startDate, "YYYY-MM-DD"); // Ensure the format is correct
  const end = moment(endDate, "YYYY-MM-DD"); // Ensure the format is correct
  const daysArray = [];

  // Create an array with formatted dates
  for (let m = start.clone(); m.isSameOrBefore(end); m.add(1, "day")) {
    // Adjusting the day of the week: moment returns 0 (Sunday) to 6 (Saturday),
    // but we want Monday as 1, and Sunday as 7
    let dayOfWeek = m.format("d"); // Day of the week number (0 = Sunday, 1 = Monday, etc.)
    dayOfWeek = dayOfWeek === "0" ? "7" : dayOfWeek; // Make Sunday '7'

    const formattedDay = m.format("MM/DD"); // Date in MM/DD format
    const formattedDayOfWeek = m.format("ddd"); // Day of the week abbreviation (Mon, Tue, Wed, etc.)

    // Push the formatted values to the array
    daysArray.push({
      dayOfWeek: dayOfWeek,
      formattedDate: formattedDay,
      formattedDayOfWeek,
    });
  }

  console.log(daysArray);
  const [loginemp, setLoginEmp] = React.useState();

  const autoLogoutFunction = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/EmployeeLogin");
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment().format("HH:mm:ss"));
    }, 1000); // Update current time every second

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const shiftStartMoment = moment(shiftStartTime, "HH:mm:ss");
    const shiftEndMoment = moment(shiftEndTime, "HH:mm:ss");
    const currentMoment = moment(currentTime, "HH:mm:ss");

    if (currentMoment.isAfter(shiftEndMoment)) {
      // Auto-logout functionality
      console.log("Auto-logout triggered!");
      autoLogoutFunction();
      // Clear user session, remove authentication tokens, and redirect to login page
    }
  }, [currentTime, shiftStartTime, shiftEndTime]);

  useEffect(() => {
    setLoginEmp(localStorage.getItem("userId"));
    getWeeklyEmployeeStatusConsolidatedView(
      "",
      dateRange.startDate,
      dateRange.endDate
    );
    getSwapOfferRequests();
  }, []);

  // Swap Shift Moddal
  const [Swap, setOpenSwap] = React.useState(false);
  const [loginEmpShiftInfo, setLoginEmpShiftInfo] = React.useState({
    location: "",
    assinged_shifts: [],
    weekday: "",
    weekdate: "",
  });
  const handleClickSwap = (obj) => {
    console.log(obj);
    if (reqtype == "" || reqtype == undefined) {
      swal({
        title: "Warning",
        text: "Please select swap/offer first",
        icon: "warning",
        // buttons: true,
        dangerMode: true,
      });
    } else {
      setLoginEmpShiftInfo(obj);
      setShiftDisabled(true);
      setOpenSwap(true);
    }
  };

  const [sourceswap, setSourceSwap] = React.useState({});
  const [destswap, setDestinationSwap] = React.useState({});
  const SourceSwapSelect = (obj) => {
    if (reqtype == "swap") {
      if (obj.flag == "source") {
        setSourceSwap(obj);
        setOpenSwap(false);
      }

      if (obj.flag == "destination") {
        setDestinationSwap(obj);
        setOpenSwap(false);
        setTimeout(() => {
          hideShowdrwrdata();
        }, 2000);
      }
    }

    if (reqtype == "offer") {
      setSourceSwap(obj);
      setDestinationSwap(obj);
      handleRequestSwap(obj, obj);
      setOpenSwap(false);
      // setTimeout(() => {
      //   hideShowdrwrdata();
      // }, 2000);
    }
  };

  const handleCloseSwap = () => {
    setOpenSwap(false);
  };

  const handleCloseDrawer = async () => {
    const willDelete = await swal({
      title: "Cancel swap or offer request",
      text: "This will cancel the request.",
      icon: "warning",
      buttons: ["Cancel", "Ok"],
    });

    if (willDelete) {
      window.location.reload();
    }
  };
  // End

  // Another Swap Shift Moddal
  const [AnotherSwap, setOpenAnotherSwap] = React.useState(false);

  const handleClickAnotherSwap = () => {
    setOpenAnotherSwap(true);
  };

  const handleCloseAnotherSwap = () => {
    setOpenAnotherSwap(false);
  };
  // End

  const settings2 = {
    focusOnSelect: true,
    slidesToScroll: 1,
    className: "center",
    centerMode: false,
    infinite: false,
    centerPadding: "0px",
    slidesToShow: 7,
    speed: 500,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getMondays = (numWeeks) => {
    const mondays = [];
    let currentDate = moment();

    currentDate = moment();
    //  if (availweekstartdate == "") {
    //    currentDate = moment();
    //  } else {
    //    currentDate = moment(availweekstartdate);
    //  }

    // Find the previous Monday (or current day if it's Monday)
    const currentMonday = currentDate.startOf("week").add(1, "days");
    // const nextMonday = currentDate.startOf("week").add(1, "weeks").add(1, "days");

    for (let i = 0; i < numWeeks; i++) {
      mondays.push(currentMonday.clone().add(i * 7, "days"));
    }

    return mondays;
  };

  // Generate an array of Mondays for the next 'n' weeks
  const mondays = getMondays(100); // Adjust the number of weeks if needed

  const currentMonday = moment().startOf("week").add(1, "days"); // Get current week's Monday
  const currentWeekStartDate = currentMonday.format("YYYY-MM-DD"); // Start date
  const currentWeekEndDate = currentMonday
    .clone()
    .add(6, "days")
    .format("YYYY-MM-DD"); // End date

  console.log(">>", currentWeekStartDate);

  // Find the index of the current week's Monday in the mondays array
  const currentMondayIndex = mondays.findIndex(
    (monday) => monday.format("YYYY-MM-DD") === currentWeekStartDate
  );

  // setCurrentWeekIndex(currentMondayIndex);

  const handleMondayChange = (newMonday) => {
    const startDate = newMonday.format("YYYY-MM-DD");
    const endDate = newMonday.clone().add(6, "days").format("YYYY-MM-DD");
    // mondays = getMondays(selectedMonday, 1);
    setDateRange({ startDate, endDate }); // Update state with the new date range

    getWeeklyEmployeeStatusConsolidatedView("", startDate, endDate);
  };

  return (
    <>
      <div className="wrapper innerPagesMain" style={{ paddingBottom: 0 }}>
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch" style={{ height: 65 }}></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <NavigateBeforeIcon onClick={() => navigate(-1)} />
          </div>
          <h1>Shift Schedule</h1>
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}
      </div>

      <div className="schedlPreviewMain" id="swapId">
        <div className="forNotificationMain">
          <Slider {...settings}>
            {request.length > 0 &&
              request.map((req, index) => {
                if (
                  loginemp != req.requestfromempid &&
                  req.destempaction === null
                ) {
                  return (
                    <div key={index}>
                      <div className="notificationMain">
                        {((req.adminid != 0 && req.adminaction != null) ||
                          req.destempaction == false) && (
                          <CloseIcon
                            className="statusCls"
                            onClick={() =>
                              requestClosed(req.empswapofferid, "destination")
                            }
                          />
                        )}
                        <div
                          className="notificationTp"
                          style={{ marginBottom: 9 }}
                        >
                          <div className="notificationProp">
                            <img src={picAv} />
                          </div>
                          {req.request_type === "swap" ? (
                            <div>
                              <h1>
                                {req.full_name}{" "}
                                <span>
                                  want’s to swap a shift with you. Below are the
                                  details:
                                </span>
                              </h1>
                              <div className="swapDataMain">
                                <div
                                  className="swapDatTop wantToSwapMain swpDtNew"
                                  style={{ paddingTop: 15 }}
                                >
                                  <label className="notifLocation">
                                    {req.sourcelocation}
                                  </label>
                                  <div className="swapDatInr">
                                    <div className="assignShiProLine">
                                      <div className="swapShiRight">
                                        <h1>
                                          {req.sourceweekday}{" "}
                                          {req.sourceweekdate}
                                        </h1>
                                        <p>{req.sourceshifttime}</p>
                                      </div>
                                    </div>
                                    <SwapHorizontalCircleIcon className="swpIc" />
                                  </div>
                                  <div className="swapDatInr">
                                    <div className="assignShiProLine">
                                      <div className="swapShiRight">
                                        <h1>
                                          {req.destweekday} {req.destweekdate}
                                        </h1>
                                        <p>{req.destshifttime}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            // code for offer
                            <>
                              <h1>
                                {req.full_name}{" "}
                                <span>
                                  send an {req.request_type} request. Below are
                                  the details:
                                </span>
                              </h1>
                            </>

                            //end code
                          )}
                        </div>
                        {req.request_type === "offer" && (
                          <div className="swapDataMain">
                            <div
                              className="swapDatTop wantToSwapMain swpDtNew"
                              style={{ paddingTop: 15 }}
                            >
                              <label className="notifLocation">
                                {req.sourcelocation}
                              </label>
                              <div className="swapDatInr">
                                <div className="assignShiProLine forSingleShift">
                                  <div className="swapShiRight">
                                    <h1>
                                      <CalendarMonthIcon /> {req.sourceweekday}{" "}
                                      {req.sourceweekdate}
                                    </h1>
                                    <p>
                                      <AccessTimeIcon /> {req.sourceshifttime}
                                    </p>
                                  </div>
                                </div>

                                {/* <SwapHorizontalCircleIcon className="swpIc" /> */}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="rejNAcepBts">
                          {req.request_type === "swap" && (
                            <Button
                              onClick={() =>
                                requestSwapActionUser(req.empswapofferid, false)
                              }
                            >
                              Reject
                            </Button>
                          )}
                          <Button
                            onClick={() =>
                              requestSwapActionUser(req.empswapofferid, true)
                            }
                            style={{ color: "#0FB000" }}
                          >
                            Accept
                          </Button>
                        </div>
                      </div>
                    </div>
                  );
                } else if (
                  loginemp == req.requesttoempid &&
                  req.destempaction === true
                ) {
                  return (
                    <div key={index}>
                      <div className="notificationMain">
                        {((req.adminid != 0 && req.adminaction != null) ||
                          req.destempaction == false) && (
                          <CloseIcon
                            className="statusCls"
                            onClick={() =>
                              requestClosed(req.empswapofferid, "destination")
                            }
                          />
                        )}
                        <div
                          className="notificationTp"
                          style={{ marginBottom: 9 }}
                        >
                          <div className="notificationProp">
                            <img src={picAv} />
                          </div>
                          {req.request_type === "swap" ? (
                            <div>
                              <h1>
                                {req.destempaction === true &&
                                  req.adminaction === null && (
                                    <span>
                                      You accepted the <b>swap request</b> send
                                      by <b>{req.full_name}</b>. Your manager
                                      will approve it. Below are the details:
                                    </span>
                                  )}

                                {req.destempaction === true &&
                                  req.adminaction === true && (
                                    <span>
                                      Your manager approved the{" "}
                                      <b>swap request</b> send by{" "}
                                      <b>{req.full_name}</b> to you. Below are
                                      the details:
                                    </span>
                                  )}

                                {req.destempaction === true &&
                                  req.adminaction === false && (
                                    <span>
                                      Your manager refused the{" "}
                                      <b>swap request</b> send by{" "}
                                      <b>{req.full_name}</b> to you. Below are
                                      the details:
                                    </span>
                                  )}
                              </h1>
                            </div>
                          ) : (
                            <h1>
                              {req.destempaction === true &&
                                req.adminaction === null && (
                                  <span>
                                    You accepted the <b>offer request</b> send
                                    by <b>{req.full_name}</b>. Your manager will
                                    approve it. Below are the details:
                                  </span>
                                )}

                              {req.destempaction === true &&
                                req.adminaction === true && (
                                  <span>
                                    Your manager approved the{" "}
                                    <b>offer request</b> send by{" "}
                                    <b>{req.full_name}</b> to you. Below are the
                                    details:
                                  </span>
                                )}

                              {req.destempaction === true &&
                                req.adminaction === false && (
                                  <span>
                                    Your manager refused the{" "}
                                    <b>offer request</b> send by{" "}
                                    <b>{req.full_name}</b> to you. Below are the
                                    details:
                                  </span>
                                )}
                            </h1>
                          )}
                        </div>
                        {req.request_type === "swap" && (
                          <div className="swapDataMain">
                            <div
                              className="swapDatTop wantToSwapMain swpDtNew"
                              style={{ paddingTop: 15 }}
                            >
                              <label className="notifLocation">
                                {req.sourcelocation}
                              </label>
                              <div className="swapDatInr">
                                <div className="assignShiProLine">
                                  <div
                                    className="swapShiRight"
                                    style={{ alignItems: "stretch" }}
                                  >
                                    <h1>
                                      {req.sourceweekday} {req.sourceweekdate}
                                    </h1>
                                    <p>{req.sourceshifttime}</p>
                                  </div>
                                </div>
                                <SwapHorizontalCircleIcon className="swpIc" />
                              </div>
                              <div className="swapDatInr">
                                <div className="assignShiProLine">
                                  <div
                                    className="swapShiRight"
                                    style={{ alignItems: "stretch" }}
                                  >
                                    <h1>
                                      {req.destweekday} {req.destweekdate}
                                    </h1>
                                    <p>{req.destshifttime}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* new code for offer */}
                        {req.request_type === "offer" && (
                          <div className="swapDataMain">
                            <div
                              className="swapDatTop wantToSwapMain swpDtNew"
                              style={{ paddingTop: 15 }}
                            >
                              <label className="notifLocation">
                                {req.sourcelocation}
                              </label>
                              <div className="swapDatInr">
                                <div className="assignShiProLine forSingleShift">
                                  <div className="swapShiRight">
                                    <h1>
                                      <CalendarMonthIcon /> {req.sourceweekday}{" "}
                                      {req.sourceweekdate}
                                    </h1>
                                    <p>
                                      <AccessTimeIcon /> {req.sourceshifttime}
                                    </p>
                                  </div>
                                </div>

                                {/* <SwapHorizontalCircleIcon className="swpIc" /> */}
                              </div>
                            </div>
                          </div>
                        )}
                        {/* end code */}

                        {req.destempaction === true &&
                          req.adminaction === null && (
                            <h5 className="statusPenOrRej pendingStaus">
                              Pending
                            </h5>
                          )}

                        {req.destempaction === true &&
                          req.adminaction === true && (
                            <h5 className="statusPenOrRej approvedStatus">
                              Approved By Manager
                            </h5>
                          )}

                        {req.destempaction === true &&
                          req.adminaction === false && (
                            <h5 className="statusPenOrRej">
                              Refused By Manager
                            </h5>
                          )}
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })}

            {/* new code added by yamini for showing status */}
            {requestfrom.length > 0 &&
              requestfrom.map((req, index) => {
                return loginemp == req.requestfromempid ? (
                  <div className="forNotiTablet">
                    <div
                      className="notificationMain"
                      style={{ marginBottom: 0 }}
                    >
                      {((req.adminid != 0 && req.adminaction != null) ||
                        req.destempaction == false) && (
                        <CloseIcon
                          className="statusCls"
                          onClick={() =>
                            requestClosed(req.empswapofferid, "source")
                          }
                        />
                      )}
                      <div
                        className="notificationTp"
                        style={{ marginBottom: 9 }}
                      >
                        <div className="notificationProp">
                          <img src={picAv} />
                        </div>
                        {req.request_type == "swap" ? (
                          <>
                            <div>
                              <h1>
                                {/* {req.full_name}{" "} */}
                                <span>
                                  You have a <b>swap request</b>{" "}
                                  <span>with </span>
                                  <b>{req.tofull_name}</b>. Below are the
                                  details:
                                </span>{" "}
                              </h1>
                            </div>
                          </>
                        ) : (
                          <h1>
                            {/* {req.full_name}{" "} */}
                            <span>
                              You send an {req.request_type} request. Below are
                              the details:
                            </span>
                          </h1>
                        )}
                      </div>
                      {req.request_type == "swap" && (
                        <>
                          <div className="swapDataMain">
                            <div
                              className="swapDatTop wantToSwapMain swpDtNew"
                              style={{ paddingTop: 15 }}
                            >
                              <label className="notifLocation">
                                {req.sourcelocation}
                              </label>
                              <div className="swapDatInr">
                                <div className="assignShiProLine">
                                  <div
                                    className="swapShiRight"
                                    style={{ alignItems: "stretch" }}
                                  >
                                    <h1>
                                      {req.sourceweekday} {req.sourceweekdate}
                                    </h1>
                                    <p>{req.sourceshifttime}</p>
                                  </div>
                                </div>
                                <SwapHorizontalCircleIcon className="swpIc" />
                              </div>

                              <div className="swapDatInr">
                                <div className="assignShiProLine">
                                  <div
                                    className="swapShiRight"
                                    style={{ alignItems: "stretch" }}
                                  >
                                    <h1>
                                      {req.destweekday} {req.destweekdate}
                                    </h1>
                                    <p>{req.destshifttime}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* // new code 21jan25 */}
                          {req.destempaction === null &&
                            req.adminaction === null && (
                              <div className="rejNAcepBts">
                                <Button
                                  onClick={() =>
                                    CancelRequest(req.empswapofferid)
                                  }
                                  style={{ color: "red" }}
                                >
                                  Cancel Request
                                </Button>
                              </div>
                            )}
                        </>
                        //end code
                      )}
                      {/* new code for offer new design */}
                      {req.request_type === "offer" && (
                        <>
                          <div className="swapDataMain">
                            <div
                              className="swapDatTop wantToSwapMain swpDtNew"
                              style={{ paddingTop: 15 }}
                            >
                              <label className="notifLocation">
                                {req.sourcelocation}
                              </label>
                              <div className="swapDatInr">
                                <div className="assignShiProLine forSingleShift">
                                  <div className="swapShiRight">
                                    <h1>
                                      <CalendarMonthIcon /> {req.sourceweekday}{" "}
                                      {req.sourceweekdate}
                                    </h1>
                                    <p>
                                      <AccessTimeIcon /> {req.sourceshifttime}
                                    </p>
                                  </div>
                                </div>

                                {/* <SwapHorizontalCircleIcon className="swpIc" /> */}
                              </div>
                            </div>
                          </div>

                          {/* // new code 21jan25 */}
                          {req.destempaction === null &&
                            req.adminaction === null && (
                              <div className="rejNAcepBts">
                                <Button
                                  onClick={() =>
                                    CancelRequest(req.empswapofferid)
                                  }
                                  style={{ color: "red" }}
                                >
                                  Cancel Request
                                </Button>
                              </div>
                            )}
                        </>
                        //end code
                      )}
                      {/* end code */}
                      {req.destempaction === null &&
                        req.adminaction === null && (
                          <h5 className="statusPenOrRej pendingStaus">
                            Pending
                          </h5>
                        )}
                      {req.destempaction === true &&
                        req.adminaction === null && (
                          <h5 className="statusPenOrRej acceptedStatus">
                            Accepted By{" "}
                            {req.tofull_name != null &&
                              req.tofull_name.split(" ")[0]}
                          </h5>
                        )}
                      {req.destempaction === true &&
                        req.adminaction === true && (
                          <h5 className="statusPenOrRej approvedStatus">
                            Approved By Manager
                          </h5>
                        )}
                      {req.destempaction === false && (
                        <h5 className="statusPenOrRej rejectedStatus">
                          Rejected By{" "}
                          {req.tofull_name != null &&
                            req.tofull_name.split(" ")[0]}
                        </h5>
                      )}
                      {req.destempaction === true &&
                        req.adminaction === false && (
                          <h5 className="statusPenOrRej">Refused By Manager</h5>
                        )}
                    </div>
                  </div>
                ) : null;
              })}
            {/* end code new code */}
          </Slider>
        </div>

        <div className="schedledLin">
          {/* Shift schedule for week{" "}
          <b>
            {startDate} - {endDate}
          </b> */}
          <Grid item xs={12} md={12}>
            <div className="mySlik createScheduSlik">
              <div className="slider-container">
                <Slider
                  {...settings2}
                  // initialSlide={currentweekindex} // Set the initial slide to current Monday
                  afterChange={(index) => handleMondayChange(mondays[index])}
                >
                  {mondays.map((monday, index) => (
                    <div key={index}>
                      <h3>Mon</h3>
                      <span>{monday.format("MM/DD/YYYY")}</span>{" "}
                      {/* Format the date as desired */}
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </Grid>
        </div>

        <div className="selectSwapOffLab">
          <label className="blink_Label">Select Swap / Offer</label>
        </div>
        <div className="swapOfrBtnOuter">
          <div className="swapOfrBtnMain">
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton value="swap">
                <img src={swap} className="swpBlc" />
                <img src={swapWhite} className="swpWt" /> Swap
                <SwipeIcon className="swapAnimHand" />
              </ToggleButton>
              <ToggleButton value="offer">
                <img src={forword} className="swpBlc" />
                <img src={forwordWhite} className="swpWt" /> Offer
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        {loc.length > 0 &&
          loc
            .filter((item) => item !== "No Location")
            .map((item, index) => (
              <div className="daySlider emplShifSlider schedPreviewSlideMain">
                <h5
                  className="stickHeading"
                  style={{ justifyContent: "space-between" }}
                >
                  <Button></Button>
                  {item}{" "}
                  <Button onClick={() => scrollToTarget("target2")}>
                    Next <ArrowDownwardTwoToneIcon />
                  </Button>
                </h5>

                <div className="schedulPreviewTblMain" id={`target${index}`}>
                  <div
                    className="tblSearchEmp"
                    key={index}
                    id={`searchSlideId${index}`}
                  >
                    <SearchIcon />
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => searchEmp(e, item)}
                    />
                  </div>
                  <div class="table-wrapper">
                    <table width="100%">
                      <thead>
                        <tr>
                          <th class="fixed-column">
                            <div
                              className="tblSearchIcoOt"
                              onClick={() => searchSlide(index, item)}
                            >
                              <PersonSearchIcon />
                            </div>
                          </th>
                          {daysArray.map((day) => (
                            <th>
                              <p>
                                <span>{day.formattedDayOfWeek}</span>{" "}
                                <span>{day.formattedDate}</span>
                              </p>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {viewdata[item].map((empinfo, index) => (
                          <tr
                            className={
                              empinfo.employee_id == loginemp
                                ? "activeRow"
                                : null
                            }
                          >
                            <td class="fixed-column">
                              <p className="dayNDateCol">
                                <h1>{empinfo.full_name}</h1>
                              </p>
                            </td>
                            {empinfo.weekly_status.length > 0 &&
                              empinfo.weekly_status.map((week, indexweek) => (
                                <>
                                  {week.status == "A" ? (
                                    <td className="selctedSwap">
                                      <div
                                        className="swapEmpDiv"
                                        onClick={
                                          empinfo.employee_id == loginemp
                                            ? () =>
                                                handleClickSwap({
                                                  id: empinfo.employee_id,
                                                  name: empinfo.full_name,
                                                  location: item,
                                                  weekday: week.weekday,
                                                  weekdate: week.weekdate,
                                                  assinged_shifts:
                                                    week.assigned_shift_times,
                                                  flag: "source",
                                                })
                                            : loginshiftchecked
                                            ? () =>
                                                handleClickSwap({
                                                  id: empinfo.employee_id,
                                                  name: empinfo.full_name,
                                                  location: item,
                                                  weekday: week.weekday,
                                                  weekdate: week.weekdate,
                                                  assinged_shifts:
                                                    week.assigned_shift_times,
                                                  flag: "destination",
                                                })
                                            : undefined
                                        }
                                      >
                                        <h1>{week.status}</h1>
                                        <AddCircleIcon className="sbIcon" />
                                        {week.assigned_shift_times.length > 0 &&
                                          week.assigned_shift_times.map(
                                            (shift, indexshift) => (
                                              <>
                                                <span>{shift}</span>
                                              </>
                                            )
                                          )}
                                      </div>
                                    </td>
                                  ) : (
                                    <td>
                                      <div className="swapEmpDiv standBy">
                                        <h1>{week.status}</h1>
                                      </div>
                                    </td>
                                  )}
                                </>
                              ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ))}
        {/* <div className="patchForSwapDraw"></div> */}

        <div className="swapDrawerMain" id="hideShoId">
          <h3 className="indicatSwapHead">
            {/* {*onClick={openBotDraw}*} */}
            Select a shift to swap{" "}
            {/* <KeyboardArrowUpIcon className="drwrIcoArro" /> */}
          </h3>
          <CloseIcon
            className="bottViewMainClose"
            onClick={handleCloseDrawer}
          />

          <div className="underProcessDataMain hideD2">
            <h4>
              Click on the shift you want to swap{" "}
              <WavingHandIcon className="handBy" />
            </h4>
            {loginshiftchecked != "" && sourceswap && (
              <>
                <div className="swapDatInr">
                  <div className="assignShiProLine">
                    <div className="assignShiProLineInr">
                      <div className="assignShiPro">
                        <img src={picPro} />
                      </div>
                      <h1>{sourceswap.name}</h1>
                    </div>
                    <div className="swapShiRight">
                      <h1>
                        {sourceswap.weekday} {sourceswap.weekdate}
                      </h1>
                      <p>{loginshiftchecked}</p>
                    </div>
                  </div>
                </div>
                <h4>
                  Choose another employee shift to swap with{" "}
                  <WavingHandIcon className="handBy" />
                </h4>
                {othershiftchecked != "" && destswap && (
                  <>
                    <div className="swapDatInr">
                      <div className="assignShiProLine">
                        <div className="assignShiProLineInr">
                          <div className="assignShiPro">
                            <img src={picPro} />
                          </div>
                          <h1>{destswap.name}</h1>
                        </div>
                        <div className="swapShiRight">
                          <h1>
                            {destswap.weekday} {destswap.weekdate}
                          </h1>
                          <p>{othershiftchecked}</p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            {/* <div className="rejNAcepBts">
              <Button>Next</Button>
            </div> */}
          </div>

          <div className="swapDataMain hideD1">
            <SwapVerticalCircleIcon className="swpIc" />
            <div className="swapDatTop">
              <div className="swapDatInr">
                <div className="assignShiProLine">
                  <div className="assignShiProLineInr">
                    <div className="assignShiPro">
                      <img src={picPro} />
                    </div>
                    <h1>
                      {/* {sourceswap.id} */}
                      {sourceswap.name}
                    </h1>
                  </div>
                  <div className="swapShiRight">
                    <h1>
                      {sourceswap.weekday} {sourceswap.weekdate}
                    </h1>
                    <p>{loginshiftchecked}</p>
                  </div>
                </div>
              </div>

              <div className="swapDatInr">
                <div className="assignShiProLine">
                  <div className="assignShiProLineInr">
                    <div className="assignShiPro">
                      <img src={picPro} />
                    </div>
                    <h1>
                      {/* {destswap.id} */}
                      {destswap.name}
                    </h1>
                  </div>
                  <div className="swapShiRight">
                    <h1>
                      {destswap.weekday} {destswap.weekdate}
                    </h1>
                    <p>{othershiftchecked}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="rejNAcepBts">
              <Button onClick={handleCancel}>Cancel</Button>
              <Button
                style={{ color: "#0FB000" }}
                onClick={() => handleRequestSwap(sourceswap, destswap)}
              >
                Request Swap
              </Button>
            </div>
          </div>
        </div>
      </div>

      <UserBottom />

      {/* Swap Shift Moddal */}
      <div className="">
        <React.Fragment>
          <Dialog
            open={Swap}
            onClose={handleCloseSwap}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ background: "#f6f6f6" }}
            >
              <h1
                className="modalHeading"
                style={{ marginTop: 0, paddingTop: 25 }}
              >
                Choose a shift to {reqtype}
                <br />
                <b className="alrdLoc">
                  for {loginEmpShiftInfo.location} on{" "}
                  {loginEmpShiftInfo.weekday}{" "}
                  <span>{loginEmpShiftInfo.weekdate}</span>
                </b>
              </h1>
              <IconButton className="modalCloseIco" onClick={handleCloseSwap}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent style={{ padding: 0 }}>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="previewScheduModMain">
                    <div className="assignedShiftDivMain">
                      <div className="assignedShiftInr">
                        <label class="assignedShi">
                          Select a shift you want to {reqtype}
                        </label>

                        <div className="forSwpCk">
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                              {loginEmpShiftInfo?.assinged_shifts.length > 0 &&
                                loginEmpShiftInfo.assinged_shifts.map(
                                  (item, index) => (
                                    <FormControlLabel
                                      value={item}
                                      key={index}
                                      control={
                                        <Checkbox
                                          onChange={(e) =>
                                            handleShiftSwapChecked(
                                              e,
                                              loginEmpShiftInfo.flag
                                            )
                                          }
                                          checked={
                                            loginEmpShiftInfo.flag === "source"
                                              ? loginshiftchecked === item
                                              : othershiftchecked === item
                                          } // Bind the state to the checkbox
                                        />
                                      }
                                      label={item}
                                    />
                                  )
                                )}
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <div className="modNewBtnOuter">
                                <Button
                                  onClick={() =>
                                    SourceSwapSelect(loginEmpShiftInfo)
                                  }
                                  disabled={shiftdisabled}
                                >
                                  Ok
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div>
      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default ShiftSwapAndOffer;
