import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import profilePic from "../../../assets/images/Profile-Picture.jpg";
import FilterIcon from "@mui/icons-material/Filter";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import UploadFileIcon from "@mui/icons-material/UploadFile";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";

import Switch from "@mui/material/Switch";
import {
  makeGetRequest,
  makePostRequest,
  uploadFileRequest,
} from "../../../utils/utils";
import SettingsVoiceIcon from "@mui/icons-material/SettingsVoice";
import imageCompression from "browser-image-compression";
import CloseIcon from "@mui/icons-material/Close";
import videoLoadImg from "../../../assets/images/Video_Loader.gif";
import PDF from "../../../assets/images/pdfSvg.svg";
import TXT from "../../../assets/images/textSvg.svg";

function AdminAddTask() {
  const navigate = useNavigate();
  const location = useLocation();
  const { Id } = location.state || {};

  const [showLoader, setShowLoader] = React.useState(false);
  const [showLoaderImage, setShowLoaderImage] = React.useState(false);
  const [loginErrMsg, setLoginErrMsg] = React.useState("");
  const [text, setText] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [formValues, setFormValues] = React.useState(null);
  const [isEditTask, setIsEditTask] = useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [multiplePersonTaskAssign, setMultiplePersonTaskAssign] =
    useState(false);
  const [taskname, setTaskname] = React.useState("");
  const [taskdescription, setTaskDescription] = React.useState("");
  const [taskId, setTaskId] = useState();
  const [isActive, setIsActive] = useState(true);
  const [sound, setSound] = useState(0);
  const [tasks, setTasks] = useState([]);
  const [task, setTask] = useState("");
  const [isListening, setIsListening] = useState(false);

  const editTask = (Id) => {
    if (Id) {
      //setShowLoader(true);
      makeGetRequest("task/gettask/" + Id)
        .then((response) => {
          if (response.data) {
            console.log(response.data.data[0]);

            const savedValues = {
              task_name: response.data.data[0].task_name,
              task_description: response.data.data[0].task_description,
              //is_beforeafter : response.data.data[0].is_beforeafter,
              //status: response.data.data[0].status,
              //is_MultiplePersonTask :  response.data.data[0].is_MultiplePersonTask,
            };
            setIsEditTask(true);
            setFormValues(savedValues);
            //setText(response.data.data[0].task_description);
            //setShowLoader(false);
            //setTaskname(response.data.data[0].task_name);
            //setTaskDescription(response.data.data[0].task_description);
            setIsAdmin(response.data.data[0].is_beforeafter);
            setIsActive(response.data.data[0].status);
            setMultiplePersonTaskAssign(
              response.data.data[0].is_multiple_person_task
            );
            if (response.data.data[0].media != null) {
              setImageUpload(response.data.data[0].media);
            } else {
              setImageUpload([]);
            }
            if (response.data.data[0].file != null) {
              setDocumentUpload(response.data.data[0].file);
            } else {
              setDocumentUpload([]);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          let errMsg = err.response.data.message;
          setShowLoader(false);
        });
    }

    /*  console.log("Call");
    setIsEditTask(true);
    if(data) {
      setFormValues(data);
      setTaskId(data.taskid);
      setText(data.task_description);
      setTaskname(data.task_name);
      setIsAdmin(data.is_beforeafter);
    } else setFormValues(null); */
  };

  const [imageUpload, setImageUpload] = useState([]);
  const deleteImgUpload = (index) => {
    imageUpload.splice(index, 1);
    setImageUpload([...imageUpload]);
  };

  async function uploadImageOrFile(fileData) {
    setShowLoaderImage(true);
    console.log(fileData);
    var fileUrl;
    const formData = new FormData();
    if (fileData.name.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i)) {
      formData.append("image", fileData);
      await uploadFileRequest("superadmin/upload", formData)
        .then((response) => {
          fileUrl = response.data.imagepath;
        })
        .catch((err) => {
          setShowLoaderImage(false);
          let errMsg = err.response.data.message;
          console.log(err.response.data.message);
        });
    } else if (fileData.name.match(/.(mp4|MP4|3gp|3GP|flv|FLV)$/i)) {
      formData.append("file", fileData);
      await uploadFileRequest("superadmin/uploadvideo", formData)
        .then((response) => {
          fileUrl = response.data.imagepath;
        })
        .catch((err) => {
          setShowLoaderImage(false);
          let errMsg = err.response.data.message;
          console.log(err.response.data.message);
        });
    }
    setShowLoaderImage(false);
    console.log("upload", fileUrl);
    return fileUrl;
  }

  const [employeeImageErrMsg, setEmployeeImageErrMsg] = React.useState("");

  async function compressImages(file) {
    const options = {
      maxSizeMB: 1, // Set the max size to 1MB
      maxWidthOrHeight: 1024, // Set the maximum width or height
      useWebWorker: true, // Use web worker for better performance
    };
    try {
      const imageCompress = await imageCompression(file, options);
      const uniqueFileName = `${Date.now()}_${imageCompress.name}`;
      const renamedFile = new File([imageCompress], uniqueFileName, {
        type: imageCompress.type,
      });
      const uploadDone = await uploadImageOrFile(renamedFile);
      setImageUpload([...imageUpload, uploadDone]);
      setShowLoaderImage(false);
      return true;
    } catch (error) {
      console.log(error);
    }
  }

  async function uploadFiles(e) {
    if (e.target.files[0]) {
      setEmployeeImageErrMsg("");
      setShowLoaderImage(true);
      const file = e.target.files[0];
      if (await validateImage(file)) {
        if (file.name.match(/.(mp4|MP4|3gp|3GP|flv|FLV)$/i)) {
          const uploadDone = await uploadImageOrFile(file);
          setImageUpload([...imageUpload, uploadDone]);
        } else if (file.name.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i)) {
          if (await compressImages(file)) {
            setShowLoaderImage(false);
          } else {
            setShowLoaderImage(false);
          }
        } else {
          setEmployeeImageErrMsg("Invalid file format");
        }
        //setImageUpload([...imageUpload, uploadDone]);
        /* console.log("After Validate", file);
        const imageUpload = await uploadImageOrFile(file);
        console.log("image Upload", imageUpload)
        let id = localStorage.getItem("userId");
        await uploadProfilepic(imageUpload, id); */
      } else {
        setEmployeeImageErrMsg("Invalid file format");
      }
      //updateImage();
      setShowLoaderImage(false);
    } else {
      setShowLoaderImage(false);
    }
  }

  async function uploadDocumentLive(fileData) {
    console.log("File", fileData);
    var fileUrl = "";
    try {
      setShowLoaderImage(true);
      const formData = new FormData();
      formData.append("image", fileData);
      await uploadFileRequest("superadmin/upload", formData).then(
        (response) => {
          console.log(response);
          fileUrl = response.data.imagepath;
        }
      );
      setShowLoader(false);
    } catch (error) {
      console.log(error);
      setDocumentErrMsg("Invalid file format");
    }
    return fileUrl;
  }

  async function validateImage(fileData) {
    const fileSize = fileData.size; // Size in bytes
    const fileName = fileData.name;
    console.log("File Name", fileName, "File Size", fileSize);
    console.log("fileData", fileData.size);
    if (
      fileData.name.match(
        /.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF|mp4|MP4|3gp|3GP|flv|FLV)$/i
      )
    ) {
      return true;
    } else return false;
  }

  async function validateDocument(fileData) {
    if (fileData.name.match(/.(pdf|PDF|txt|TXT|docx|DOCX|doc|DOC)$/i)) {
      return true;
    } else return false;
  }

  const [documentErrMsg, setDocumentErrMsg] = React.useState("");
  const [documentUpload, setDocumentUpload] = React.useState([]);

  const openPdfDocFile = (file) => {
    console.log(file);
  };

  async function uploadDocument(e) {
    setDocumentErrMsg("");
    setShowLoaderImage(true);
    const file = e.target.files[0];
    if (await validateDocument(file)) {
      const uploadDocDone = await uploadDocumentLive(file);
      setDocumentUpload([...documentUpload, uploadDocDone]);
      setShowLoaderImage(false);
    } else {
      setDocumentErrMsg("Invalid file format");
    }
    //updateImage();
    setShowLoaderImage(false);
  }

  const deleteDocument = (index) => {
    documentUpload.splice(index, 1);
    setDocumentUpload([...documentUpload]);
  };

  const initialValues = {
    task_name: "",
    //media_type: "",
    task_description: "",
    //is_beforeafter: isAdmin,
    //status: isActive,
    //is_MultiplePersonTask: multiplePersonTaskAssign
  };

  const validationSchema = Yup.object({
    task_name: Yup.string().trim().required("Required"),
    //media_type: Yup.string().required("Required"),
    task_description: Yup.string()
      .trim("Space is not Allow")
      .strict("Space is not Allow"),
    //is_beforeafter: Yup.boolean(),
    //status: Yup.boolean(),
    //is_MultiplePersonTask: Yup.boolean()
  });

  const onSubmit = async (values) => {
    console.log("Form Data", values);
    if (isEditTask) {
      //setShowLoader(true);
      setLoginErrMsg("");
      setDescriptionError("");
      values.taskid = Id;
      values.status = isActive;
      values.is_beforeafter = isAdmin;
      values.media = imageUpload;
      values.file = documentUpload;
      values.is_deleted = false;
      values.is_multiple_person_task = multiplePersonTaskAssign;
      console.log("Form Data", values);
      await makePostRequest("task/updatetask", values)
        .then((response) => {
          console.log("error with response===>" + response);
          if (response.data) {
            console.log(response);
            setLoginErrMsg("");
            //setShowLoader(false);
            localStorage.setItem("TabName", "Task");
            navigate("/AdminTasksList");
            setTaskDescription("");
          }
        })
        .catch((err) => {
          console.log("Err");
          console.log(err);
          let errMsg = err.message;
          console.log(errMsg);
          //setShowLoader(false);
          setLoginErrMsg(errMsg);
        });
    } else {
      //setShowLoader(true);
      setLoginErrMsg("");
      setDescriptionError("");
      values.status = isActive;
      values.is_deleted = false;
      values.is_beforeafter = isAdmin;
      values.media = imageUpload;
      values.file = documentUpload;
      values.is_multiple_person_task = multiplePersonTaskAssign;
      console.log("Form Data", values);
      if (taskdescription) {
        values.task_description = taskdescription;
        await makePostRequest("task/inserttask", values)
          .then((response) => {
            console.log("error with response===>" + response);
            if (response.data) {
              console.log(response);
              setLoginErrMsg("");
              setShowLoader(false);
              localStorage.setItem("TabName", "Task");
              navigate("/AdminTasksList");
              setTaskDescription("");
            }
          })
          .catch((err) => {
            console.log("Err");
            console.log(err);
            let errMsg = err.message;
            console.log(errMsg);
            //setShowLoader(false);
            setLoginErrMsg(errMsg);
          });
      } else {
        setDescriptionError("Required");
      }
    }
  };

  const handleTaskName = (e) => {
    if (e.target.value !== "") {
      setTaskname(e.target.value);
    }
  };

  useEffect(() => {
    if (Id && sound == 0) editTask(Id);
    if (!("webkitSpeechRecognition" in window)) {
      alert(
        "Your browser does not support speech recognition. Please try Chrome."
      );
      return;
    }

    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = false;

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setTask(transcript);
      setTaskname(transcript);
      setIsListening(false);
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    if (isListening) {
      recognition.start();
    }

    return () => {
      recognition.stop();
    };
  }, [isListening]);

  const handleStartListening = () => {
    setIsListening(true);
    document.getElementById("task_name").focus();
    setTaskname("");
    setSound(1);
  };

  const handleTaskDescription = (e) => {
    if (e.htmlValue != "") {
      setTaskDescription(e.htmlValue);
    }
  };
  const handleChange = (e) => {
    setIsAdmin(e.target.checked);
  };

  const handleChangeMultiplePesonAssign = (e) => {
    //console.log("Multi person", e.target.checked);
    setMultiplePersonTaskAssign(e.target.checked);
  };

  /* const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }; */

  const checkActiveStatus = (status) => {
    console.log("StatusActive", status);
    setIsActive(status);
  };

  /* const dropdownOptions = [
    { key: "Image", value: "image" },
    { key: "Video", value: "video" },
  ]; */

  useEffect(() => {
    localStorage.setItem("TabName", "Task");
    if (Id) editTask(Id);
  }, []);

  return (
    <>
      <div className="wrapper innerPagesMain">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <NavigateBeforeIcon onClick={() => navigate("/AdminTasksList")} />
          </div>
          {isEditTask ? <h1>Edit Task</h1> : <h1>Add Task</h1>}
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}

        <div className="addAdminEmployeeMain">
          <Formik
            initialValues={formValues || initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <div>
                      <FormikControl
                        control="input"
                        type="text"
                        label="Add Title"
                        placeholder="Add Title"
                        labelClass="myLable"
                        name="task_name"
                        id="task_name"
                        isRequired="true"
                        //value={taskname}
                        //onChange={(e) => setTaskname(e.target.value)}
                        className="myInput"
                        //onBlur={(e) => handleTaskName(e)}
                      />
                      {/* <SettingsVoiceIcon className="microphone" onClick={handleStartListening} disabled={isListening}/> */}
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="myCheckBx">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isAdmin === true}
                              name="is_beforeafter"
                              onChange={(e) => handleChange(e)}
                            />
                          }
                          label="Does it need Before and After photos?"
                        />
                      </FormGroup>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="textarea"
                      type="text"
                      label="Add Description"
                      placeholder="Add Description"
                      labelClass="myLable"
                      name="task_description"
                      className="myTextArea"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="myCheckBx">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={multiplePersonTaskAssign}
                              name="is_multiple_person_task"
                              onChange={(e) =>
                                handleChangeMultiplePesonAssign(e)
                              }
                            />
                          }
                          label="Is the task assigned to multiple people?"
                        />
                      </FormGroup>
                    </div>
                  </Grid>
                  {/* <Grid item xs={12} md={12}>
                    <label className="myLable">Procedure</label>
                    <div className="card myTxEditor">
                      <Editor
                        value={text}
                        onTextChange={(e) => handleTaskDescription(e)}
                        name="task_description"
                        style={{ height: "320px" }}
                      />
                      <p style={{ fontSize: 12, color: "red" }}>
                        {descriptionError}
                      </p>
                    </div>
                  </Grid> */}
                  {/* <Grid item xs={12} md={12}>
                  <FormikControl
                      control="radio"
                      type="text"
                      label="Pick one option"
                      labelClass="myLable"
                      options = {dropdownOptions}
                      name="media_type"    
                  />
                  </Grid> */}
                  <Grid item xs={12} md={12}>
                    <div className="browNew">
                      <UploadFileIcon sx={{ fontSize: 30 }} />
                      <p>
                        Browse File{" "}
                        <label className="browseFiNote">
                          Note: Upload file with jpg/jpeg/png/gif.
                        </label>
                      </p>
                      <input type="file" onChange={(e) => uploadFiles(e)} />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="uploadedImMain">
                      {imageUpload ? (
                        <>
                          {imageUpload.map((uploadImg, index) => (
                            <div className="uploadedImInr" key={index}>
                              {/* <img src={uploadImg} /> */}
                              {uploadImg.match(
                                /.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i
                              ) ? (
                                <Zoom>
                                  <img src={uploadImg} />
                                </Zoom>
                              ) : uploadImg.match(
                                  /.(mp4|MP4|3gp|3GP|flv|FLV)$/i
                                ) ? (
                                <div className="videoPlaMain">
                                  <video width="100%" height="85" controls>
                                    <source src={uploadImg} type="video/mp4" />
                                  </video>
                                </div>
                              ) : null}
                              <CloseIcon
                                sx={{ fontSize: 20 }}
                                onClick={() => deleteImgUpload(index)}
                              />
                            </div>
                          ))}
                        </>
                      ) : null}
                    </div>
                    <p className="errorSt">{employeeImageErrMsg}</p>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="browNew">
                      <UploadFileIcon sx={{ fontSize: 30 }} />
                      <p>
                        Upload Document's{" "}
                        <label className="browseFiNote">
                          Note: Upload Document's with mp4/3gp/flv.
                        </label>
                      </p>
                      <input
                        type="file"
                        accept="application/pdf, text/plain"
                        onChange={(e) => uploadDocument(e)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="uploadedImMain docUpladPreviewMain">
                      {documentUpload ? (
                        <>
                          {documentUpload.map((uploadDoc, index) => (
                            <div className="uploadedImInr" key={index}>
                              {uploadDoc.match(/.(pdf|PDF)$/i) ? (
                                <div>
                                  <a
                                    href={uploadDoc}
                                    id="pdfLink"
                                    target="_blank"
                                  >
                                    <img src={PDF} />
                                  </a>
                                </div>
                              ) : uploadDoc.match(/.(txt|TXT)$/i) ? (
                                <div>
                                  <a
                                    href={uploadDoc}
                                    id="pdfLink"
                                    target="_blank"
                                  >
                                    <img src={TXT} />
                                  </a>
                                </div>
                              ) : uploadDoc.match(/.(docx|DOCX|doc|DOC)$/i) ? (
                                <div>
                                  <a
                                    href={uploadDoc}
                                    id="pdfLink"
                                    target="_blank"
                                  >
                                    <img src={TXT} />
                                  </a>
                                </div>
                              ) : null}
                              <CloseIcon
                                sx={{ fontSize: 20 }}
                                onClick={() => deleteDocument(index)}
                              />
                            </div>
                          ))}
                        </>
                      ) : null}
                    </div>
                    <p className="errorSt">{documentErrMsg}</p>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="mySwitch">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isActive}
                            onChange={(e) =>
                              checkActiveStatus(e.target.checked)
                            }
                          />
                        }
                        label="Active"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Button type="submit" className="logginBt">
                      {isEditTask ? <>Update</> : <>Add</>} Task
                    </Button>
                  </Grid>

                  {/* <Grid item xs={12} md={12}>
                    <h1 className="bodyHeadignAdmin">Recently Added</h1>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="employyeListingMain">
                      <div className="emploTaskListInr">
                        <div>
                          <h1>Task Name</h1>
                        </div>
                        <div className="taskImUpload">
                          <input type="file" />
                          <FilterIcon />
                        </div>
                      </div>
                    </div>
                  </Grid> */}
                </Grid>
              </Form>
            )}
          </Formik>
        </div>

        <AdminBottomNav />
      </div>
      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}

      {showLoaderImage ? (
        <div className="videoLoaderMain">
          <img src={videoLoadImg} />
          <h1>
            Please Hold on,
            <br /> Uploading your Video, Image or Documents...
          </h1>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default AdminAddTask;
