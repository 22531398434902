import React from "react";
import TopBar from "../TopBar";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import noPic from "../../../assets/images/no-data.png";

function ShoppingList() {
  return (
    <>
      <TopBar />
      <div className="wrapper">
        <div className="taskListAdmMain forUpperBottomBt">
          {/* TopBar */}
          <div className="innerPageTopSecBackPatch"></div>
          <div className="innerPageTopSec">
            <h1>Shopping List</h1>
          </div>
          {/* TopBarEnd */}
          <div class="dataNoF">
            {" "}
            <img src={noPic} />
            <p>Data Not Found</p>
          </div>
          <AdminBottomNav />
        </div>
      </div>
    </>
  );
}

export default ShoppingList;
