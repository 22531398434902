import React from "react";
import { useNavigate } from "react-router-dom";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import cloFaq from "../../../assets/images/unlock.png";
import openFaq from "../../../assets/images/lock.png";
import mark from "../../../assets/images/mark.svg";

function FAQs() {
  const navigate = useNavigate();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <div className="wrapper innerPagesMain">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <NavigateBeforeIcon onClick={() => navigate(-1)} />
          </div>
          <h1>FAQs</h1>
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}

        <div className="faqsMain">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">What is ToDoBoom?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                ToDoBoom is a task management app designed to help you organize,
                assign, and complete tasks efficiently.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography component="span">Is ToDoBoom free to use?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, ToDoBoom offers a free plan. It is free to use
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography component="span">
                How do I login into my account?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Download the app, and use your pin to login to the app. (Get
                your PIN from manager)
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography component="span">How do I reset my pin?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Contact your manager to reset your PIN.</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography component="span">
                Can I update my profile information?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, for updating contact information you have to contact to
                your manager
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography component="span">
                How can I switch between Admin and Employee profiles?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">
                  The switching between your role feature lets you to see your
                  both duties in a single login. To use this feature:
                </p>
                <div className="policyInrCont">
                  <ul>
                    <li>Go to report tab</li>
                    <li>Tap on switch icon in the top-right corner.</li>
                    <li>And switch between “Admin” and “Employee” role.</li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography component="span">
                How do I update my profile picture?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">
                  Follow below steps to change your profile picture.
                </p>
                <div className="policyInrCont">
                  <ul>
                    <li>Click on 1st tab i.e Tasks list tab.</li>
                    <li>
                      Tab on edit icon on which is on left side of
                      employee(your) name.
                    </li>
                    <li>Select and upload photo from your phone gallery.</li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography component="span">
                What is the process to complete a task?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">Steps to Complete Your Task</p>
                <div className="policyInrCont">
                  <ul>
                    <li>
                      Go to the Task List Tab: <br />
                      View tasks organized by shift and location.
                    </li>
                    <li>
                      Task Status Indicators:
                      <ul className="faqInnerUl">
                        <li>
                          <p>
                            <img src={cloFaq} /> Open: Unlock icon – Task is
                            available.
                          </p>
                        </li>
                        <li>
                          <p>
                            <img src={openFaq} /> In Progress: Lock icon – Task
                            is being worked on.
                          </p>
                        </li>
                        <li>
                          {" "}
                          <p>
                            <img src={mark} /> Completed: Green tick – Task is
                            done.
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li>
                      Take an Open Task:
                      <br /> Tap on an open task to start.
                    </li>
                  </ul>
                  <p className="moreContTx">
                    <b style={{ color: "#ff0000" }}>Note:</b> You can’t take
                    tasks in progress or already completed.
                  </p>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel9"}
            onChange={handleChange("panel9")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography component="span">
                What are the different stages of a task?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="policyInrCont">
                  <ul>
                    <li>
                      Stages of Tasks
                      <ul className="faqInnerUl">
                        <li>
                          <p>
                            <img src={cloFaq} /> Open: Unlock icon – Task is
                            available.
                          </p>
                        </li>
                        <li>
                          <p>
                            <img src={openFaq} /> In Progress: Lock icon – Task
                            is being worked on.
                          </p>
                        </li>
                        <li>
                          {" "}
                          <p>
                            <img src={mark} /> Completed: Green tick – Task is
                            done.
                          </p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel10"}
            onChange={handleChange("panel10")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography component="span">
                How do I upload before and after photos for a task?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">Steps to Upload Your Task Photos</p>
                <div className="policyInrCont">
                  <ul>
                    <li>
                      Go to the Task List: Open the task list to view all
                      assigned tasks.
                    </li>
                    <li>
                      Identify Tasks Requiring Photos: Look for the photo icon
                      on the right side of the task.
                    </li>
                    <li>
                      Upload Photos:
                      <ul className="faqInnerUl">
                        <li>
                          <p>Click on the photo icon.</p>
                        </li>
                        <li>
                          <p>
                            You’ll see two options: Upload Before Photo and
                            Upload After Photo. Select the appropriate option
                            and upload your photos accordingly.
                          </p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel11"}
            onChange={handleChange("panel11")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography component="span">
                How can I add my availability?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">
                  Adding your availability helps the manager assign tasks
                  accordingly.
                </p>
                <p className="moreContTx">Steps to add availability:</p>
                <div className="policyInrCont">
                  <ul>
                    <li>
                      Go to the Availability Tab (2nd Tab): Open the
                      availability section in the app.
                    </li>
                    <li>
                      Select the Week: <br /> Use the weekly slider to choose
                      the week you want to update availability for.
                    </li>
                    <li>
                      Add Availability:
                      <ul className="faqInnerUl">
                        <li>
                          <p>
                            Select the specific day you’re available by clicking
                            the checkbox next to it, then click Save.
                          </p>
                        </li>
                        <li>
                          <p>
                            To mark availability for the entire day, click the
                            checkbox before the day name and save.
                          </p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel12"}
            onChange={handleChange("panel12")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography component="span">
                How can I view my availability for specific day?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">Steps to view availability:</p>
                <div className="policyInrCont">
                  <ul>
                    <li>Go to the Availability Tab (2nd Tab)</li>
                    <li>You are able to view your availability here</li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel13"}
            onChange={handleChange("panel13")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography component="span">
                How do I set my preferences?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">Steps to add Preference:</p>
                <div className="policyInrCont">
                  <ul>
                    <li>Go to the Availability Tab (2nd Tab)</li>
                    <li>Click on “Set your preferences” section</li>
                    <li>Set your maximum hours and shifts per week</li>
                    <li>Click on Save button</li>
                  </ul>
                  <p className="moreContTx">
                    <b style={{ color: "#ff0000" }}>Note:</b> Setting these
                    preferences doesn't guarantee the preferred number of hours
                    or shifts.
                  </p>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel14"}
            onChange={handleChange("panel14")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography component="span">
                How do I check the consolidated preview of my tasks?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">
                  <b>Steps to view consolidated preview:</b>
                </p>
                <div className="policyInrCont">
                  <ul>
                    <li>Go to the Swap & Offer tab (3rd Tab)</li>
                    <li>Here you will see preview of whole week.</li>
                  </ul>

                  <p className="moreContTx">
                    <b>Explanation of preview -</b>
                  </p>
                  <ul>
                    <li>
                      On top of the page you will find week slider to select
                      week.
                    </li>
                    <li>
                      Here you are able to use swap and offer shifts features.
                    </li>
                    <li>
                      You are able to view shifts scheduled location wise.
                      Scrolling down give you preview for next location
                    </li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel15"}
            onChange={handleChange("panel15")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography component="span">
                How do I use the “Offer Shift” feature?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">
                  Offer feature is used to offer shifts to other if employee
                  wants to switch his shift.
                </p>
                <div className="policyInrCont">
                  <ul>
                    <li>
                      Go to 3rd tab, on consolidated preview you can find Offer.
                    </li>
                    <li>Click on offer</li>
                    <li>Select shift you want to offer, and its done</li>
                  </ul>
                  <p className="moreContTx">
                    <b style={{ color: "#ff0000" }}>Note:</b> Your offer request
                    is visible only when admin approves it.
                  </p>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel16"}
            onChange={handleChange("panel16")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography component="span">
                How do I use the “Swap Shift” feature?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">
                  Swap feature is used to swap shifts with your colleague.
                </p>
                <div className="policyInrCont">
                  <ul>
                    <li>
                      Go to 3rd tab, on consolidated preview you can find Swap.
                    </li>
                    <li>Click on Swap</li>
                    <li>Select shift you want to swap.</li>
                    <li>
                      Select shift from your colleague row which you want to
                      swap with.
                    </li>
                    <li>
                      After this you will find swap shifts preview in bottom
                      drawer.
                    </li>
                    <li>Click on swap request.</li>
                  </ul>
                  <p className="moreContTx">
                    <b style={{ color: "#ff0000" }}>Note:</b>
                    <p className="moreContTx">
                      -While swapping in bottom drawer you will always find
                      steps for swap shift.
                    </p>
                    <p className="moreContTx">
                      -Your swap request is visible to your colleague only when
                      admin approves it.
                    </p>
                  </p>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel17"}
            onChange={handleChange("panel17")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography component="span">
                Is my data secure on ToDoBoom?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">
                  Yes, your data is highly secure on ToDoBoom. We use advanced
                  encryption protocols to protect your information and ensure it
                  is accessible only to authorized users. Additionally, regular
                  security audits, secure servers, and data privacy practices
                  are in place to safeguard your data at all times. Your trust
                  and privacy are our top priorities.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel18"}
            onChange={handleChange("panel18")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography component="span">
                Does ToDoBoom share my information with third parties?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">
                  No, ToDoBoom does not share your information with third
                  parties without your explicit consent. Your data is handled in
                  strict compliance with our privacy policy, ensuring it is used
                  only to improve your experience within the app. We are
                  committed to maintaining your privacy and security.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel19"}
            onChange={handleChange("panel19")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography component="span">
                I’m unable to log in. What should I do?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">
                  If you're unable to log in, try the following steps:
                </p>
                <div className="policyInrCont">
                  <ul>
                    <li>
                      Check Your Credentials: Ensure that you are entering the
                      correct PIN.
                    </li>
                    <li>
                      Reset PIN: If you've forgotten your PIN, contact your
                      manager to set it.
                    </li>
                    <li>
                      Stable Internet: Ensure your device is connected to a
                      stable internet connection.
                    </li>
                    <li>
                      Update the App: Make sure you are using the latest version
                      of the app.
                    </li>
                    <li>
                      Contact Support: If the issue persists, reach out to our
                      support team for assistance.
                    </li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </>
  );
}

export default FAQs;
