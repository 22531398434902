import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import avtarPic from "../assets/images/avtarPic.png";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import compLog from "../assets/images/demoL.png";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { makeGetRequest } from "../utils/utils";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import GroupIcon from "@mui/icons-material/Group";

function AppDrawer() {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = React.useState(false);
  const [companypic, setCompanyPic] = useState();
  const [employeeDetails, setEmployeeDetails] = useState([]);

  const getEmployeeDetails = async (id) => {
    setShowLoader(true);
    await makeGetRequest("/employee/getemployee/" + parseInt(id))
      .then((response) => {
        if (response.data.data) {
          if (response.data.data.length > 0) {
            console.log(response.data.data[0]);
            setEmployeeDetails(response.data.data[0]);
          } else setEmployeeDetails([]);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };
  const logoutFunction = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  };
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerInputs = [
    // {
    //   name: "User",
    //   icon: <PersonIcon />,
    //   route: "/EmployeeTaskListNew",
    // },
    // {
    //   name: "personal Tasks",
    //   icon: <AssignmentTurnedInIcon />,
    //   route: "/PersonalTasks",
    // },
    // {
    //   name: "Swap Preview",
    //   icon: <SwapHorizIcon />,
    //   route: "/SchedulPreview",
    // },
    {
      name: "All Employee",
      icon: <GroupIcon />,
      route: "/EmployeeListing",
    },
    /* {
      name: "New Employee Shift Schedular",
      icon: <SwapHorizIcon />,
      route: "/WeeklyTasksNew",
    }, */
  ];

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {DrawerInputs.map((drawObj, index) => (
          <>
            {/* <ListItem key={drawObj.name} disablePadding>
              <ListItemButton onClick={() => navigate(`${drawObj.route}`)}>
                <ListItemIcon>{drawObj.icon}</ListItemIcon>
                <ListItemText primary={drawObj.name} />
              </ListItemButton>
            </ListItem> */}

            <ListItem key={drawObj.name} disablePadding>
              <ListItemButton onClick={() => navigate("/EmployeeListing")}>
                <ListItemIcon>{drawObj.icon}</ListItemIcon>
                <ListItemText primary="All Employee" />
              </ListItemButton>
            </ListItem>
          </>
        ))}
      </List>
    </Box>
  );

  const adminDetailsView = (
    <div className="drawerAdminView">
      <div className="avtardiv">
        {employeeDetails.profile_pic !== null &&
        employeeDetails.profile_pic !== "" ? (
          <img src={employeeDetails.profile_pic} />
        ) : (
          <img src={avtarPic} />
        )}
        <p>{employeeDetails.full_name}</p>
        <span className="userTypeName">{employeeDetails.user_type}</span>
        <span className="userTypeName">{employeeDetails.schemaname}</span>
        <span className="userTypeName">
          Employee Code: {employeeDetails.employee_code}
        </span>
      </div>
    </div>
  );

  useEffect(() => {
    let id = localStorage.getItem("userId");
    setCompanyPic(localStorage.getItem("companyLogo"));
    getEmployeeDetails(id);
    //console.log("useName", UserName, "userId", userId, "userType", userType, "Comapny", companyName)
  }, []);

  return (
    <div>
      <div className="comnCompaniesLogoMain">
        <MenuIcon onClick={toggleDrawer(true)} />
        <div className="alCompLogs">
          {companypic ? <img src={companypic} /> : <img src={avtarPic} />}
        </div>
      </div>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {adminDetailsView}
        {localStorage.getItem("userType") == "Admin" ? <>{DrawerList}</> : null}
        <div className="logoutView">
          <div className="logoutBtn" onClick={logoutFunction}>
            <p>Logout</p>
            <LogoutIcon />
          </div>
        </div>
      </Drawer>
      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default AppDrawer;
