import React, { useContext, useState, useEffect } from "react";
import AppDrawer from "../AppDrawer";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";

import NotificationIcon from "../../components/NotificationIcon";
import { makePostRequest } from "../../utils/utils";
function TopBar() {
  const navigate = useNavigate();

  const [noticount, setNotiCount] = useState(0);
  const getNotificationsCount = async () => {
    let bodyFormData = {
      to_emp_id: JSON.parse(localStorage.getItem("userId")),
    };
    try {
      await makePostRequest("schedular/getNotificationsCount", bodyFormData)
        .then((response) => {
          // alert(response.data)
          if (response.data.count) {
            setNotiCount(response.data.count);
          } else {
            setNotiCount(0);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error(`Error while requesting swap`, error);
    }
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      console.log("Page is now visible");
      getNotificationsCount();
      // Add any logic to handle when the page is visible again
    } else {
      console.log("Page is now hidden");
      // Add any logic to handle when the page is hidden
    }
  };

  useEffect(() => {
    window.receiveNotification = (notification) => {
      // alert("receivenoti",notification);
      getNotificationsCount(); // Trigger API and update UI
    };
    // Add the event listener
    document.addEventListener("visibilitychange", handleVisibilityChange);
    getNotificationsCount();
  }, []);

  return (
    <>
      {/* <div className="topBarPatch"></div> */}
      <div className="topBarMain">
        <AppDrawer />
        <Badge
          badgeContent={noticount}
          className="notiSt"
          onClick={() => navigate("/NotificationPage")}
        >
          <NotificationsIcon color="action" />
        </Badge>
        {/* <NotificationIcon/> */}
      </div>
    </>
  );
}

export default TopBar;
