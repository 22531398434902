import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import profilePic from "../../../assets/images/Profile-Picture.jpg";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import SortIcon from "@mui/icons-material/Sort";
import FilterIcon from "@mui/icons-material/Filter";
import { Grid, Button } from "@mui/material";
import Slider from "react-slick";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import moment from "moment/moment";
import SettingsVoiceOutlinedIcon from "@mui/icons-material/SettingsVoiceOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// End

import afterBef1 from "../../../assets/images/afterBef1.png";
import afterBef2 from "../../../assets/images/afterBef2.png";
import CloseIcon from "@mui/icons-material/Close";
import userWithCheck from "../../../assets/images/userWithCheck.svg";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";

import PropTypes, { func } from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";
import swal from "sweetalert";
import ToggleButton from "@mui/material/ToggleButton";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import AppDrawer from "../../AppDrawer";

// For Accordian
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// End

import pencil from "../../../assets/images/pencil.svg";
import availabilityPreview from "../../../assets/images/availabilityPreview.svg";
import Switch from "@mui/material/Switch";
import avarPi from "../../../assets/images/avtarPic.png";
import speak_animation from "../../../assets/images/speak_animation.gif";
import { DateRange } from "@mui/icons-material";
import TopBar from "../TopBar";
import FormControlLabel from "@mui/material/FormControlLabel";
import { set } from "lodash";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

const label = { inputProps: { "aria-label": "Switch demo" } };

// For Tabs
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// End

// Tab Inner
function CustomTabPanel2(props) {
  const { children, value2, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value2 !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value2 === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel2.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value2: PropTypes.number.isRequired,
};

function a11yProps2(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// End

function AdminTasksList() {
  // For Days Slider
  const daysSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  // End

  // For According 2
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange3 = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // End
  // For Accordian 4
  const [expanded4, setExpanded4] = React.useState(false);

  const handleChange4 = (panel4) => (event, isExpanded) => {
    setExpanded4(isExpanded ? panel4 : false);
  };
  // End

  //For Scroll Add Class
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        // Adjust this value based on your needs
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // End

  const settings = {
    focusOnSelect: true,
    slidesToScroll: 1,
    className: "center",
    centerMode: false,
    infinite: false,
    centerPadding: "0px",
    slidesToShow: 3,
    speed: 500,
  };

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0); // Track the current slide index

  const activeind = ["Pref", "M", "T", "W", "Th", "F", "S", "Su"];

  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => {
      setCurrentSlideIndex(current); // Update the current slide index after the slide changes
      SetAbb(activeind[current]);
    },
  };

  // For Modal
  const [openAval, setOpenAval] = React.useState(false);
  const [currentweekindex, setCurrentWeekIndex] = React.useState(0);
  const [sch_ids, SetSch_id] = React.useState("");
  const handleClickOpenAval = (sch_ids) => {
    console.log(">>", sch_ids);

    setShowLoader(true);
    const initialStartDate = mondays[0].format("YYYY-MM-DD");
    const initialEndDate = mondays[0]
      .clone()
      .add(6, "days")
      .format("YYYY-MM-DD");

    const currentMonday = moment().startOf("week").add(1, "days"); // Get current week's Monday
    const currentWeekStartDate = currentMonday.format("YYYY-MM-DD"); // Start date
    const currentWeekEndDate = currentMonday
      .clone()
      .add(6, "days")
      .format("YYYY-MM-DD"); // End date

    console.log(">> weekstart", currentWeekStartDate);

    // Find the index of the current week's Monday in the mondays array
    const currentMondayIndex = mondays.findIndex(
      (monday) => monday.format("YYYY-MM-DD") === currentWeekStartDate
    );

    setCurrentWeekIndex(currentMondayIndex);
    setDateRange({
      startDate: currentWeekStartDate,
      endDate: currentWeekEndDate,
    });
    SetSch_id(sch_ids);

    getEmployeeAvailability(currentWeekStartDate, currentWeekEndDate, sch_ids);
    setOpenAval(true);
  };

  const handleCloseAval = () => {
    setOpenAval(false);
  };
  // End

  // Tab Inner
  const [value2, setValue2] = React.useState(0);

  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };
  // End

  const navigate = useNavigate();
  const [showLoader, setShowLoader] = React.useState(false);
  const [upload, setUpload] = React.useState(false);
  const [sortBy, setSortBy] = React.useState("desc");
  const [pageNo, setPageNo] = React.useState(1);
  const [sortColumnName, setSortColumnName] = React.useState("created_at");
  const [selected, setSelected] = React.useState(false);
  const [offsetvalue, setoffsetvalue] = React.useState(0);
  const [itemsPerPage] = React.useState(1000);
  const [totalItems, setTotalItems] = React.useState(0);
  const [categoryList, setCategoryList] = React.useState([]);
  const [taskList, setTaskList] = React.useState([]);
  const [frequancyList, setFrequancyList] = React.useState([]);
  const [schedulelist, setScheduleList] = React.useState([]);
  const [searchText, setSearchText] = useState("");
  const [catsearchText, setCatSearchText] = useState("");
  const [fresearchText, setFreSearchText] = useState("");
  const [tempSlide, setTempSlide] = React.useState("");
  const [selectedTaskList, setSelectedTaskList] = React.useState();
  const [selectedCategorylist, setSelectedCategorylist] = React.useState();
  const [selectedFrequencylist, setSelectedFrequencylist] = React.useState();
  const [isListening, setIsListening] = useState(false);
  const [isListeningLoc, setIsListeningLoc] = useState(false);
  const [whiteSpaceErrorTaskGroup, setWhiteSpaceErrorTaskGroup] = useState("");
  const [whiteSpaceErrorTaskList, setWhiteSpaceErrorTaskList] = useState("");
  const [whiteSpaceErrorShift, setWhiteSpaceErrorShift] = useState("");

  function handleChangeSortBy() {
    setSortColumnName("task_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    getAllTaskList(searchText);
  }

  // For Tabs
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    // if (newValue === 4) { // If "Location" tab is selected
    //   navigate('/location');
    // } else {
    //   setValue(newValue); // Update the active tab state for other tabs
    // }
    setValue(newValue);
    if (newValue == 0) {
      setSearchText("");
      setCatSearchText("");
      setFreSearchText("");
      checkCatDelete();
      getAllTaskList(null);
    } else if (newValue == 1) {
      setSearchText("");
      setCatSearchText("");
      setFreSearchText("");
      checkDelete();
      getAllCategoryList(null);
    } else if (newValue == 2) {
      setSearchText("");
      setCatSearchText("");
      setFreSearchText("");
      checkFreDelete();
      getAllFrequancyList(null);
    } else {
      setSearchText("");
      setCatSearchText("");
      setFreSearchText("");
      checkDelete();
      getAllTaskList(null);
    }

    localStorage.removeItem("TabName");
  };
  // End

  // For Drop Menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const OpenDrop = Boolean(anchorEl);
  const handleClickOpenDrop = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseOpenDrop = () => {
    setAnchorEl(null);
  };
  // End

  const swipeNSelection = (event) => {
    forSelectList(event);
    resetSwipeDirection(event);
  };

  const forSelectList = () => {
    // var element = document.getElementById("listID");
    // element.classList.toggle("selectedList");
  };

  // For Modal
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // End

  // For Swipe Left Right
  const [swipeDirection, setSwipeDirection] = useState(null);
  const startXRef = useRef(null);
  const threshold = 50;

  const handleTouchStart = (e, index) => {
    startXRef.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e, index) => {
    const currentX = e.touches[0].clientX;
    const distance = currentX - startXRef.current;

    if (Math.abs(distance) > threshold) {
      if (distance > 0) {
        var element = document.getElementById("bugfree-" + tempSlide);
        if (element != null) {
          element.classList.remove("swiped-right");
          element.classList.remove("swiped-left");
        }

        var elementnew = document.getElementById("bugfree-" + index);
        elementnew.classList.toggle("swiped-right");
        setTempSlide(index);
        //setSwipeDirection("right");
      } else {
        //setSwipeDirection("left");
        var element = document.getElementById("bugfree-" + tempSlide);
        if (element != null) {
          element.classList.remove("swiped-left");
          element.classList.remove("swiped-right");
        }

        var elementnew = document.getElementById("bugfree-" + index);
        elementnew.classList.toggle("swiped-left");
        setTempSlide(index);
      }
    }
  };

  const handleTouchMove2 = (e) => {
    const currentX = e.touches[0].clientX;
    const distance = currentX - startXRef.current;

    if (Math.abs(distance) > threshold) {
      if (distance > 0) {
        setSwipeDirection("right");
      } else {
        setSwipeDirection("left");
      }
    }
  };

  const handleTouchEnd = () => {
    var element = document.getElementById("bugfree-" + tempSlide);
    if (element != null) {
      element.classList.remove("swiped-right");
      element.classList.remove("swiped-left");
    }
    // Optionally, you could reset the swipeDirection here if you want to clear it after some time
    // setSwipeDirection(null);
  };

  const resetSwipeDirection = () => {
    setSwipeDirection(null);
  };
  // Swipe Left Right End

  const [loclist, setLocList] = React.useState([]);
  async function getAllLocationList(searchString) {
    let company_id = localStorage.getItem("companyId");
    if (!searchString) {
      searchString = null;
      setSearchText(null);
    } else {
      setSearchText(searchString);
    }
    //setShowLoader(true);
    await makeGetRequest(
      "location/getlocationlist/" + company_id + "/" + searchString
    )
      .then((response) => {
        console.log("in location list", response.data.data.res);
        setLocList(response.data.data.res);
      })
      .catch((err) => {
        //setShowLoader(false);
      });
  }

  async function getAllTaskList(searchString) {
    if (!searchString) {
      searchString = null;
      setSearchText(null);
    } else {
      setSearchText(searchString);
    }
    //setShowLoader(true);
    await makeGetRequest(
      "task/tasksearch/" +
        sortColumnName +
        "/" +
        sortBy +
        "/" +
        searchString +
        "/" +
        offsetvalue +
        "/" +
        itemsPerPage
    )
      .then((response) => {
        if (response.data.data.res) {
          if (response.data.data.res.length > 0) {
            setTaskList(response.data.data.res);
            setTotalItems(response.data.data.totalCount);
            console.log("Task", response.data.data.totalCount);
          } else setTaskList([]);
          setUpload(!upload);
        }
        //setShowLoader(false);
        setSortColumnName("created_at");
      })
      .catch((err) => {
        //setShowLoader(false);
      });
  }

  async function checkDelete() {
    makeGetRequest("schedular/gettaskschedularById")
      .then((response) => {
        if (response.data.data.rows.length > 0) {
          setSelectedTaskList(response.data.data.rows);
        } else {
          setSelectedTaskList();
        }
      })
      .catch((err) => {});
  }

  async function checkCatDelete() {
    makeGetRequest("schedular/getcategoryschedularById")
      .then((response) => {
        if (response.data.data.rows.length > 0) {
          setSelectedCategorylist(response.data.data.rows);
        } else {
          setSelectedCategorylist();
        }
      })
      .catch((err) => {});
  }

  async function checkFreDelete() {
    makeGetRequest("schedular/getfrequencyschedularById")
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.data.rows.length > 0) {
          setSelectedFrequencylist(response.data.data.rows);
        } else {
          setSelectedFrequencylist();
        }
      })
      .catch((err) => {});
  }

  async function deleteLocation(taskObj) {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "You want to delete this location",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
    });

    if (willDelete) {
      const bodyFormData = {
        loc_id: parseInt(taskObj.loc_id),
        is_deleted: true,
        loc_name: taskObj.loc_name,
      };

      try {
        const response = await makePostRequest(
          "location/deletelocation",
          bodyFormData
        );

        if (response.data) {
          swal("Success", "Record deleted successfully", "success", {
            timer: 3000,
            buttons: false,
          });
          getAllLocationList(searchText); // Refresh the location list
        } else {
          swal(
            "Error",
            response.message || "Failed to delete the record",
            "error",
            {
              timer: 3000,
              buttons: false,
            }
          );
        }
      } catch (err) {
        // Handle the error from the backend
        if (err.response && err.response.data && err.response.data.message) {
          swal("Error", err.response.data.message, "error", {
            timer: 3000,
            buttons: false,
          });
        } else {
          swal("Error", "An unexpected error occurred", "error", {
            timer: 3000,
            buttons: false,
          });
        }
        console.error(err); // Log the error for debugging
      }
    }
  }

  async function deleteTask(taskId) {
    const willDelete = await swal({
      title: "Confirm Delete?",
      text: "This will permanently delete the task.",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
    });

    if (willDelete) {
      var bodyFormData = {
        taskid: taskId,
        is_deleted: true,
      };
      await makePostRequest("task/updatetaskstatus", bodyFormData)
        .then((response) => {
          if (response.data) {
            swal("Success", "Record deleted successfully", "success", {
              timer: 3000,
              buttons: false,
            });
            getAllTaskList(searchText);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function add(arr, name) {
    if (arr != undefined) {
      const { length } = arr;
      const id = length + 1;
      const found = arr.some((el) => el.taskid === name);
      let res = false;
      if (!found) {
        res = false;
      } else {
        res = true;
      }
      return res;
    }
  }

  function addcat(arr, name) {
    if (arr != undefined) {
      const { length } = arr;
      const id = length + 1;
      const found = arr.some((el) => el.categoryid === name);
      let res = false;
      if (!found) {
        res = false;
      } else {
        res = true;
      }
      return res;
    }
  }

  function addFreq(arr, name) {
    if (arr != undefined) {
      const { length } = arr;
      const id = length + 1;
      const found = arr.some((el) => el.frequancyid === name);
      let res = false;
      if (!found) {
        res = false;
      } else {
        res = true;
      }
      return res;
    }
  }

  const editTask = (taskObj) => {
    navigate("/AdminAddTask", {
      state: { Id: taskObj.taskid },
    });
  };

  const editLoc = (taskObj) => {
    console.log(taskObj); // Debugging: Check the object before navigating
    navigate("/EditLocation", {
      state: { data: taskObj.loc_id }, // Pass the object under a specific key (e.g., 'data')
    });
  };

  function handleChangeSortByTask() {
    setSortColumnName("task_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    getAllTaskList(searchText);
  }

  async function getAllCategoryList(searchString) {
    if (!searchString) {
      searchString = null;
      setCatSearchText(null);
    } else {
      setCatSearchText(searchString);
    }
    //setShowLoader(true);
    await makeGetRequest(
      "category/categorysearch/" +
        sortColumnName +
        "/" +
        sortBy +
        "/" +
        searchString +
        "/" +
        offsetvalue +
        "/" +
        itemsPerPage
    )
      .then((response) => {
        if (response.data.data.res) {
          if (response.data.data.res.length > 0) {
            setCategoryList(response.data.data.res);
            setTotalItems(response.data.data.totalCount);
            console.log("Category", response.data.data.totalCount);
          } else setCategoryList([]);
          setUpload(!upload);
        }
        //setShowLoader(false);
        setSortColumnName("created_at");
      })
      .catch((err) => {
        //setShowLoader(false);
      });
  }

  async function deleteCategory(categoryid) {
    const willDelete = await swal({
      title: "Confirm Delete?",
      text: "This will permanently delete the task group.",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
    });

    if (willDelete) {
      var bodyFormData = {
        categoryid: categoryid,
        is_deleted: true,
      };
      await makePostRequest("category/updatecategorystatus", bodyFormData)
        .then((response) => {
          if (response.data) {
            swal("Success", "Record deleted successfully", "success", {
              timer: 3000,
              buttons: false,
            });
            getAllCategoryList(null);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const editCategory = (cateObj) => {
    navigate("/AddCategory", {
      state: { data: cateObj },
    });
  };

  function handleChangeSortByCategory() {
    setSortColumnName("frequancy_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    getAllCategoryList(catsearchText);
  }

  async function getAllFrequancyList(searchString) {
    if (!searchString) {
      searchString = null;
      setFreSearchText(null);
    } else {
      setFreSearchText(searchString);
    }
    //setShowLoader(true);
    await makeGetRequest(
      "frequancy/frequancysearch/" +
        sortColumnName +
        "/" +
        sortBy +
        "/" +
        searchString +
        "/" +
        offsetvalue +
        "/" +
        itemsPerPage
    )
      .then((response) => {
        if (response.data.data.res) {
          if (response.data.data.res.length > 0) {
            setFrequancyList(response.data.data.res);
            setTotalItems(response.data.data.totalCount);
            console.log("frequancy", response.data.data.res);
          } else setFrequancyList([]);
          setUpload(!upload);
        }
        //setShowLoader(false);
        setSortColumnName("created_at");
      })
      .catch((err) => {
        //setShowLoader(false);
      });
  }

  async function deleteFrequency(frequancyid) {
    const willDelete = await swal({
      title: "Confirm Delete?",
      text: "This will permanently delete the employee shift.",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
    });

    if (willDelete) {
      var bodyFormData = {
        frequancyid: frequancyid,
        is_deleted: true,
      };
      await makePostRequest("frequancy/updatefrequancystatus", bodyFormData)
        .then((response) => {
          if (response.data) {
            swal("Success", "Record deleted successfully", "success", {
              timer: 3000,
              buttons: false,
            });
            getAllFrequancyList(null);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const editFrequency = (frequObj) => {
    navigate("/AddFrequency", {
      state: { data: frequObj },
    });
  };

  const editSchedule = (schlist, template_name) => {
    localStorage.setItem("templatename", template_name);
    navigate("/CreateNewSchedule", {
      state: { data: schlist },
    });
  };

  function handleChangeSortByFrequency() {
    setSortColumnName("frequancy_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    getAllFrequancyList(fresearchText);
  }

  React.useEffect(() => {
    localStorage.removeItem("templatename");
    getStartWeekdate();
    checkCatDelete();
    getAllCategoryList(null);
    getAllTaskList(null);
    getAllLocationList(null);
    getAllFrequancyList(null);
    getAllScheduleList();
    getAllArchiveTemplateList();

    if (localStorage.getItem("TabName") == "Location") {
      setValue(2);
      a11yProps(2);
    } else if (localStorage.getItem("TabName") == "Task") {
      // alert("hiii")
      setValue(1);
      a11yProps(1);
    } else if (localStorage.getItem("TabName") == "Category") {
      setValue(0);
      a11yProps(0);
    } else if (localStorage.getItem("TabName") == "Schedule") {
      setValue(3);
      a11yProps(3);
    } else {
      setValue(0);
      a11yProps(0);
    }
  }, [pageNo]);

  const [isActive, setIsActive] = useState(false);

  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  // Prepare a lookup to access the count and sum values based on weekdays
  const initialDayData = {
    shifts: {
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
      Sunday: 0,
    },
    emp: {
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
      Sunday: 0,
    },
  };

  const [dayData, setDayData] = useState(initialDayData);

  const [tabValues, setTabValues] = useState({}); // To track active tab for each template
  const [templateDayData, setTemplateDayData] = useState({}); // To track dayData for each template

  // Function to handle tab change for each template
  const handleChangeTab = (templateName, newValue) => {
    setTabValues((prevTabValues) => ({
      ...prevTabValues,
      [templateName]: newValue,
    }));
  };

  async function getlocationwiseSummarycount(loc_name, template_name) {
    var bodyFormData = {
      location: loc_name,
      templatename: template_name,
    };

    await makePostRequest(
      "schedular/GetShiftAndEmpCountByLocation",
      bodyFormData
    )
      .then((response) => {
        if (response.data) {
          console.log(response.data.data);

          const updatedDayData = {
            shifts: { ...initialDayData.shifts },
            emp: { ...initialDayData.emp },
          };

          // Update only the weekdays present in the API response
          response.data.data.forEach((item) => {
            updatedDayData.shifts[item.weekday] = item.count;
            updatedDayData.emp[item.weekday] = item.sum;
          });

          // Update dayData for the specific template
          setTemplateDayData((prevTemplateDayData) => ({
            ...prevTemplateDayData,
            [template_name]: updatedDayData,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getAllScheduleList() {
    await makeGetRequest(
      "schedular/getTemplateListForSummaryCount/" +
        parseInt(localStorage.getItem("companyId")) +
        "/false"
    )
      .then(async (response) => {
        if (response.data.data) {
          setShowLoader(false);
          console.log("schedule", response.data.data);
          if (response.data.data.length > 0) {
            setScheduleList(response.data.data);
            // Fetch "All" summary count for each template
            for (const schlist of response.data.data) {
              await getlocationwiseSummarycount("All", schlist.template_name);
            }
          } else{
            setScheduleList([]);
            setShowLoader(false)
          } 
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  const [archive, setArchiveList] = React.useState([]);
  async function getAllArchiveTemplateList() {
    await makeGetRequest("schedular/getArchiveTemplatesList")
      .then(async (response) => {
        if (response.data.data) {
          console.log("archive", response.data.data);
          if (response.data.data.length > 0) {
            setArchiveList(response.data.data);
          } else setArchiveList([]);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }
  //

  const handleSwitchChange = async (event, index, template_name) => {
    const isActivating = event.target.checked; // Check if the user is activating or deactivating
    const updatedList = [...schedulelist]; // Clone the current list

    // Prevent deactivating the last active template
    if (
      !isActivating &&
      schedulelist.filter((item) => item.is_active).length === 1
    ) {
      alert("At least one template must be active.");
      return;
    }

    var bodyFormData = {
      template_name: template_name,
      is_active: isActivating,
      userid:JSON.parse(localStorage.getItem('userId'))
    };

    try {
      // Step 1: Check entries for next week
      const response = await makePostRequest(
        "schedular/checkEntriesForNextWeek",
        bodyFormData
      );

      if (response.data) {
        if (response.data.data.autoEmpScheduler) {
          // Prompt the first SweetAlert warning
          const userResponse = await swal({
            title: "Warning",
            text: "Are you sure you want to activate this template? Because employee shift assignment done for the next week for the current active template. If yes then employee has to resubmit their availability and again you need to assign shift schedule.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          });

          if (!userResponse) {
            console.log("User canceled activation.");
            return; // Exit if user clicks Cancel
          }
        } else if (response.data.data.employeeAvailability) {
          // Prompt the second SweetAlert warning
          const userResponse = await swal({
            title: "Warning",
            text: "Are you sure you want to activate this template? If yes then employee has to resubmit their availability.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          });

          if (!userResponse) {
            console.log("User canceled activation.");
            return; // Exit if user clicks Cancel
          }
        }
      } else {
        throw new Error("Failed to check entries for next week.");
      }

      // Step 2: Proceed with the template activation/deactivation
      updatedList.forEach((template, idx) => {
        template.is_active = idx === index ? isActivating : false; // Activate the selected template, deactivate others
      });

      setShowLoader(true);

      const updateResponse = await makePostRequest(
        "schedular/ChangeAdminScheduleTemplate",
        bodyFormData
      );

      if (updateResponse.data) {
        // Only update the state if the API call is successful
        setShowLoader(false);
        setScheduleList(updatedList);
        getAllScheduleList(); // Refresh the data after the state update
        console.log(
          `${updatedList[index].template_name} is now ${
            isActivating ? "active" : "inactive"
          }`
        );
      } else {
        setShowLoader(false);
        throw new Error("Failed to update template status.");
      }
    } catch (error) {
      console.error("Error updating template status:", error);
      setShowLoader(false);
      // Revert changes in case of an error
      const revertedList = [...schedulelist];
      revertedList[index].is_active = !isActivating;
      setScheduleList(revertedList);

      alert("Failed to update the template status. Please try again.");
    }
  };

  // const handleSwitchChange = async (event, index, template_name) => {

  //   try {
  //     const response = await makePostRequest(
  //       "schedular/checkEntriesForNextWeek",
  //       bodyFormData
  //     );

  //     if (response.data) {
  //       console.log(response.data.data.autoEmpScheduler);
  //       console.log(response.data.data.employeeAvailability);
  //       if(response.data.data.autoEmpScheduler)
  //       {

  //         const userResponse = await swal({
  //           title: "Warning",
  //           text: "Are you sure you want to activate this template? Employee Shift Assignment Done for Next Week for the current active template. If Yes then employee has to resubmit their availability and again need to assign shift time.",
  //           icon: "warning",
  //           buttons: true,
  //           dangerMode: true,
  //         });

  //         if (userResponse) {
  //           console.log("User clicked OK");
  //           // Proceed with your logic for "OK"
  //         } else {
  //           console.log("User clicked Cancel");
  //           return;
  //           // Logic for "Cancel"
  //         }

  //       }

  //       if(response.data.data.employeeAvailability)
  //       {

  //         const userResponse = await swal({
  //           title: "Warning",
  //           text: "Are you sure you want to activate this template? If Yes then employee has to resubmit their availability.",
  //           icon: "warning",
  //           buttons: true,
  //           dangerMode: true,
  //         });

  //         if (userResponse) {
  //           console.log("User clicked OK");
  //           // Proceed with your logic for "OK"
  //         } else {
  //           console.log("User clicked Cancel");
  //           return;
  //           // Logic for "Cancel"
  //         }
  //       }

  //     } else {
  //       throw new Error("Failed to update template status.");
  //     }
  //   } catch (error) {
  //       return;
  //   }

  //   // return false;
  //   const isActivating = event.target.checked; // Check if the user is activating or deactivating
  //   const updatedList = [...schedulelist]; // Clone the current list

  //   // Prevent deactivating the last active template
  //   if (
  //     !isActivating &&
  //     schedulelist.filter((item) => item.is_active).length === 1
  //   ) {
  //     alert("At least one template must be active.");
  //     return;
  //   }

  //   // Update the state for activation or deactivation
  //   updatedList.forEach((template, idx) => {
  //     template.is_active = idx === index ? isActivating : false; // Activate the selected template, deactivate others
  //   });

  //   var bodyFormData = {
  //     template_name: template_name,
  //     is_active: isActivating,
  //   };

  //   try {
  //     const response = await makePostRequest(
  //       "schedular/ChangeAdminScheduleTemplate",
  //       bodyFormData
  //     );

  //     if (response.data) {
  //       // Only update the state if the API call is successful
  //       setScheduleList(updatedList);
  //       getAllScheduleList(); // Refresh the data after the state update
  //     } else {
  //       throw new Error("Failed to update template status.");
  //     }
  //   } catch (error) {
  //     console.error("Error updating template status:", error);

  //     // Revert changes in case of an error
  //     const revertedList = [...schedulelist];
  //     revertedList[index].is_active = !isActivating;
  //     setScheduleList(revertedList);

  //     alert("Failed to update the template status. Please try again.");
  //   }

  //   console.log(
  //     `${updatedList[index].template_name} is now ${
  //       isActivating ? "active" : "inactive"
  //     }`
  //   );
  // };

  const [taskname, setTaskname] = React.useState("");
  const [locname, setLocname] = React.useState("");

  const [sound, setSound] = useState(0);

  const handleTaskName = (e) => {
    if (e.target.value != "") {
      setTaskname(e.target.value);
    }
  };

  const handleLocName = (e) => {
    if (e.target.value != "") {
      setLocname(e.target.value);
    }
  };

  const addLocationList = async (locName) => {
    setWhiteSpaceErrorTaskList("");
    if (locName.trim() === "") {
      setWhiteSpaceErrorTaskList("White space not allowed");
    } else {
      setWhiteSpaceErrorTaskList("");
      const formData = {
        company_id: parseInt(localStorage.getItem("companyId")),
        loc_name: locName,
        is_deleted: false,
      };

      await makePostRequest("location/insertlocation", formData)
        .then((response) => {
          if (response.data) {
            console.log(response);
            swal("Success", "Location added successfully", "success", {
              timer: 1500,
              buttons: false,
            });
            getAllLocationList(null);
          }
          setLocname("");
          setWhiteSpaceErrorTaskList("");
          setShowLoader(false);
        })
        .catch((err) => {
          console.log("Err", err);
        });
    }
  };

  const addTaskList = async (taskName) => {
    setWhiteSpaceErrorTaskList("");
    if (taskName.trim() === "") {
      setWhiteSpaceErrorTaskList("White space not allowed");
    } else {
      setWhiteSpaceErrorTaskList("");
      const formData = {
        task_name: taskName,
        status: true,
        is_deleted: false,
        is_multiple_person_task: false,
      };

      await makePostRequest("task/inserttask", formData)
        .then((response) => {
          console.log("error with response===>" + response);
          if (response.data) {
            console.log(response);
            swal("Success", "Task added successfully", "success", {
              timer: 1500,
              buttons: false,
            });
            getAllTaskList(null);
          }
          setTaskname("");
          setWhiteSpaceErrorTaskList("");
          setShowLoader(false);
        })
        .catch((err) => {
          console.log("Err", err);
        });
    }
  };

  useEffect(() => {
    if (!("webkitSpeechRecognition" in window)) {
      alert(
        "Your browser does not support speech recognition. Please try Chrome."
      );
      return;
    }

    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = false;

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setLocname(transcript);
      if (transcript !== null) {
        addLocationList(transcript);
        
      }
      setIsListeningLoc(false);
    };

    recognition.onend = () => {
      setIsListeningLoc(false);
    };

    if (isListeningLoc) {
      recognition.start();
    }

    return () => {
      recognition.stop();
    };
  }, [isListeningLoc]);

  const handleStartListeningLocation = () => {
    setIsListeningLoc(true);
    document.getElementById("loc_name").focus();
    setLocname("");
    setSound(1);
  };

  useEffect(() => {
    if (!("webkitSpeechRecognition" in window)) {
      alert(
        "Your browser does not support speech recognition. Please try Chrome."
      );
      return;
    }

    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = false;

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setTaskname(transcript);
      if (transcript !== null) {
        addTaskList(transcript);
      }
      setIsListening(false);
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    if (isListening) {
      recognition.start();
    }

    return () => {
      recognition.stop();
    };
  }, [isListening]);

  const handleStartListening = () => {
    setIsListening(true);
    document.getElementById("task_name").focus();
    setTaskname("");
    setSound(1);
  };

  const [frequency, setFrequency] = React.useState("");
  const [isListeningFrequency, setIsListeningFrequency] = useState(false);

  const addFrequency = async (frequency) => {
    setWhiteSpaceErrorShift("");
    if (frequency.trim() === "") {
      setWhiteSpaceErrorShift("White space not allowed");
    } else {
      setWhiteSpaceErrorShift("");
      const formData = {
        frequancy_name: frequency,
        is_deleted: false,
        status: true,
      };

      await makePostRequest("frequancy/insertfrequancy", formData)
        .then((response) => {
          console.log("error with response===>" + response);
          if (response.data) {
            console.log(response);
            swal("Success", "Shift added successfully", "success", {
              timer: 1500,
              buttons: false,
            });
            getAllFrequancyList(null);
          }
          setFrequency("");
          setWhiteSpaceErrorShift("");
          setShowLoader(false);
        })
        .catch((err) => {
          console.log("Err", err);
        });
    }
  };

  useEffect(() => {
    if (!("webkitSpeechRecognition" in window)) {
      alert(
        "Your browser does not support speech recognition. Please try Chrome."
      );
      return;
    }

    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = false;

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setFrequency(transcript);
      if (transcript !== null) {
        addFrequency(transcript);
      }
      setIsListeningFrequency(false);
    };

    recognition.onend = () => {
      setIsListeningFrequency(false);
    };

    if (isListeningFrequency) {
      recognition.start();
    }

    return () => {
      recognition.stop();
    };
  }, [isListeningFrequency]);

  const handleStartListeningFrequency = () => {
    setIsListeningFrequency(true);
    document.getElementById("frequancy_name").focus();
    setFrequency(" ");
    setSound(1);
  };

  const [Category, setCategory] = React.useState("");
  const [isListeningCategory, setIsListeningCategory] = useState(false);

  const addCategory = async (category) => {
    setWhiteSpaceErrorTaskGroup("");
    console.log("CAtegory", category);
    if (category.trim() === "") {
      setWhiteSpaceErrorTaskGroup("White space not allowed");
    } else {
      setWhiteSpaceErrorTaskGroup("");
      const formData = {
        category_name: category.trim(""),
        frequancy_ids: [],
        is_deleted: false,
        status: true,
      };

      await makePostRequest("category/insertcategory", formData)
        .then((response) => {
          console.log("error with response===>" + response);
          if (response.data) {
            console.log(response);
            swal("Success", "Task group added successfully", "success", {
              timer: 1500,
              buttons: false,
            });
            getAllCategoryList(null);
          }
          setCategory("");
          setWhiteSpaceErrorTaskGroup("");
          setShowLoader(false);
        })
        .catch((err) => {
          if (err.response.status == 409) {
            let errMsg = err.response.data.message;
            swal("Error", errMsg, "error", {
              timer: 1500,
              buttons: false,
            });
          }
          console.log("Err", err);
        });
    }
  };

  useEffect(() => {
    if (!("webkitSpeechRecognition" in window)) {
      alert(
        "Your browser does not support speech recognition. Please try Chrome."
      );
      return;
    }

    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = false;

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setCategory(transcript);
      if (transcript !== null) {
        addCategory(transcript);
      }
      setIsListeningCategory(false);
    };

    recognition.onend = () => {
      setIsListeningCategory(false);
    };

    if (isListeningCategory) {
      recognition.start();
    }

    return () => {
      recognition.stop();
    };
  }, [isListeningCategory]);

  const handleStartListeningCategory = () => {
    setIsListeningCategory(true);
    document.getElementById("category_name").focus();
    setCategory("");
    setSound(1);
  };

  //code by yamini
  const weekdayMap = {
    M: "Monday",
    T: "Tuesday",
    W: "Wednesday",
    Th: "Thursday",
    F: "Friday",
    S: "Saturday",
    Su: "Sunday",
  };

  const weekdays1 = Object.keys(weekdayMap);

  // Define `dateRange` with initial values as today's week range
  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf("week").add(1, "days").format("YYYY-MM-DD"),
    endDate: moment().endOf("week").add(1, "days").format("YYYY-MM-DD"),
  });

  const [availweekstartdate, Setavailweekstartdate] = React.useState();
  const getStartWeekdate = () => {
    makeGetRequest("employee/getStartingWeekDate")
      .then((response) => {
        console.log(response.data.data);
        if (response.data.data) {
          Setavailweekstartdate(response.data.data);
        } else {
          Setavailweekstartdate();
        }
      })
      .catch((err) => {});
  };

  const getMondays = (numWeeks) => {
    const mondays = [];
    let currentDate;

    console.log("avail week start", availweekstartdate);
    // currentDate = moment();
    // if (availweekstartdate == "") {
    currentDate = moment();

    // } else {
    //   currentDate = moment(availweekstartdate);
    // }

    // Get the current date

    // Find the previous Monday (or current day if it's Monday)
    const currentMonday = currentDate.startOf("week").add(1, "days");
    // const nextMonday = currentDate.startOf("week").add(1, "weeks").add(1, "days");

    for (let i = 0; i < numWeeks; i++) {
      mondays.push(currentMonday.clone().add(i * 7, "days"));
    }

    return mondays;
  };

  // Generate an array of Mondays for the next 'n' weeks
  const mondays = getMondays(100); // Adjust the number of weeks if needed

  const [selectedMonday, setSelectedMonday] = useState(mondays[3]); // Default to the first Monday
  let weekdayDates = [];
  const getCurrentWeekDates = (startDate) => {
    const weekDates = [];
    const startOfWeek = moment(startDate);
    const day = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    for (let i = 0; i < 7; i++) {
      weekDates.push(startOfWeek.clone().add(i, "days").format("YYYY-MM-DD"));
      weekdayDates[day[i]] = startOfWeek
        .clone()
        .add(i, "days")
        .format("YYYY-MM-DD");
    }

    return weekDates;
  };

  // Weekday dates will dynamically update based on `dateRange.startDate`
  const weekDates = getCurrentWeekDates(dateRange.startDate);

  // Handle Monday change from the slider
  const handleMondayChange = (newMonday) => {
    const startDate = newMonday.format("YYYY-MM-DD");
    const endDate = newMonday.clone().add(6, "days").format("YYYY-MM-DD");
    // mondays = getMondays(selectedMonday, 1);
    setDateRange({ startDate, endDate }); // Update state with the new date range

    getEmployeeAvailability(startDate, endDate, sch_ids);
    getEmployeePreferencesForAdmin(startDate, endDate);
  };

  const [availability, setAvailability] = React.useState({});
  const [availabilitystatus, setAvailabilityStatus] = React.useState([]);
  const [availabilitystatuspartial, setAvailabilityStatusPartial] =
    React.useState([]);
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const sliderRef = useRef(null);
  const dayMapping = {
    M: "Monday",
    T: "Tuesday",
    W: "Wednesday",
    Th: "Thursday",
    F: "Friday",
    S: "Saturday",
    Su: "Sunday",
  };
  const weekdayAbbr = Object.keys(dayMapping);
  const [selectedDay, setSelectedDay] = useState("Monday"); // Initialize with "M" for Monday
  const [showPreferences, setShowPreferences] = useState(false); // State to show preferences

  // const handleDayClick = (day) => {
  //   console.log(day);
  //   setSelectedDay(dayMapping[day]); // Update the selected day state when a day is clicked

  //   // getEmployeeAvailability(initialStartDate, initialEndDate, sch_ids);
  // };

  // Handle Preferences click
  const handlePreferencesClick = () => {
    SetAbb("Pref");
    setShowPreferences(!showPreferences); // Toggle Preferences view
    if (sliderRef.current) {
      // Move slider to Preferences slide (index 0)
      sliderRef.current.slickGoTo(0);
    }
  };

  const [abb, SetAbb] = React.useState("Pref");
  const handleDayClick = (dayAbbreviation) => {
    const fullDay = dayMapping[dayAbbreviation]; // Get the full day name
    SetAbb(dayAbbreviation);
    setSelectedDay(fullDay); // Set the selected day as full name

    // Get the slide index based on the full day
    const dayIndex = weekdayAbbr.indexOf(dayAbbreviation) + 1;

    // Move slider to the selected day index
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(dayIndex);
    }
  };
  const getEmployeeAvailability = async (startDate, endDate, sch_ids) => {
    console.log(sch_ids);

    let formData = {
      startDate: startDate, // Pass the start date
      endDate: endDate, // Pass the end date
      sch_id: sch_ids,
    };
    setShowLoader(true);

    makePostRequest("employee/getAvailabilityData", formData)
      .then((response) => {
        const scheduleData = response?.data?.data;
        if (scheduleData && Object.keys(scheduleData).length > 0) {
          // Iterate through each day's data and populate selectedEmployees state based on is_emp_available
          setAvailability(scheduleData);
        } else {
          setAvailability([]);
          // setAvailabilityStatusPartial([])
          setShowLoader(false);
        }

        checkAvailability(startDate, sch_ids);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const checkAvailability = async (startDate, schids) => {
    try {
      const availabilityResults = {};

      // Convert startDate to moment object
      const startMoment = moment(startDate);

      // Loop through the abbreviated weekdays
      for (const day of weekdays1) {
        const fullDay = weekdayMap[day]; // Get the full name from the map

        // Calculate the specific weekdate for the current day
        const weekdate = startMoment.clone().day(fullDay); // Format as YYYY-MM-DD
        // If the calculated date is before the start date, add 7 days to move to the next week
        if (weekdate.isBefore(startMoment)) {
          weekdate.add(7, "days");
        }

        // Format as YYYY-MM-DD
        const formattedWeekdate = weekdate.format("YYYY-MM-DD");

        let formData = {
          weekdate: formattedWeekdate, // Include the calculated weekdate
          sch_id: schids,
          weekday: fullDay,
        };

        console.log(formData);
        const response = await makePostRequest(
          "employee/showWeeklyAvailabilityStatusToAdmin",
          formData
        );

        console.log("day : ", day + " avail: ", response.data.data[0].status);
        if (response.data.data.length > 0)
          availabilityResults[day] = response.data.data[0].status;
        // true or false
        else availabilityResults[day] = false;
      }

      setAvailabilityStatus(availabilityResults);
    } catch (error) {
      console.error("Error fetching availability:", error);
    }
  };

  const [employee, setEmployee] = React.useState([]);

  async function getEmployeePreferencesForAdmin(startDate, endDate) {
    let bodyFormData = {
      search: search,
      startDate: startDate,
      endDate: endDate,
    };
    await makePostRequest(
      "schedular/getEmployeePreferencesForAdmin",
      bodyFormData
    )
      .then((response) => {
        if (response.data) {
          console.log(response.data.data);
          setEmployee(response.data.data);
        } else {
          setEmployee([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const [search, setSearch] = React.useState("");
  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    getEmployeePreferencesForAdmin(dateRange.startDate, dateRange.endDate);
  }, []);

  // const deleteTemplate = async (template_name) => {
  //   setShowLoader(true);

  //   swal({
  //     title: "Confirm Delete?",
  //     text: "You want to delete this template.",
  //     icon: "warning",
  //     buttons: true,
  //     dangerMode: true,
  //   }).then(async (willDelete) => {
  //     if (willDelete) {
  //       try {
  //         let bodyFormData = {
  //           template_name,
  //         };
  //         const response = await makePostRequest(
  //           "schedular/deleteTemplate",
  //           bodyFormData
  //         );
  //         if (response.data.data) {
  //           setShowLoader(false);
  //           getAllScheduleList();
  //           getAllArchiveTemplateList();
  //         }
  //       } catch (error) {
  //         console.error(`Error in deleting:`, error);
          
  //       }
  //     } else {
  //       //swal("Your file is safe!");
  //       setShowLoader(false);
  //     }
  //   });
  // };




  const deleteTemplate = async (template_name) => {
    setShowLoader(true);
  
    swal({
      title: "Confirm Delete?",
      text: "You want to delete this template.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          let bodyFormData = { template_name };
  
          const response = await makePostRequest(
            "schedular/deleteTemplate",
            bodyFormData
          );
  
          setShowLoader(false);
  
          if (response.data) {
            swal("Deleted!", "Template has been deleted successfully.", "success");
            getAllScheduleList();
            getAllArchiveTemplateList();
          }
        } catch (error) {
          setShowLoader(false);
  
          console.error("Error in deleting:", error);
  
          let errorMessage = "Something went wrong. Please try again.";
  
          if (error.response && error.response.data && error.response.data.message) {
            errorMessage = error.response.data.message; // API error message
          }
  
          swal("Error!", errorMessage, "error");
        }
      } else {
        setShowLoader(false);
      }
    });
  };
  

  const archiveTemplate = async (template_name) => {
    setShowLoader(true);

    swal({
      title: "Confirm Archive?",
      text: "You want to archive this template.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          let bodyFormData = {
            template_name,
          };
          const response = await makePostRequest(
            "schedular/archiveTemplate",
            bodyFormData
          );
          if (response.data.data) {
            setShowLoader(false);
            getAllScheduleList();
            getAllArchiveTemplateList();
          }
        } catch (error) {
          setShowLoader(false);
          console.error(`Error in archiving:`, error);
        }
      } else {
        //swal("Your file is safe!");
      }
    });
  };

  //end code

  const checkActiveStatus = async (status, id) => {
    console.log("StatusActive", status);
    setShowLoader(true);
    const formData = {
      frequancyid: id,
      status: status,
    };
    await makePostRequest("frequancy/updatefrequancystatus", formData)
      .then((response) => {
        console.log("error with response===>" + response);
        if (response.data) {
          getAllFrequancyList(null);
          setShowLoader(false);
          localStorage.setItem("TabName", "frequency");
        }
      })
      .catch((err) => {
        console.log("Err", err);
        let errMsg = err.message;
        setShowLoader(false);
      });
  };

  return (
    <>
      <TopBar />
      <div className="wrapper">
        <div className="taskListAdmMain forUpperBottomBt">
          {/* TopBar */}
          <div className="innerPageTopSecBackPatch"></div>
          <div className="innerPageTopSec">
            {/* <div className="forBackArrow forAppDrawer">
              <AppDrawer />
            </div> */}
            <h1>Manage Tasks & Category</h1>
            {/* <IconButton
              className="addEmpTpBt"
              onClick={() => navigate("/AdminAddTask")}
            >
              <AddIcon />
            </IconButton> */}

            {/* <div className="myFlotingBtMain adminTaskFloatingMain">
              <div class="fab-wrapper">
                <input id="fabCheckbox" type="checkbox" class="fab-checkbox" />
                <label class="fab" for="fabCheckbox">
                  <span class="fab-dots fab-dots-1"></span>
                  <span class="fab-dots fab-dots-2"></span>
                  <span class="fab-dots fab-dots-3"></span>
                </label>
                <div class="fab-wheel">
                  <a
                    class="fab-action fab-action-1"
                    onClick={() => navigate("/AdminAddTask")}
                  >
                    <span className="floatinTx">Add Task</span>
                    <AddIcon />
                  </a>
                  <a
                    class="fab-action fab-action-2"
                    onClick={() => navigate("/AddFrequency")}
                  >
                    <span className="floatinTx">Add Frequency</span>
                    <AddIcon />
                  </a>
                  <a
                    class="fab-action fab-action-3"
                    onClick={() => navigate("/AddCategory")}
                  >
                    <span className="floatinTx">Add Category</span>
                    <AddIcon />
                  </a>
                  <a
                    class="fab-action fab-action-4"
                    onClick={() => navigate("/CreateNewSchedule")}
                  >
                    <span className="floatinTx">Create New Schedule</span>
                    <AddIcon />
                  </a>
                </div>
              </div>
            </div> */}
            {/* <div className="forBackArrow"></div> */}
          </div>
          {/* TopBarEnd */}

          <div className="tabsMainD addTaskNCateTabMain">
            <Button
              className="schedTsBt"
              onClick={() => navigate("/TasksScheduler")}
            >
              <div className="schedTsBtIco">
                <AssignmentTurnedInIcon />
              </div>{" "}
              Schedule your tasks <ArrowForwardIcon />
            </Button>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  scrollButtons={false}
                  className={`kkk ${scrolling ? "scrolledTab" : ""}`}
                >
                  <Tab label="Task Groups" {...a11yProps(0)} />
                  <Tab label="All Tasks" {...a11yProps(1)} />
                  <Tab label="Location" {...a11yProps(2)} />
                  {/* <Tab label="Employee Shifts" {...a11yProps(2)} /> */}
                  {/* <Tab label="Schedule" {...a11yProps(3)} /> */}
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <div className="tabInrContDiv">
                  {/* <Button
                    className="logginBt addNewB"
                    onClick={() => navigate("/AddCategory")}
                  >
                    Add Tasks List
                  </Button> */}
                  <div style={{ gap: 10, marginBottom: 10 }}>
                    <div className="withPlusIcon">
                      <div className="tabMickMainInp tabMickMainInpnew myMick">
                        {isListeningCategory ? (
                          <img
                            src={speak_animation}
                            className="voiceAnimation"
                          />
                        ) : null}
                        <input
                          type="text"
                          placeholder="Speak or type to add task group name"
                          name="category_name"
                          id="category_name"
                          value={Category}
                          onChange={(e) => setCategory(e.target.value)}
                        />
                        <SettingsVoiceOutlinedIcon
                          className="microphone"
                          onClick={handleStartListeningCategory}
                          disabled={isListeningCategory}
                        />
                      </div>
                      <IconButton
                        className="addTskIco"
                        aria-label="add"
                        onClick={() => addCategory(Category)}
                      >
                        <AddIcon />
                      </IconButton>
                    </div>
                    <p style={{ color: "red" }}>{whiteSpaceErrorTaskGroup}</p>
                  </div>
                  <div className="listingSearch">
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => getAllCategoryList(e.target.value)}
                    />
                    <div className="sortingIcoOut">
                      <ToggleButton
                        value="check"
                        selected={selected}
                        onClick={() => handleChangeSortByCategory()}
                      >
                        <ImportExportIcon
                          className="visitorFilterIco"
                          aria-haspopup="true"
                        />
                      </ToggleButton>
                    </div>
                  </div>
                  <div className="employyeListingMain">
                    {categoryList ? (
                      <>
                        {categoryList.map((cateObj) => (
                          <div
                            id={"bugfree-" + cateObj.categoryid}
                            // className={`swipe-container ${
                            //   swipeDirection === "left" ? "swiped-left" : ""
                            //   } ${swipeDirection === "right" ? "swiped-right" : ""}`}
                            className={"swipe-container"}
                            onTouchStart={(e) =>
                              handleTouchStart(e, cateObj.categoryid)
                            }
                            onTouchMove={(e) =>
                              handleTouchMove(e, cateObj.categoryid)
                            }
                            onClick={(e) =>
                              handleTouchEnd(e, cateObj.categoryid)
                            }
                          >
                            <div className="deleEdiBtLeft">
                              <IconButton onClick={() => editCategory(cateObj)}>
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  navigate("/CategoryDetails", {
                                    state: { Id: cateObj.categoryid },
                                  })
                                }
                              >
                                <InfoOutlinedIcon />
                              </IconButton>
                            </div>
                            <div
                              className="emploTaskListInr assignFirstLabel"
                              id="listID"
                              onClick={swipeNSelection}
                            >
                              <div>
                                <h1>{cateObj.category_name}</h1>
                                {cateObj.frequancy_ids == 0 ? (
                                  <p className="errorSt">
                                    Please Add Employee Shift for This Task
                                  </p>
                                ) : null}
                              </div>
                              {/* <div className="taskImUpload">
                          <input type="file" />
                          <FilterIcon onClick={handleClickOpen} />
                        </div> */}
                            </div>
                            <div className="deleEdiBtRight">
                              <IconButton onClick={resetSwipeDirection}>
                                <RestartAltIcon className="RestartAltIcon" />
                              </IconButton>

                              {addcat(
                                selectedCategorylist,
                                cateObj.categoryid
                              ) ? (
                                <></>
                              ) : (
                                <IconButton
                                  onClick={() =>
                                    deleteCategory(cateObj.categoryid)
                                  }
                                >
                                  <DeleteIcon className="DeleteIcon" />
                                </IconButton>
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  {/* <div className="upperBottomBt">
                    <Button className="logginBt">Schedule Tasks</Button>
                  </div> */}
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <div className="tabInrContDiv">
                  {/*  <Button
                    className="logginBt addNewB"
                    onClick={() => navigate("/AdminAddTask")}
                  >
                    Add Task
                  </Button>*/}
                  <div style={{ gap: 10, marginBottom: 10 }}>
                    <div className="withPlusIcon">
                      <div className="tabMickMainInp tabMickMainInpnew myMick">
                        {isListening ? (
                          <img
                            src={speak_animation}
                            className="voiceAnimation"
                          />
                        ) : null}
                        <input
                          type="text"
                          placeholder="Speak or type to add task"
                          name="task_name"
                          id="task_name"
                          value={taskname}
                          onChange={(e) => setTaskname(e.target.value)}
                          onBlur={(e) => handleTaskName(e)}
                        />
                        <SettingsVoiceOutlinedIcon
                          className="microphone"
                          onClick={handleStartListening}
                          disabled={isListening}
                        />
                      </div>
                      <IconButton
                        className="addTskIco"
                        aria-label="add"
                        onClick={() => addTaskList(taskname)}
                      >
                        <AddIcon />
                      </IconButton>
                    </div>
                    <p style={{ color: "red" }}>{whiteSpaceErrorTaskList}</p>
                  </div>
                  <div className="listingSearch">
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => getAllTaskList(e.target.value)}
                    />
                    <div onClick={() => handleChangeSortByTask()}>
                      <div className="sortingIcoOut">
                        <ToggleButton
                          value="check"
                          selected={selected}
                          onChange={() => {
                            handleChangeSortBy();
                          }}
                        >
                          <ImportExportIcon
                            className="visitorFilterIco"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </div>
                  </div>
                  <div className="employyeListingMain">
                    {taskList ? (
                      <>
                        {taskList.map((taskObj) => (
                          <div
                            id={"bugfree-" + taskObj.taskid}
                            // className={`swipe-container ${
                            //   swipeDirection === "left" ? "swiped-left" : ""
                            //   } ${swipeDirection === "right" ? "swiped-right" : ""}`}
                            className="swipe-container"
                            onTouchStart={(e) =>
                              handleTouchStart(e, taskObj.taskid)
                            }
                            onTouchMove={(e) =>
                              handleTouchMove(e, taskObj.taskid)
                            }
                            onClick={(e) => handleTouchEnd(e, taskObj.taskid)}
                          >
                            <div className="deleEdiBtLeft">
                              <IconButton onClick={() => editTask(taskObj)}>
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  navigate("/TaskDetails", {
                                    state: { Id: taskObj.taskid },
                                  })
                                }
                              >
                                <InfoOutlinedIcon />
                              </IconButton>
                            </div>
                            <div
                              className="emploTaskListInr"
                              id="listID"
                              onClick={swipeNSelection}
                            >
                              <div>
                                <h1>{taskObj.task_name}</h1>
                              </div>
                              <div className="taskImUpload">
                                {/* <FilterIcon onClick={handleClickOpen} /> */}
                                {taskObj.is_beforeafter == true ? (
                                  <div className="taskImUpload">
                                    <FilterIcon />
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                              {/* <div className="taskImUpload">
                          <input type="file" />
                          <FilterIcon onClick={handleClickOpen} />
                        </div> */}
                            </div>
                            <div className="deleEdiBtRight">
                              <IconButton onClick={resetSwipeDirection}>
                                <RestartAltIcon className="RestartAltIcon" />
                              </IconButton>

                              {add(selectedTaskList, taskObj.taskid) ? (
                                <></>
                              ) : (
                                <IconButton
                                  onClick={() => deleteTask(taskObj.taskid)}
                                >
                                  <DeleteIcon className="DeleteIcon" />
                                </IconButton>
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  {/* <div className="upperBottomBt">
                    <Button className="logginBt">Schedule Tasks</Button>
                  </div> */}
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <div className="tabInrContDiv">
                  <div style={{ gap: 10, marginBottom: 10 }}>
                    <div className="withPlusIcon">
                      <div className="tabMickMainInp tabMickMainInpnew myMick">
                        {isListening ? (
                          <img
                            src={speak_animation}
                            className="voiceAnimation"
                          />
                        ) : null}
                        <input
                          type="text"
                          placeholder="Speak or type to add location"
                          name="loc_name"
                          id="loc_name"
                          value={locname}
                          onChange={(e) => setLocname(e.target.value)}
                          onBlur={(e) => handleLocName(e)}
                        />
                        <SettingsVoiceOutlinedIcon
                          className="microphone"
                          onClick={handleStartListeningLocation}
                          disabled={isListeningLoc}
                        />
                      </div>
                      <IconButton
                        className="addTskIco"
                        aria-label="add"
                        onClick={() => addLocationList(locname)}
                      >
                        <AddIcon />
                      </IconButton>
                    </div>
                    <p style={{ color: "red" }}>{whiteSpaceErrorTaskList}</p>
                  </div>
                  <div className="listingSearch">
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => getAllLocationList(e.target.value)}
                    />
                  </div>
                  <div className="employyeListingMain">
                    {loclist.length > 0 ? (
                      <>
                        {loclist.map((taskObj) => (
                          <div
                            id={"bugfree-" + taskObj.loc_id}
                            className="swipe-container"
                            onTouchStart={(e) =>
                              handleTouchStart(e, taskObj.loc_id)
                            }
                            onTouchMove={(e) =>
                              handleTouchMove(e, taskObj.loc_id)
                            }
                            onClick={(e) => handleTouchEnd(e, taskObj.loc_id)}
                          >
                            <div className="deleEdiBtLeft">
                              <IconButton onClick={() => editLoc(taskObj)}>
                                <EditIcon />
                              </IconButton>
                            </div>
                            <div
                              className="emploTaskListInr"
                              id="listID"
                              onClick={swipeNSelection}
                            >
                              <div>
                                <h1>{taskObj.loc_name}</h1>
                              </div>
                            </div>
                            <div className="deleEdiBtRight">
                              {/* <IconButton onClick={resetSwipeDirection}>
                                <RestartAltIcon className="RestartAltIcon" />
                              </IconButton> */}

                              {add(selectedTaskList, taskObj.loc_id) ? (
                                <></>
                              ) : (
                                <IconButton
                                  onClick={() => deleteLocation(taskObj)}
                                >
                                  <DeleteIcon className="DeleteIcon" />
                                </IconButton>
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  {/* <div className="upperBottomBt">
                    <Button className="logginBt">Schedule Tasks</Button>
                  </div> */}
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <div className="tabInrContDiv">
                  {/* <Button
                    className="logginBt addNewB"
                    onClick={() => navigate("/AddFrequency")}
                  >
                    Add Employee Shifts
                  </Button> */}
                  {/* Commented By Kailas */}
                  <div style={{ gap: 10, marginBottom: 10 }}>
                    <div className="withPlusIcon">
                      <div className="tabMickMainInp tabMickMainInpnew myMick">
                        {isListeningFrequency ? (
                          <img
                            src={speak_animation}
                            className="voiceAnimation"
                          />
                        ) : null}
                        <input
                          type="text"
                          placeholder="Speak or type to add shift"
                          name="frequancy_name"
                          id="frequancy_name"
                          value={frequency}
                          onChange={(e) => setFrequency(e.target.value)}
                        />
                        <SettingsVoiceOutlinedIcon
                          className="microphone"
                          onClick={handleStartListeningFrequency}
                          disabled={isListeningFrequency}
                        />
                      </div>
                      <IconButton
                        className="addTskIco"
                        aria-label="add"
                        onClick={() => addFrequency(frequency)}
                      >
                        <AddIcon />
                      </IconButton>
                    </div>
                    <p style={{ color: "red" }}>{whiteSpaceErrorShift}</p>
                  </div>
                  <div className="listingSearch">
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => getAllFrequancyList(e.target.value)}
                    />
                    <div className="sortingIcoOut">
                      <ToggleButton
                        value="check"
                        selected={selected}
                        onClick={() => handleChangeSortByFrequency()}
                      >
                        <ImportExportIcon
                          className="visitorFilterIco"
                          aria-haspopup="true"
                        />
                      </ToggleButton>
                    </div>
                  </div>
                  <div className="employyeListingMain">
                    {frequancyList ? (
                      <>
                        {frequancyList.map((frequObj) => (
                          <div
                            id={"bugfree-" + frequObj.frequancyid}
                            className={"swipe-container"}
                            onTouchStart={(e) =>
                              handleTouchStart(e, frequObj.frequancyid)
                            }
                            onTouchMove={(e) =>
                              handleTouchMove(e, frequObj.frequancyid)
                            }
                            onClick={(e) =>
                              handleTouchEnd(e, frequObj.frequancyid)
                            }
                          >
                            <div className="deleEdiBtLeft">
                              <IconButton
                                onClick={() => editFrequency(frequObj)}
                              >
                                <EditIcon />
                              </IconButton>
                            </div>
                            <div
                              className="emploTaskListInr"
                              id="listID"
                              onClick={swipeNSelection}
                            >
                              <div className="activDeactSt emploShifTb">
                                <h1>{frequObj.frequancy_name}</h1>
                                <div className="categorySwitchView">
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={frequObj.status}
                                        onChange={(e) =>
                                          checkActiveStatus(
                                            e.target.checked,
                                            frequObj.frequancyid
                                          )
                                        }
                                      />
                                    }
                                  />
                                </div>
                                {/* <span>
                                  {frequObj.status == true ? (
                                    <p className="actSt">Active</p>
                                  ) : (
                                    <p className="deActSt">Deactive</p>
                                  )}
                                </span> */}
                              </div>
                            </div>
                            <div className="deleEdiBtRight">
                              <IconButton onClick={resetSwipeDirection}>
                                <RestartAltIcon className="RestartAltIcon" />
                              </IconButton>

                              {addFreq(
                                selectedFrequencylist,
                                frequObj.frequancyid
                              ) ? (
                                <></>
                              ) : (
                                <IconButton
                                  onClick={() =>
                                    deleteFrequency(frequObj.frequancyid)
                                  }
                                >
                                  <DeleteIcon className="DeleteIcon" />
                                </IconButton>
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  {/*<div className="upperBottomBt">
                    <Button className="logginBt">Schedule Tasks</Button>
                  </div>*/}
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={4}>
                <div className="tabInrContDiv">
                  <Button
                    className="logginBt addNewB"
                    onClick={() => navigate("/CreateNewSchedule")}
                  >
                    Create New Schedule
                  </Button>
                  <div className="schedulAcordianMain">
                    {schedulelist.map((schlist, index) => {
                      return (
                        schlist.template_name !== "" && (
                          <div className="schedulAcordianInr">
                            <div className="schedulAcordianTp">
                              <b>
                                {schlist.template_name}
                                <IconButton
                                  aria-label="edit"
                                  onClick={() =>
                                    editSchedule(schlist, schlist.template_name)
                                  }
                                >
                                  <img src={pencil} />
                                </IconButton>
                              </b>
                              <div className="schedulAcordianTpRight">
                                <IconButton
                                  aria-label="edit"
                                  onClick={() =>
                                    handleClickOpenAval(schlist.sch_ids)
                                  }
                                >
                                  {schlist.is_active && (
                                    <img src={availabilityPreview} />
                                  )}
                                </IconButton>
                                {!schlist.is_active && (
                                  <>
                                    <IconButton>
                                      <DeleteIcon
                                        className="DeleteIcon"
                                        onClick={() =>
                                          deleteTemplate(schlist.template_name)
                                        }
                                      />
                                    </IconButton>
                                    <ArchiveOutlinedIcon
                                      className="archiveIcon"
                                      onClick={() =>
                                        archiveTemplate(schlist.template_name)
                                      }
                                    />
                                  </>
                                )}
                                <Switch
                                  {...label}
                                  checked={schlist.is_active} // Set the checked state based on is_active
                                  onChange={(event) =>
                                    handleSwitchChange(
                                      event,
                                      index,
                                      schlist.template_name
                                    )
                                  }
                                  disabled={
                                    schlist.is_active &&
                                    schedulelist.filter(
                                      (item) => item.is_active
                                    ).length === 1
                                  }
                                />
                              </div>
                            </div>
                            <div className="schedulAcordianCont">
                              <div className="tabsMainD tabsMainDInr">
                                <Box sx={{ width: "100%" }}>
                                  <Box
                                    sx={{
                                      borderBottom: 1,
                                      borderColor: "divider",
                                    }}
                                  >
                                    <Tabs
                                      value={
                                        tabValues[schlist.template_name] || 0
                                      }
                                      onChange={(event, newValue) =>
                                        handleChangeTab(
                                          schlist.template_name,
                                          newValue
                                        )
                                      }
                                      aria-label="basic tabs example"
                                      variant="scrollable"
                                      scrollButtons={false}
                                    >
                                      {schlist.distinct_locations.map(
                                        (loc, locIndex) => (
                                          <Tab
                                            key={locIndex}
                                            label={loc}
                                            {...a11yProps2({ locIndex })}
                                            onClick={() =>
                                              getlocationwiseSummarycount(
                                                loc,
                                                schlist.template_name
                                              )
                                            }
                                          />
                                        )
                                      )}
                                    </Tabs>
                                  </Box>
                                  {schlist.distinct_locations.map(
                                    (loc, locIndex) => (
                                      <CustomTabPanel
                                        value={
                                          tabValues[schlist.template_name] || 0
                                        }
                                        index={locIndex}
                                        key={locIndex}
                                      >
                                        <div className="tabInrContDiv">
                                          <div className="shiftDaysMain">
                                            <div className="shiftDaysInr">
                                              <div className="weeklyTaskTbl">
                                                <table width="100%">
                                                  <tbody>
                                                    <tr>
                                                      <td>
                                                        <b>
                                                          D<em>ays</em>
                                                        </b>
                                                      </td>
                                                      <td>M</td>
                                                      <td>T</td>
                                                      <td>W</td>
                                                      <td>T</td>
                                                      <td>F</td>
                                                      <td>S</td>
                                                      <td
                                                        style={{ color: "red" }}
                                                      >
                                                        S
                                                      </td>
                                                    </tr>
                                                    {/* Shifts Row */}
                                                    <tr>
                                                      <td>
                                                        <b>
                                                          S<em>hifts</em>
                                                        </b>
                                                      </td>
                                                      {weekdays.map((day) => (
                                                        <td key={day}>
                                                          {templateDayData[
                                                            schlist
                                                              .template_name
                                                          ]?.shifts[day] || 0}
                                                        </td>
                                                      ))}
                                                    </tr>

                                                    {/* Employees Row */}
                                                    <tr>
                                                      <td>
                                                        <b>
                                                          E<em>mp</em>
                                                        </b>
                                                      </td>
                                                      {weekdays.map((day) => (
                                                        <td key={day}>
                                                          {templateDayData[
                                                            schlist
                                                              .template_name
                                                          ]?.emp[day] || 0}
                                                        </td>
                                                      ))}
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </CustomTabPanel>
                                    )
                                  )}
                                </Box>
                              </div>
                            </div>
                          </div>
                        )
                      );
                    })}

                    <div className="archiveListingMain">
                      <h1 className="archiveListingHed">Archive</h1>
                      {archive.length > 0 ? (
                        archive.map((data, index) => (
                          <div className="schedulAcordianTp" key={index}>
                            <b>{data}</b>
                            <div className="schedulAcordianTpRight">
                              <ArchiveOutlinedIcon
                                className="archiveIcon"
                                onClick={() => navigate("/ArchivePage")}
                              />
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>No Archive Templates</p>
                      )}
                    </div>
                  </div>
                </div>
              </CustomTabPanel>
            </Box>
          </div>

          <AdminBottomNav />
        </div>
      </div>

      {/* BeforAfterImage */}
      <div className="">
        <React.Fragment>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle id="alert-dialog-title">
              <h1 className="modalHeading">Before & After Pictures</h1>
              <IconButton className="modalCloseIco" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="beforAftMain">
                    <div className="beforSt">
                      <h1 className="befAftHeading">Before</h1>
                      <div className="befAftImgDiv">
                        <img src={afterBef1} />
                      </div>
                    </div>
                    <div className="afterSt">
                      <h1 className="befAftHeading">After</h1>
                      <div className="befAftImgDiv">
                        <img src={afterBef2} />
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div>

      {/* Availabiliy */}
      <div className="AvailabilityPopUp">
        <React.Fragment>
          <Dialog
            open={openAval}
            onClose={handleCloseAval}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle id="alert-dialog-title">
              <h1 className="modalHeading">
                Employee Availability Information
              </h1>
              <IconButton className="modalCloseIco" onClick={handleCloseAval}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="shifAvailabiMain">
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12}>
                        <div className="mySlik createScheduSlik">
                          <div className="slider-container">
                            <Slider
                              {...settings}
                              initialSlide={currentweekindex} // Set the initial slide to current Monday
                              afterChange={(index) =>
                                handleMondayChange(mondays[index])
                              }
                            >
                              {mondays.map((monday, index) => (
                                <div key={index}>
                                  <h3>Mon</h3>
                                  <span>
                                    {monday.format("MM/DD/YYYY")}
                                  </span>{" "}
                                  {/* Format the date as desired */}
                                </div>
                              ))}
                            </Slider>
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <div
                          className="setDayMain myAvailibiSetDay "
                          style={{ marginBottom: 10 }}
                        >
                          <div
                            className={`dayInD preferanceBts ${
                              abb == "Pref" ? "activeIndicator" : ""
                            } `}
                            onClick={handlePreferencesClick}
                          >
                            <img src={userWithCheck} style={{ width: 17 }} />
                            <span></span>
                          </div>

                          {weekdayAbbr.map((abbreviation) => (
                            <button
                              key={abbreviation}
                              onClick={() => handleDayClick(abbreviation)}
                              className={`dayInD ${
                                availabilitystatus[abbreviation] == "true"
                                  ? "activDs"
                                  : availabilitystatus[abbreviation] ==
                                    "partially true"
                                  ? "activDsPartial"
                                  : ""
                              } ${
                                abb == abbreviation ? "activeIndicator" : ""
                              } `}
                            >
                              {abbreviation}
                              <span></span>
                            </button>
                            // <button
                            //   key={abbreviation}
                            //   onClick={() => handleDayClick(abbreviation)}
                            //   className={`dayInD ${availabilitystatuspartial.length>0} ? ${
                            //     availabilitystatuspartial[dayMapping[abbreviation]]=='ok' && "activDs"}
                            //     ${ availabilitystatuspartial[dayMapping[abbreviation]]=='partial ok' && "activDsPartial"}
                            //     ${availabilitystatuspartial[dayMapping[abbreviation]]=='not ok' && ""
                            //     } : "" ${
                            //     abb == abbreviation ? "activeIndicator" : ""
                            //   } `}
                            // >
                            //   {abbreviation}
                            //   <span></span>
                            // </button>
                          ))}
                        </div>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <div className="daySlider emplShifSlider">
                          <Slider ref={sliderRef} {...settings2}>
                            <div>
                              <h3 className="slidDayHeading newSLideDayHeading">
                                <b>Preferences</b>
                              </h3>
                              <div className="preferenceTableOuter">
                                <table width="100%">
                                  <thead>
                                    <tr className="preferenceTableHeading">
                                      <th>
                                        <p className="preferenceTableP">
                                          Employee Name
                                        </p>
                                      </th>
                                      <th>
                                        <p className="preferenceTableP">
                                          Hours
                                        </p>
                                      </th>
                                      <th>
                                        <p className="preferenceTableP">
                                          Shifts
                                        </p>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {employee.map((emp, index) => (
                                      <tr>
                                        <td>
                                          <div className="preferenceEmpData">
                                            <div className="preferenceEmpDataInner">
                                              <Zoom>
                                                <img
                                                  src={
                                                    emp.profile_pic != null &&
                                                    emp.profile_pic != ""
                                                      ? emp.profile_pic
                                                      : profilePic
                                                  }
                                                />
                                              </Zoom>
                                            </div>
                                            <h1>{emp.full_name}</h1>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="preferenceHours">
                                            <p>
                                              {emp.no_of_hours > 0
                                                ? emp.no_of_hours + "Hrs"
                                                : "-"}
                                            </p>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="preferenceShifts">
                                            <p>
                                              {emp.no_of_shifts > 0
                                                ? emp.no_of_shifts + "Shifts"
                                                : "-"}{" "}
                                            </p>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            {Object.keys(availability).length > 0 ? (
                              Object.keys(availability).map((day, index) => (
                                <div>
                                  <h3 className="slidDayHeading newSLideDayHeading">
                                    <b>{day}</b>
                                    <span>{weekdayDates[day]}</span>
                                  </h3>
                                  <div
                                    className="schedulAcordianMain"
                                    style={{ marginBottom: 20 }}
                                  >
                                    {availability[day].map((locationData) => (
                                      <div
                                        className="schedulAcordianInr"
                                        key={locationData.location}
                                      >
                                        <div className="schedulAcordianTp">
                                          <b>{locationData.location}</b>
                                          <div className="schedulAcordianTpRight">
                                            {locationData.status ===
                                            "not ok" ? (
                                              <ErrorOutlineIcon
                                                style={{
                                                  color: "red",
                                                }}
                                              />
                                            ) : (
                                              <PlaylistAddCheckIcon
                                                style={{
                                                  color: "#0FB000",
                                                }}
                                              />
                                            )}
                                          </div>
                                        </div>
                                        <div className="schedulAcordianCont">
                                          {locationData.shifts.map((shift) => (
                                            <div
                                              className="ShifPreviewModCont"
                                              key={shift.shift_timing}
                                            >
                                              <h1>
                                                {shift.shift_timing} (
                                                {shift.avail_emp_count}/
                                                {shift.emp_count})
                                                {shift.status === "not ok" && (
                                                  <ErrorOutlineIcon
                                                    style={{
                                                      color: "red",
                                                    }}
                                                  />
                                                )}
                                              </h1>
                                              {shift.employees.map(
                                                (employee) => (
                                                  <div
                                                    className="connectionsDiv"
                                                    style={{
                                                      marginBottom: 0,
                                                    }}
                                                    key={employee.emp_id}
                                                  >
                                                    <div className="pendingReIm">
                                                      <img
                                                        src={
                                                          employee.profile_pic ||
                                                          avarPi
                                                        }
                                                        alt={employee.name}
                                                      />
                                                    </div>
                                                    <div className="pendingRex prefranceTx">
                                                      <h1> {employee.name} </h1>
                                                      <p></p>
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div>No availability data found.</div>
                            )}
                          </Slider>
                          {showLoader ? (
                            <div className="loaderNew">
                              <div className="loader"></div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div>

      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default AdminTasksList;
