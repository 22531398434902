import React from "react";
import { useNavigate } from "react-router-dom";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import cloFaq from "../../../assets/images/unlock.png";
import openFaq from "../../../assets/images/lock.png";
import mark from "../../../assets/images/mark.svg";

function FAQsAdmin() {
  const navigate = useNavigate();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <div className="wrapper innerPagesMain">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <NavigateBeforeIcon onClick={() => navigate(-1)} />
          </div>
          <h1>FAQs</h1>
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}

        <div className="faqsMain">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">What is ToDoBoom?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                ToDoBoom is a task management app designed to help you organize,
                assign, and complete tasks efficiently.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">Is ToDoBoom free to use?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Yes, ToDoBoom offers a free plan.</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                How do I log in to my account?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Download the app, and use your pin to login to the app. (Get
                your PIN from manager)
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">How do I reset my PIN?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Contact your manager to reset your PIN.</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                Can I update my profile information?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, for updating contact information you have to contact to
                your manager
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                What do the icons in the bottom navigation bar represent?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">Bottom tab icon:</p>
                <div className="policyInrCont">
                  <ul>
                    <li>1st Tab - Reports</li>
                    <li>2nd Tab - Tasks Management</li>
                    <li>3rd Tab - Shift Management</li>
                    {/* <li>4th Tab - Shopping List</li> */}
                    <li>5th Tab - More Details </li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">How can I view reports?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">
                  Reports provide a clear understanding of task completion,
                  including details on who completed each task. They are
                  designed to simplify tracking, offering daily summaries for
                  each location. Additionally, the first slide gives you a
                  concise summary of the selected location.
                </p>
                <p className="moreContTx">Steps to View Reports:</p>
                <div className="policyInrCont">
                  <ul>
                    <li>Go to the first tab.</li>
                    <li>
                      Select the desired day from the day slider to view the
                      report.
                    </li>
                    <li>
                      Below the dates, you'll find Task Groups.
                      <ul className="faqInnerUl">
                        <li>
                          For each task group, you can view reports categorized
                          by location.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <p className="moreContTx">
                    This streamlined process helps you quickly monitor and
                    analyze task performance for any location and day.
                  </p>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">What are task groups?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Task groups are collections of tasks organized into specific
                categories. Each category is assigned a unique name and helps
                streamline task management. These categories are conveniently
                listed under the Task Groups tab for easy access and
                organization.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel9"}
            onChange={handleChange("panel9")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                How do I create task groups?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">Steps to create Task group:</p>
                <div className="policyInrCont">
                  <ul>
                    <li>Go to 2nd tab i.e Task management</li>
                    <li>Click on Task Groups from top tabs</li>
                    <li>
                      Using audio feature or input field you are able to add
                      task group.
                    </li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel10"}
            onChange={handleChange("panel10")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                How can I edit a task group?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">Steps to edit Task group:</p>
                <div className="policyInrCont">
                  <ul>
                    <li>Go to 2nd tab i.e Task management</li>
                    <li>Click on Task Groups from top tabs</li>
                    <li>
                      Swipe task group card to right and click on edit icon.
                    </li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel11"}
            onChange={handleChange("panel11")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                How I can assign shifts to task group?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">
                Assigning Shifts to a Task Group:
                </p>
                <div className="policyInrCont">
                  <ul>
                    <li>Go to the Task Management tab.</li>
                    <li>Tap on Task Groups from the top menu.</li>
                    <li>Tap on Task Groups from the top menu.
                    </li>
                    <li>In the edit screen, select Shifts and save the task group.
                    </li>
                  </ul>
                </div>
                <p>Your shifts are now assigned to the task group!</p>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel12"}
            onChange={handleChange("panel12")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                How can I view details of a task group?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">Viewing Task Group Details:</p>
                <div className="policyInrCont">
                  <ul>
                    <li>Navigate to the Task Management tab.</li>
                    <li>Tap on Task Groups from the top menu.</li>
                    <li>Swipe right on a task group card and tap the Info icon. </li>
                  </ul>
                </div>
                <p>Now you can see all the details of your task group!</p>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel13"}
            onChange={handleChange("panel13")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">How do I see all tasks?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">Viewing All Tasks:</p>
                <div className="policyInrCont">
                  <ul>
                    <li>Open the Task Management tab.</li>
                    <li>Tap on All Tasks from the top menu.
                    </li>
                  </ul>
                </div>
                <p>Now you can see the complete list of tasks in one place!</p>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel14"}
            onChange={handleChange("panel14")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                How can I create a new task?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">How to Add New Tasks:</p>
                <div className="policyInrCont">
                  <ul>
                    <li>Open the Task Management tab.</li>
                    <li>Tap on All Tasks from the top menu.                    </li>
                    <li>Create a new task effortlessly using voice input or text field.
                    </li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel15"}
            onChange={handleChange("panel15")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                How can I edit an existing task?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">How to Edit Tasks:</p>
                <div className="policyInrCont">
                  <ul>
                    <li>Open the Task Management tab.                    </li>
                    <li>Tap on All Tasks from the top menu.                    </li>
                    <li>Swipe right on a task card and tap the Edit icon to make changes.</li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel16"}
            onChange={handleChange("panel16")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">How can I delete a task?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">Steps to delete tasks:</p>
                <div className="policyInrCont">
                  <ul>
                    <li>Open the Task Management tab.</li>
                    <li>Click on All tasks from top tabs</li>
                    <li>Swipe left on a task card and tap the delete icon to make changes.</li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel17"}
            onChange={handleChange("panel17")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                How do I add "before and after images required" as an indication
                for a task?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">
                  Adding before/after images requirement for task, let employees
                  know that they have to upload before after images for that
                  task while completing.
                </p>
                <p className="moreContTx">Steps:</p>
                <div className="policyInrCont">
                  <ul>
                    <li>Open the Task Management tab.</li>
                    <li>Click on All tasks from top tabs</li>
                    <li>Go to edit task.</li>
                    <li>
                      Click on check box “Does it need Before and After photos?”
                    </li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel18"}
            onChange={handleChange("panel18")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                How can I upload a PDF or image as a task description?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">Steps to upload PDF/Images:</p>
                <div className="policyInrCont">
                  <ul>
                    <li>Open the Task Management tab.</li>
                    <li>Click on All tasks from top tabs.</li>
                    <li>Click on the task you want to edit.</li>
                    <li>In the task details, click on "Upload Photos and Images" to add your files.</li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel19"}
            onChange={handleChange("panel19")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                How do I activate/deactivate a task?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">To active/deactivate a task, follow these steps:</p>
                <div className="policyInrCont">
                  <ul>
                    <li>Navigate to the "Task Management" by clicking on the second tab.    </li>
                    <li>Click on All tasks from top tabs</li>
                    <li>Swipe right on the task card you want to deactivate and click the edit icon.                    </li>
                    <li>On the edit page, you'll find a toggle option to activate or deactivate the task. Simply toggle it to deactivate.
                    </li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel20"}
            onChange={handleChange("panel20")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                What are employee shifts?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              Employee shifts refer to the designated working hours assigned to specific task groups. They help streamline scheduling, ensure proper coverage, and provide flexibility for assigning or adjusting shifts as necessary.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel21"}
            onChange={handleChange("panel21")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                How do I add employee shifts?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">
                To add employee shifts, follow these steps:</p>
                <div className="policyInrCont">
                  <ul>
                    <li>Navigate to the third tab, "Shift Management"                    </li>
                    <li>Select "Employee Shifts" from the top menu.                    </li>
                    <li>Use the audio feature or input field to add the employee shifts as needed.
                    </li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel22"}
            onChange={handleChange("panel22")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                How do I edit an employee shift?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">
                To edit employee shifts, follow these steps:
                </p>
                <div className="policyInrCont">
                  <ul>
                    <li>Go to the third tab, "Shift Management"</li>
                    <li>Click on "Employee Shifts" from the top menu.                    </li>
                    <li>Swipe right on the employee shift card and click the edit icon.</li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel23"}
            onChange={handleChange("panel23")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                How do I activa/deactivate an employee shift?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">
                To active/deactivate employee shifts, follow these steps:</p>
                <div className="policyInrCont">
                  <ul>
                    <li>Go to the third tab, "Shift Management"                    </li>
                    <li>Click on "Employee Shifts" from the top menu.                    </li>
                    <li>Use the toggle on the shift card to activate or deactivate the shift.
                    </li>
                  </ul>
                  <p className="moreContTx">Alternatively</p>
                  <ul>
                    <li>
                    Swipe right on the employee shift card and click the edit icon.
                    </li>
                    <li>On the edit page, use the toggle to activate or deactivate the shift.
                    </li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel24"}
            onChange={handleChange("panel24")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                What is the "Schedule" tab?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                A schedule tab is used to create pre-defined plan/template that
                helps you organize your tasks and shifts for employees across
                specific days and locations. It simplifies recurring scheduling
                by letting you create, edit, and reuse templates for efficient
                task management.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel25"}
            onChange={handleChange("panel25")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                How do I create a template and how to activate/deactivate it?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">
                  Scheduling template helps you to predefine template for whole
                  week.
                </p>
                <p className="moreContTx">Steps to create new template:</p>
                <div className="policyInrCont">
                  <ul>
                    <li>Go to the 3rd tab in the app. (Shift Template)</li>
                    <li>Click on create template</li>
                    <li>
                      Add template details <br />
                      -Enter the Template Name.
                      <br />
                      -Add a New Location (if no location exists), or select
                      from the already added locations.
                    </li>
                    <li>
                      Configure Shift <br /> Add shift details, including:
                      <ul className="faqInnerUl">
                        <li>Shift Timing</li>
                        <li>Required Employee Count</li>
                        <li>Shift Label</li>
                      </ul>
                    </li>
                    <p className="moreContTx">
                      You can add multiple shifts for the same timing.
                    </p>
                    <li>
                      Edit Shifts
                      <ul className="faqInnerUl">
                        <li>You can edit shifts at any time.</li>
                        <li>
                          However, changes will only reflect in the upcoming
                          week's schedule and not in the current scheduled week.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Activate Template
                      <ul className="faqInnerUl">
                        <li>
                          Use toggle switch to activate and deactivate template
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel26"}
            onChange={handleChange("panel26")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">How do I add a location?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">
                Locations are added to streamline your workflow, allowing you to assign employees based on their specific work areas.
                </p>
                <p className="moreContTx">To add a location, follow these steps:</p>
                <div className="policyInrCont">
                  <ul>
                    <li>Navigate to the second tab, "Task Management"</li>
                    <li>Click on the "Location" tab from the top menu.</li>
                    <li>Use the audio feature or input field to add the location.
                    </li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel27"}
            onChange={handleChange("panel27")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                How do I edit a location?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">
                  Locations are added to simplify your work, you can assign
                  location to employees as per their work area(location)
                </p>
                <p className="moreContTx">To edit a location, follow these steps:</p>
                <div className="policyInrCont">
                  <ul>
                    <li>Navigate to the second tab, "Task Management"                    </li>
                    <li>Click on location tab from top tab</li>
                    <li>Swipe right on the location card and click the edit icon.
                    </li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel28"}
            onChange={handleChange("panel28")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                How can I view the list of added locations?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">To view list of locations</p>
                <div className="policyInrCont">
                  <ul>
                    <li>Go to 2nd tab i.e Task Management</li>
                    <li>Click on location tab from top tab to see list.</li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel29"}
            onChange={handleChange("panel29")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                How do I delete a location?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">To delete locations</p>
                <div className="policyInrCont">
                  <ul>
                    <li>Go to 2nd tab i.e Task Manager</li>
                    <li>Click on location tab from top tab</li>
                    <li>Swipe left on the location card and click the edit icon.</li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          {/* <Accordion
            expanded={expanded === "panel30"}
            onChange={handleChange("panel30")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">How do I schedule tasks?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">
                  Scheduling your tasks allows you to assign task for whole week
                  as per task category. You need to add task under task group.
                </p>
                <p className="moreContTx">Steps to schedule task:</p>
                <div className="policyInrCont">
                  <ul>
                    <li>Go to 2nd tab i.e Task Management</li>
                    <li>Click on “Schedule Your Task”</li>
                    <li>
                      You can see task group as a tabs here, simply click on tab
                      in which you want to add task
                    </li>
                  </ul>
                </div>
                <p className="moreContTx">thinking...............</p>
              </Typography>
            </AccordionDetails>
          </Accordion> */}

          <Accordion
            expanded={expanded === "panel31"}
            onChange={handleChange("panel31")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                How can I assign a employee as an admin?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">
                  Adding employee as manager, you are giving all admin access to
                  employee.
                </p>
                <p className="moreContTx">Steps to assign as admin</p>
                <div className="policyInrCont">
                  <ul>
                    <li>Login as admin</li>
                    <li>Go to employee list</li>
                    <li>Go to edit employee</li>
                    <li>
                      On edit page you will have option to set employee as admin{" "}
                    </li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel32"}
            onChange={handleChange("panel32")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                How do I view a consolidated preview of tasks?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">
                  Consolidated preview is preview where you are able to see all
                  view on same page
                </p>
                <p className="moreContTx">Steps:</p>
                <div className="policyInrCont">
                  <ul>
                    <li>Go to 3rd tab i.e Shift Management tab.</li>
                    <li>Click on Shift Scheduler from top tabs. </li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel33"}
            onChange={handleChange("panel33")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                How can I assign/change employees to shifts?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">
                To assign shifts to employees, follow these steps:
                </p>
                <div className="policyInrCont">
                  <ul>
                    <li>Go to the third tab, "Shift Management"                    </li>
                    <li>Click on the "Shift Scheduler" tab from the top menu.
                    </li>
                    <li>You can see consolidate preview, click on employee row which you want to assign shift.
                    </li>
                    <li>A pop-up will appear, allowing you to change or assign the shift with the desired timings.
                    </li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel34"}
            onChange={handleChange("panel34")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                How can I view a consolidated preview without employees standby
                shifts?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">Steps:</p>
                <div className="policyInrCont">
                  <ul>
                    <li>Go to 3rd tab i.e Shift Scheduler tab.</li>
                    <li>Click on Shift Scheduler from top tabs</li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel35"}
            onChange={handleChange("panel35")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                How can I hide or unhide employees from the consolidated
                preview?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">
                  This features is used if you want to hide emp from current
                  preview
                </p>
                <p className="moreContTx">Steps:</p>
                <div className="policyInrCont">
                  <ul>
                    <li>Go to consolidated preview</li>
                    <li>Before employee name click on minus icon</li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel36"}
            onChange={handleChange("panel36")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                Where can I see swap and offer requests?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Swap and offer requests are requests send by employees to swap
                and offer shifts. On consolidated preview on top you will get
                requests.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel37"}
            onChange={handleChange("panel37")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                Is my data secure on ToDoBoom?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, your data is highly secure on ToDoBoom. We use advanced
                encryption protocols to protect your information and ensure it
                is accessible only to authorized users. Additionally, regular
                security audits, secure servers, and data privacy practices are
                in place to safeguard your data at all times. Your trust and
                privacy are our top priorities.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel38"}
            onChange={handleChange("panel38")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                Does ToDoBoom share my information with third parties?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                No, ToDoBoom does not share your information with third parties
                without your explicit consent. Your data is handled in strict
                compliance with our privacy policy, ensuring it is used only to
                improve your experience within the app. We are committed to
                maintaining your privacy and security.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel39"}
            onChange={handleChange("panel39")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="span">
                I’m unable to log in. What should I do?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="moreContTx">
                  If you're unable to log in, try the following steps:
                </p>
                <div className="policyInrCont">
                  <ul>
                    <li>
                      Check Your Credentials: Ensure that you are entering the
                      correct PIN.
                    </li>
                    <li>
                      Reset PIN: If you've forgotten your PIN, contact your
                      manager to set it.
                    </li>
                    <li>
                      Stable Internet: Ensure your device is connected to a
                      stable internet connection.
                    </li>
                    <li>
                      Update the App: Make sure you are using the latest version
                      of the app.
                    </li>
                    <li>
                      Contact Support: If the issue persists, reach out to our
                      support team for assistance.
                    </li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </>
  );
}

export default FAQsAdmin;
