import React from "react";
import noPic from "../../../assets/images/no-data.png";
import UserBottom from "../UserBottom/UserBottom";
import TopBar from "../../admin/TopBar";
import { useNavigate } from "react-router-dom";

function MoreOptionsEmployee() {
  const navigate = useNavigate();
  return (
    <>
      <TopBar />
      <div className="wrapper">
        <div className="taskListAdmMain forUpperBottomBt">
          {/* TopBar */}
          <div
            className="innerPageTopSecBackPatch"
            style={{ height: 105 }}
          ></div>
          <div className="innerPageTopSec">
            <h1>More Options</h1>
          </div>
          {/* TopBarEnd */}

          <div className="moreContMain">
            {/* <div className="moreContInr">
              <h1 className="moreHeadi">Task Management</h1>
            </div> */}

            {/* <div className="moreContInr">
              <h2 className="moreHeadiMain">Profile & Account Settings</h2>
              <p className="moreContTx">Account Settings</p>
              <p className="moreContTx">App Settings</p>
            </div> */}

            <div className="moreContInr">
              <h2 className="moreHeadiMain">Help & Support</h2>
              {/* <p className="moreContTx">
                <a
                  href="mailto:support@todoboom.com?subject=Support%20Request&body=Hi%20Support%20Team,%20I%20need%20assistance%20with..."
                  style={{ color: "#868686" }}
                >
                  Contact Support
                </a>
              </p> */}
              <p className="moreContTx" onClick={() => navigate("/FAQs")}>
                FAQs
              </p>
              <p className="moreContTx" onClick={() => navigate("/UserGuide")}>
                User Guide
              </p>
            </div>

            <div className="moreContInr">
              <h2 className="moreHeadiMain">Legal & Miscellaneous</h2>
              <p
                className="moreContTx"
                onClick={() => navigate("/PrivacyPolicy")}
              >
                Privacy Policy
              </p>
              {/* <p className="moreContTx">Terms of Service</p> */}
              <p className="moreContTx" onClick={() => navigate("/AboutUs")}>
                About Us
              </p>
            </div>

            {/* <div className="moreContInr">
              <h2 className="moreHeadiMain">Subscription & Billing</h2>
            </div> */}
          </div>

          {/* <div class="dataNoF">
            {" "}
            <img src={noPic} />
            <p>Data Not Found</p>
          </div> */}
          <UserBottom />
        </div>
      </div>
    </>
  );
}

export default MoreOptionsEmployee;
