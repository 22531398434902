import React from "react";
import { useNavigate } from "react-router-dom";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

function AboutUsAdmin() {
  const navigate = useNavigate();
  return (
    <>
      <div className="wrapper innerPagesMain">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <NavigateBeforeIcon onClick={() => navigate(-1)} />
          </div>
          <h1>About Us</h1>
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}

        <div className="policyContentMain">
          <div className="policyInrCont">
            <p className="moreContTx">
              Welcome to <b style={{ color: "#333" }}>ToDoBoom</b>, your
              ultimate task management assistant designed to simplify and
              streamline your daily operations!
            </p>
            <p className="moreContTx">
              At ToDoBoom, we believe that staying organized should be
              effortless. Whether you're managing personal tasks, team
              schedules, or complex workflows, our app is here to help you stay
              on top.
            </p>
          </div>

          <div className="policyInrCont">
            <h2 className="moreHeadiMain">What We Do</h2>
            <p className="moreContTx">
              ToDoBoom is a feature-rich mobile application tailored to enhance
              productivity for individuals and businesses alike. With intuitive
              task scheduling, seamless team collaboration, and swap/offer, we
              empower you to focus on what truly matters while leaving the
              hassle of task management to us.
            </p>
          </div>

          <div className="policyInrCont">
            <h2 className="moreHeadiMain">Our Vision</h2>
            <p className="moreContTx">
              We aim to redefine task management by providing a solution that's
              not just powerful but also easy to use. Our goal is to help users
              achieve more with less effort, whether at work, home, or anywhere
              in between.
            </p>
            <p className="moreContTx">Why Choose ToDoBoom?</p>
            <ul>
              <li>
                User-Friendly Interface: Designed for simplicity and efficiency.
              </li>
              <li>
                Task Automation: Minimize manual effort with smart scheduling
                tools.
              </li>
              <li>
                Secure and Reliable: Your data is encrypted and safe with us.
              </li>
              <li>Personalized Support: We're always here to assist you.</li>
            </ul>
          </div>

          <div className="policyInrCont">
            <h2 className="moreHeadiMain">Who We Serve</h2>
            <p className="moreContTx">
              From individuals to small businesses and large teams, ToDoBoom is
              the go-to app for anyone looking to achieve more, reduce stress,
              and maintain balance.
            </p>
            <p className="moreContTx">
              Thank you for choosing ToDoBoom. Together, let’s make every task
              manageable and every day productive!
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUsAdmin;
