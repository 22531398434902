import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import profilePic from "../../../assets/images/avtarPic.png";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import SortIcon from "@mui/icons-material/Sort";
import FilterIcon from "@mui/icons-material/Filter";
import { Button, Grid, Box, colors, Alert } from "@mui/material";
import * as Yup from "yup";
import moment from "moment/moment";
import noPic from "../../../assets/images/no-data.png";
import CheckIcon from "@mui/icons-material/Check";
import { sortBy } from "lodash";
// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// End

import afterBef1 from "../../../assets/images/afterBef1.png";
import afterBef2 from "../../../assets/images/afterBef2.png";
import CloseIcon from "@mui/icons-material/Close";
import calendarIm from "../../../assets/images/calendar.png";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import swal from "sweetalert";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";
import ToggleButton from "@mui/material/ToggleButton";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import VerifiedIcon from "@mui/icons-material/Verified";
import AppDrawer from "../../AppDrawer";

import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import searchEmployee from "../../../assets/images/searchEmployee.svg";
import profileIm from "../../../assets/images/Profile-Picture.jpg";

// Accorddian
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// End

import SouthIcon from "@mui/icons-material/South";
import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";
import DragNDrop from "../../DragNDrop";
import TopBar from "../TopBar";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

/* const useStyles = makeStyles((theme)=>({
  formWrapper:{
    marginTop:theme.spacing(5),
    marginBottom:theme.spacing(8),
  },
})); */

function WeeklyTasksNew() {
  //For Scroll Add Class
  const [scrolling, setScrolling] = useState(false);
  const [customSort, setcustomSort] = React.useState(0);
  //   for Accordian
  const [expanded, setExpanded] = React.useState(false);
  const [frequencyData, setFrequencyData] = useState({});

  const handleChange2 = (panel) => (event, isExpanded) => {
    setSelectedMenuOfMonday({
      schedule_date: "",
      employeeid: "",
      weekday: "",
      status: "",
      shifttime: "",
      locationid: "",
    });

    setSelectedMenuOfSunday({
      schedule_date: "",
      employeeid: "",
      weekday: "",
      status: "",
      shifttime: "",
      locationid: "",
    });

    setSelectedScheduleOfTuesday({
      schedule_date: "",
      employeeid: "",
      weekday: "",
      status: "",
      shifttime: "",
      locationid: "",
    });

    setSelectedMenuOfWednesday({
      schedule_date: "",
      employeeid: "",
      weekday: "",
      status: "",
      shifttime: "",
      locationid: "",
    });

    setSelectedMenuOfThursday({
      schedule_date: "",
      employeeid: "",
      weekday: "",
      status: "",
      shifttime: "",
      locationid: "",
    });

    setSelectedMenuOfFriday({
      schedule_date: "",
      employeeid: "",
      weekday: "",
      status: "",
      shifttime: "",
      locationid: "",
    });

    setSelectedMenuOfSaturday({
      schedule_date: "",
      employeeid: "",
      weekday: "",
      status: "",
      shifttime: "",
      locationid: "",
    });
    setExpanded(isExpanded ? panel : false);
  };
  // End

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        // Adjust this value based on your needs
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // End

  const navigate = useNavigate();
  const [todaysDate, setTodaysDate] = useState(Date());
  const [currentIndex, setCurrentIndex] = useState(0);
  // For Modal
  const [open, setOpen] = React.useState(false);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [checkedRows, setCheckedRows] = useState({});
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // End

  const [currentDay, setCurrentDay] = React.useState(
    moment(todaysDate.time).format("dddd")
  );

  const [currentTime, setCurrentTime] = React.useState(
    moment(todaysDate.time).format("HH:mm")
  );

  const [loginstartDate, setLoginStartDate] = React.useState(
    moment(todaysDate.time).format("YYYY-MM-DD")
  );

  const getEmployeeShiftDetails = (
    empid,
    currentTime,
    currentDate,
    weekday,
    userType
  ) => {
    makeGetRequest(
      "employeeschedular/loginEmployeeShiftDetails/" +
        empid +
        "/'" +
        currentTime +
        "'/'" +
        currentDate +
        "'/'" +
        weekday +
        "'"
    )
      .then((response) => {
        if (response.data.data.length > 0) {
          localStorage.setItem(
            "loginEmpShiftLocation",
            response.data.data[0].location
          );
          localStorage.setItem(
            "loginEmpShiftTime",
            response.data.data[0].shifttime
          );
          localStorage.setItem(
            "loginEmpShiftFrequancy",
            response.data.data[0].frequency_id
          );
        } else {
          localStorage.setItem("loginEmpShiftLocation", "");
          localStorage.setItem("loginEmpShiftTime", "");
          localStorage.setItem("loginEmpShiftFrequancy", 0);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const getCurrentWeekMonday = () => {
    const today = moment();
    const monday = today.clone().startOf("isoWeek"); // Monday
    return monday;
  };

  const startwDate = getCurrentWeekMonday();
  const endwDate = moment().endOf("year").add(1, "year");
  const datesArrayVal = getMondaysArray(startwDate, endwDate);
  const today = getStartWeek();
  const currentDate = moment().format("YYYY-MM-DD");

  const [direction, setDirection] = useState(null);

  const getCurrentWeekStart = () => {
    const today = moment();
    const isSunday = today.day() === 0; // 0 = Sunday
    const currentWeekStart = isSunday ? today.day(-6) : today.startOf("week");
    return currentWeekStart;
  };

  //Please check this function if today is Monday then it is getting today's Monday but if are is in midweek then it shows previuous monday but next day then sometimes it shows another Monday
  const currentWeekStart = getStartWeek();
  const formattedDate = currentWeekStart;

  const todayIndex = datesArrayVal.findIndex((arraydate) =>
    moment(arraydate.date).isSame(formattedDate, "day")
  );

  const settings = {
    //onReInit: () => setCurrentSlide(slider1?.innerSlider.state.currentSlide),
    initialSlide: todayIndex,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    // arrows: true,
    dots: false,
    speed: 300,
    centerPadding: "20px",
    infinite: true,
    autoplaySpeed: 5000,
    // nextArrow: <NextArrow />,
    afterChange: (current, next) => {
      if (current > currentSlide) {
        let newIndex = 0;
        let additionDate;

        if (currentIndex == 0) {
          const datesArrayVal = getArrowDatesArray(startDate, endDate);
          newIndex = currentIndex + 1;
          setCurrentIndex(newIndex);
          additionDate = datesArrayVal[newIndex];
          const date = moment(additionDate);
          const formattedDate = date.format("MM-DD-YYYY");
          SelectSliders(newIndex, formattedDate);
        } else {
          const datesArrayVal = getArrowDatesArray(startDate, endDate);
          newIndex = currentIndex + 1;
          setCurrentIndex(newIndex);
          additionDate = datesArrayVal[newIndex];
          const date = moment(additionDate);
          const formattedDate = date.format("MM-DD-YYYY");
          SelectSliders(newIndex, formattedDate);
        }

        setDirection("left");
      } else if (current < currentSlide) {
        let newIndex = 0;
        let additionDate;

        if (currentIndex == 0) {
          const datesArrayVal = getArrowDatesArray(startDate, endDate);

          newIndex = currentIndex - 1;
          setCurrentIndex(newIndex);
          additionDate = datesArrayVal[newIndex];

          const date = moment(additionDate);
          const formattedDate = date.format("MM-DD-YYYY");
          SelectSliders(newIndex, formattedDate);
        } else {
          const datesArrayVal = getArrowDatesArray(startDate, endDate);

          newIndex = currentIndex - 1;
          setCurrentIndex(newIndex);
          additionDate = datesArrayVal[newIndex];
          const date = moment(additionDate);
          const formattedDate = date.format("MM-DD-YYYY");
          SelectSliders(newIndex, formattedDate);
        }

        setDirection("right");
      }
      setCurrentSlide(current);
    },
  };

  var settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // State for the table rows
  const [rows, setRows] = useState([
    {
      id: 1,
      selectAll: false,
      checkboxes: [false, false, false, false, false, false, false],
    },
    {
      id: 2,
      selectAll: false,
      checkboxes: [false, false, false, false, false, false, false],
    },
    {
      id: 3,
      selectAll: false,
      checkboxes: [false, false, false, false, false, false, false],
    },
  ]);

  // Toggle the "select all" checkboxes in a row
  // const handleSelectAllChange = (rowId) => {
  //   setRows(
  //     rows.map((row) =>
  //       row.id === rowId
  //         ? {
  //             ...row,
  //             selectAll: !row.selectAll,
  //             checkboxes: row.checkboxes.map(() => !row.selectAll),
  //           }
  //         : row
  //     )
  //   );
  // };

  const handleSelectAllChange = (
    e,
    index,
    employeeid,
    arrDate,
    shifttimes,
    location,
    shifttimesObjindex
  ) => {
    const isChecked = e.target.checked;
    setIsSelectAllChecked(isChecked);
    setCheckedRows((prev) => ({ ...prev, [categoryid]: !prev[categoryid] }));

    const weekDay = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    let tempList = [];
    if (weekDay.includes("Monday")) {
      if (arrDate[0].CurrentWeek >= currentDate) {
        // tempList = [...selectedMenuOfMonday.categoryid];
        //if (tempList.length == 0) {
        selectedMenuOfMonday.schedule_date = arrDate[0].CurrentWeek;
        selectedMenuOfMonday.employeeid = employeeid;
        selectedMenuOfMonday.weekday = weekDay[0];
        selectedMenuOfMonday.status = true;
        //}

        if (isChecked == true) {
          if (!tempList.includes(categoryid)) {
            tempList.push(categoryid);
          }
          document
            .getElementById("mon" + index + "" + employeeid + "" + categoryid)
            .setAttribute("checked", true);
        } else {
          tempList.splice(tempList.indexOf(categoryid), 1);
          document
            .getElementById("mon" + index + "" + employeeid + "" + categoryid)
            .removeAttribute("checked", false);
        }

        selectedMenuOfMonday.categoryid = tempList;
        setSelectedMenuOfMonday(selectedMenuOfMonday);
        setUpload(!upload);
      }
    }

    if (weekDay.includes("Monday")) {
      selectedMenuOfMonday.schedule_date = arrDate[0].CurrentWeek;
      selectedMenuOfMonday.employeeid = employeeid;
      selectedMenuOfMonday.weekday = weekDay[0];
      selectedMenuOfMonday.status = true;
      selectedMenuOfMonday.shifttime = shifttimes;
      selectedMenuOfMonday.locationid = location;

      //}
      if (isChecked == true) {
        // tempList.push(categoryid);
        document
          .getElementById(
            "mon" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .setAttribute("checked", true);
      } else {
        //tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById(
            "mon" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .removeAttribute("checked", false);
      }

      // selectedMenuOfMonday.categoryid = tempList;
      setSelectedMenuOfMonday(selectedMenuOfMonday);
      setUpload(!upload);
    } else if (weekDay.includes("Tuesday")) {
      selectedScheduleOfTuesday.schedule_date = arrDate[1].CurrentWeek;
      selectedScheduleOfTuesday.employeeid = employeeid;
      selectedScheduleOfTuesday.weekday = weekDay[1];
      selectedScheduleOfTuesday.status = true;
      selectedScheduleOfTuesday.shifttime = shifttimes;
      selectedScheduleOfTuesday.locationid = location;
      //}

      if (isChecked == true) {
        // tempList.push(categoryid);
        document
          .getElementById(
            "tues" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .setAttribute("checked", true);
      } else {
        // tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById(
            "tues" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .removeAttribute("checked", false);
      }

      // selectedScheduleOfTuesday.categoryid = tempList;
      setSelectedScheduleOfTuesday(selectedScheduleOfTuesday);
      setUpload(!upload);
    } else if (weekDay.includes("Wednesday")) {
      selectedMenuOfWednesday.schedule_date = arrDate[2].CurrentWeek;
      selectedMenuOfWednesday.employeeid = employeeid;
      selectedMenuOfWednesday.weekday = weekDay[2];
      selectedMenuOfWednesday.status = true;
      selectedMenuOfWednesday.shifttime = shifttimes;
      selectedMenuOfWednesday.locationid = location;
      //}

      if (isChecked == true) {
        // tempList.push(categoryid);
        document
          .getElementById(
            "wed" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .setAttribute("checked", true);
      } else {
        // tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById(
            "wed" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .removeAttribute("checked", false);
      }

      // selectedMenuOfWednesday.categoryid = tempList;
      setSelectedMenuOfWednesday(selectedMenuOfWednesday);
      setUpload(!upload);
    } else if (weekDay.includes("Thursday")) {
      selectedMenuOfThursday.schedule_date = arrDate[3].CurrentWeek;
      selectedMenuOfThursday.employeeid = employeeid;
      selectedMenuOfThursday.weekday = weekDay[3];
      selectedMenuOfThursday.status = true;
      selectedMenuOfThursday.shifttime = shifttimes;
      selectedMenuOfThursday.locationid = location;
      //}

      if (isChecked == true) {
        // tempList.push(categoryid);
        document
          .getElementById(
            "thur" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .setAttribute("checked", true);
      } else {
        // tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById(
            "thur" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .removeAttribute("checked", false);
      }

      // selectedMenuOfThursday.categoryid = tempList;
      setSelectedMenuOfThursday(selectedMenuOfThursday);
      setUpload(!upload);
    } else if (weekDay.includes("Friday")) {
      selectedMenuOfFriday.schedule_date = arrDate[4].CurrentWeek;
      selectedMenuOfFriday.employeeid = employeeid;
      selectedMenuOfFriday.weekday = weekDay[4];
      selectedMenuOfFriday.status = true;
      selectedMenuOfFriday.shifttime = shifttimes;
      selectedMenuOfFriday.locationid = location;
      //}
      if (isChecked == true) {
        // tempList.push(categoryid);
        document
          .getElementById(
            "fri" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .setAttribute("checked", true);
      } else {
        // tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById(
            "fri" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .removeAttribute("checked", false);
      }

      // selectedMenuOfFriday.categoryid = tempList;
      setSelectedMenuOfFriday(selectedMenuOfFriday);
      setUpload(!upload);
    } else if (weekDay.includes("Saturday")) {
      selectedMenuOfSaturday.schedule_date = arrDate[5].CurrentWeek;
      selectedMenuOfSaturday.employeeid = employeeid;
      selectedMenuOfSaturday.weekday = weekDay[5];
      selectedMenuOfSaturday.status = true;
      selectedMenuOfSaturday.shifttime = shifttimes;
      selectedMenuOfSaturday.locationid = location;
      //}
      if (isChecked == true) {
        // tempList.push(categoryid);
        document
          .getElementById(
            "sat" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .setAttribute("checked", true);
      } else {
        // tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById(
            "sat" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .removeAttribute("checked", false);
      }

      // selectedMenuOfSaturday.categoryid = tempList;
      setSelectedMenuOfSaturday(selectedMenuOfSaturday);
      setUpload(!upload);
    } else if (weekDay.includes("Sunday")) {
      selectedMenuOfSunday.schedule_date = arrDate[6].CurrentWeek;
      selectedMenuOfSunday.employeeid = employeeid;
      selectedMenuOfSunday.weekday = weekDay[6];
      selectedMenuOfSunday.status = true;
      selectedMenuOfSunday.shifttime = shifttimes;
      selectedMenuOfSunday.locationid = location;
      //}
      if (isChecked == true) {
        // tempList.push(categoryid);
        document
          .getElementById(
            "sunday" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .setAttribute("checked", true);
      } else {
        // tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById(
            "sunday" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .removeAttribute("checked".false);
      }

      // selectedMenuOfSunday.categoryid = tempList;
      setSelectedMenuOfSunday(selectedMenuOfSunday);
      setUpload(!upload);
    }
  };

  function getMonday(d) {
    var date = new Date(d);
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(d.setDate(diff));
  }

  function getEntireWeek() {
    const today = moment();
    const startOfWeek = today.clone().startOf("isoWeek"); // Monday
    const endOfWeek = today.clone().endOf("isoWeek"); // Sunday
    const weekDays = [];

    while (startOfWeek.isSameOrBefore(endOfWeek)) {
      weekDays.push({ CurrentWeek: startOfWeek.format("YYYY-MM-DD") });
      startOfWeek.add(1, "day");
    }

    return weekDays;
  }

  function getStartWeek() {
    let week = [];
    var d = new Date();
    d.setDate(1);
    var curr = getMonday(new Date());
    var xday = curr.getDate();
    d.setDate(xday);
    let first = curr.getDate() - curr.getDay() + 1;
    let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
    return day;
  }

  function getArrowDatesArray() {
    const dates = [];
    const startcDate = getCurrentWeekMonday();
    const endcDate = moment().endOf("year").add(1, "year");

    for (
      let date = moment(startcDate);
      date.isSameOrBefore(endcDate);
      date.add(1, "day")
    ) {
      if (date.day() === 1) {
        // 1 represents Monday
        const d = date.toDate();
        var newDates = d.getDate();
        var newmonths = d.getMonth() + 1;
        var years = d.getFullYear();

        if (newDates < 10 && newDates > 0) newDates = "0" + newDates;

        if (newmonths < 10 && newmonths > 0) newmonths = "0" + newmonths;

        dates.push(years + "-" + newmonths + "-" + newDates);
      }
    }

    return dates;
  }

  function getEndWeek() {
    let week = [];
    var d = new Date();
    d.setDate(1);
    var curr = getMonday(new Date());
    var xday = curr.getDate();
    d.setDate(xday);
    let first = curr.getDate() - curr.getDay() + 7;
    let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
    return day;
  }

  function getAllMondays(startDate, endDate) {
    const mondays = [];
    for (
      let date = moment(startDate);
      date.isSameOrBefore(endDate);
      date.add(1, "day")
    ) {
      if (date.day() === 1) {
        // 1 represents Monday
        const d = date.toDate();
        var newDates = d.getDate();
        var newmonths = d.getMonth() + 1;
        var years = d.getFullYear();

        if (newDates < 10 && newDates > 0) newDates = "0" + newDates;

        if (newmonths < 10 && newmonths > 0) newmonths = "0" + newmonths;

        mondays.push({ Mondaydate: newmonths + "-" + newDates + "-" + years });
        //console.log("SSSSS"+JSON.stringify(mondays))
      }
    }
    //console.log("My Mondays===>"+JSON.stringify(mondays))
    return mondays;
  }

  function getMondaysArray(startDate, endDate) {
    const mondays = [];
    for (
      let date = moment(startDate);
      date.isSameOrBefore(endDate);
      date.add(1, "day")
    ) {
      if (date.day() === 1) {
        // 1 represents Monday
        const d = date.toDate();
        var newDates = d.getDate();
        var newmonths = d.getMonth() + 1;
        var years = d.getFullYear();

        if (newDates < 10 && newDates > 0) newDates = "0" + newDates;

        if (newmonths < 10 && newmonths > 0) newmonths = "0" + newmonths;

        mondays.push({ date: years + "-" + newmonths + "-" + newDates });
      }
    }

    return mondays;
  }

  // Example usage:

  // Get all Mondays in year from provided date
  // Default today's date
  function getMondays(d) {
    d = d ? new Date(+d) : new Date();
    d.setDate(1);

    var endYear = 2024 + 10;
    var endMonth = d.getMonth();

    // Set to first Monday

    var day = getMonday(new Date());
    var xday = day.getDate();

    d.setDate(xday);
    var mondays = [];

    // Create Dates for all Mondays up to end year and month
    while (d.getFullYear() < endYear || d.getMonth() != endMonth) {
      var pushDate = new Date(d.getTime());
      var newDates = pushDate.getDate();

      if (newDates < 10 && newDates > 0) newDates = "0" + newDates;
      var newMonth = pushDate.getMonth() + 1;
      if (newMonth < 10 && newMonth > 0) newMonth = "0" + newMonth;
      mondays.push({
        Mondaydate: newMonth + "-" + newDates + "-" + pushDate.getFullYear(),
      });
      d.setDate(d.getDate() + 7);
    }

    return mondays;
  }

  // Get all Mondays and display result

  function manageDate(dates) {
    var arr1 = dates.split("-");
    var newDates = arr1[2];
    if (parseInt(newDates) < 10 && parseInt(newDates) > 0)
      newDates = "0" + parseInt(newDates);
    return newDates;
  }

  function add(arr, name) {
    if (arr != undefined) {
      const { length } = arr;
      const id = length + 1;
      const found = arr.some((el) => el.employeeid === name);
      let res = false;
      if (!found) {
        res = false;
      } else {
        res = true;
      }
      return res;
    }
  }

  // Toggle individual checkbox
  const handleCheckboxChange = (rowId, index) => {
    setRows(
      rows.map((row) =>
        row.id === rowId
          ? {
              ...row,
              checkboxes: row.checkboxes.map((checked, i) =>
                i === index ? !checked : checked
              ),
              selectAll: row.checkboxes.every((checked, i) =>
                i === index ? !checked : checked
              ),
            }
          : row
      )
    );
  };

  const handleChangeWeekDayCheckBox = (
    isChecked,
    index,
    employeeid,
    weekDay,
    arrDate,
    shifttimes,
    location,
    shifttimesObjindex
  ) => {
    console.log("MY TESTING result===>" + JSON.stringify(employeeavailable));

    const targetEmployee = employeeavailable.find(
      (employee) => employee.employee_id === employeeid
    );

    if (targetEmployee) {
      targetEmployee.locations.forEach((location) => {
        location.shifttimes.forEach((shifttime) => {
          Object.keys(shifttime.availability).map((day, dayindex) => {
            if (day === weekDay && shifttime.shifttime === shifttimes) {
              shifttime.availability[day].is_assigned = isChecked;
            } else {
            }
          });
        });
      });
      setEmployeeAvailable([...employeeavailable]);
    }

    let tempList = [];
    if (weekDay == "Monday") {
      selectedMenuOfMonday.schedule_date = arrDate[0].CurrentWeek;
      selectedMenuOfMonday.employeeid = employeeid;
      selectedMenuOfMonday.weekday = weekDay;
      selectedMenuOfMonday.status = isChecked;
      selectedMenuOfMonday.shifttime = shifttimes;
      selectedMenuOfMonday.locationid = location;

      //}
      if (isChecked == true) {
        // tempList.push(categoryid);
        document
          .getElementById(
            "mon" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .setAttribute("checked", true);
      } else {
        //tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById(
            "mon" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .removeAttribute("checked", false);
      }

      // selectedMenuOfMonday.categoryid = tempList;
      setSelectedMenuOfMonday(selectedMenuOfMonday);
      setUpload(!upload);
    } else if (weekDay == "Tuesday") {
      selectedScheduleOfTuesday.schedule_date = arrDate[1].CurrentWeek;
      selectedScheduleOfTuesday.employeeid = employeeid;
      selectedScheduleOfTuesday.weekday = weekDay;
      selectedScheduleOfTuesday.status = isChecked;
      selectedScheduleOfTuesday.shifttime = shifttimes;
      selectedScheduleOfTuesday.locationid = location;
      //}

      if (isChecked == true) {
        // tempList.push(categoryid);
        document
          .getElementById(
            "tues" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .setAttribute("checked", true);
      } else {
        // tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById(
            "tues" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .removeAttribute("checked", false);
      }

      // selectedScheduleOfTuesday.categoryid = tempList;
      setSelectedScheduleOfTuesday(selectedScheduleOfTuesday);
      setUpload(!upload);
    } else if (weekDay == "Wednesday") {
      selectedMenuOfWednesday.schedule_date = arrDate[2].CurrentWeek;
      selectedMenuOfWednesday.employeeid = employeeid;
      selectedMenuOfWednesday.weekday = weekDay;
      selectedMenuOfWednesday.status = isChecked;
      selectedMenuOfWednesday.shifttime = shifttimes;
      selectedMenuOfWednesday.locationid = location;
      //}

      if (isChecked == true) {
        // tempList.push(categoryid);
        document
          .getElementById(
            "wed" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .setAttribute("checked", true);
      } else {
        // tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById(
            "wed" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .removeAttribute("checked", false);
      }

      // selectedMenuOfWednesday.categoryid = tempList;
      setSelectedMenuOfWednesday(selectedMenuOfWednesday);
      setUpload(!upload);
    } else if (weekDay == "Thursday") {
      selectedMenuOfThursday.schedule_date = arrDate[3].CurrentWeek;
      selectedMenuOfThursday.employeeid = employeeid;
      selectedMenuOfThursday.weekday = weekDay;
      selectedMenuOfThursday.status = isChecked;
      selectedMenuOfThursday.shifttime = shifttimes;
      selectedMenuOfThursday.locationid = location;
      //}

      if (isChecked == true) {
        // tempList.push(categoryid);
        document
          .getElementById(
            "thur" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .setAttribute("checked", true);
      } else {
        // tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById(
            "thur" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .removeAttribute("checked", false);
      }

      // selectedMenuOfThursday.categoryid = tempList;
      setSelectedMenuOfThursday(selectedMenuOfThursday);
      setUpload(!upload);
    } else if (weekDay == "Friday") {
      selectedMenuOfFriday.schedule_date = arrDate[4].CurrentWeek;
      selectedMenuOfFriday.employeeid = employeeid;
      selectedMenuOfFriday.weekday = weekDay;
      selectedMenuOfFriday.status = isChecked;
      selectedMenuOfFriday.shifttime = shifttimes;
      selectedMenuOfFriday.locationid = location;
      //}
      if (isChecked == true) {
        // tempList.push(categoryid);
        document
          .getElementById(
            "fri" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .setAttribute("checked", true);
      } else {
        // tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById(
            "fri" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .removeAttribute("checked", false);
      }

      // selectedMenuOfFriday.categoryid = tempList;
      setSelectedMenuOfFriday(selectedMenuOfFriday);
      setUpload(!upload);
    } else if (weekDay == "Saturday") {
      selectedMenuOfSaturday.schedule_date = arrDate[5].CurrentWeek;
      selectedMenuOfSaturday.employeeid = employeeid;
      selectedMenuOfSaturday.weekday = weekDay;
      selectedMenuOfSaturday.status = isChecked;
      selectedMenuOfSaturday.shifttime = shifttimes;
      selectedMenuOfSaturday.locationid = location;
      //}
      if (isChecked == true) {
        // tempList.push(categoryid);
        document
          .getElementById(
            "sat" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .setAttribute("checked", true);
      } else {
        // tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById(
            "sat" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .removeAttribute("checked", false);
      }

      // selectedMenuOfSaturday.categoryid = tempList;
      setSelectedMenuOfSaturday(selectedMenuOfSaturday);
      setUpload(!upload);
    } else if (weekDay == "Sunday") {
      selectedMenuOfSunday.schedule_date = arrDate[6].CurrentWeek;
      selectedMenuOfSunday.employeeid = employeeid;
      selectedMenuOfSunday.weekday = weekDay;
      selectedMenuOfSunday.status = isChecked;
      selectedMenuOfSunday.shifttime = shifttimes;
      selectedMenuOfSunday.locationid = location;
      //}
      if (isChecked == true) {
        // tempList.push(categoryid);
        document
          .getElementById(
            "sunday" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .setAttribute("checked", true);
      } else {
        // tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById(
            "sunday" + index + "" + employeeid + "" + shifttimesObjindex
          )
          .removeAttribute("checked".false);
      }

      // selectedMenuOfSunday.categoryid = tempList;
      setSelectedMenuOfSunday(selectedMenuOfSunday);
      setUpload(!upload);
    }
  };

  const handleCloseShipTiming = () => {
    setOpenShipTiming(false);
  };
  // End

  const handleClick = async (id, frequancyid) => {
    setClickedId(id);
    handleCloseShipTiming();
    setShowLoader(true);
    setLoginErrMsg("");

    let freid = [];
    freid.push(frequancyid);

    const initialValues = {
      taskid: taskid,
      categoryid: categoryid,
      frequancyid: freid,
      status: true,
      is_deleted: false,
    };

    await makePostRequest("schedular/insertschedular", initialValues)
      .then((response) => {
        if (response.data) {
          setLoginErrMsg("");
          setShowLoader(false);
        }
      })
      .catch((err) => {
        let errMsg = err.message;
        setShowLoader(false);
        setLoginErrMsg(errMsg);
      });
  };

  // For Modal
  const [ShipTiming, setOpenShipTiming] = React.useState(false);

  const handleClickOpenShipTiming = () => {
    setOpenShipTiming(true);
  };

  const autoRunScript = () => {
    makeGetRequest("schedular/autoAssignShifts")
      .then((response) => {
        if (response.data.data) {
          if (response.data.data.length > 0) {
            console.log(
              "my auto script===>" + JSON.stringify(response.data.data)
            );
          } else {
          }
        } else setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const getSelectCount = () => {
    setShowLoader(true);
    makeGetRequest(
      "schedular/getempschedularlist/" + startDate + "/" + endDate + ""
    )
      .then((response) => {
        if (response.data.data) {
          //alert(response.data.data.length)
          if (response.data.data.length > 0) {
            console.log("Use Effect " + JSON.stringify(response.data.data));

            setEmployeeSortAvailable(response.data.data);
            let empArray = response.data.data.sort((a, b) =>
              a.full_name.localeCompare(b.full_name)
            );

            console.log("Use After Effect " + JSON.stringify(empArray));

            setEmployeeAvailable(empArray);

            setShowLoader(false);
          } else {
            setEmployeeAvailable([]);
            setEmployeeSortAvailable([]);
            setShowLoader(false);
          }
          setUpload(!upload);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const getSelectSliderCount = (startDate, endDate) => {
    setShowLoader(true);

    makeGetRequest(
      "schedular/getempschedularlist/" + startDate + "/" + endDate + ""
    )
      .then((response) => {
        if (response.data.data) {
          if (response.data.data.length > 0) {
            let empArray = response.data.data.sort((a, b) =>
              a.full_name.localeCompare(b.full_name)
            );
            setEmployeeAvailable(empArray);
            setShowLoader(false);
          } else {
            setEmployeeAvailable([]);
            setShowLoader(false);
          }
          setUpload(!upload);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const [value, setValue] = React.useState(0);
  const [incvalue, setIncValue] = React.useState(0);
  const [taskList, setTaskList] = React.useState([]);
  const [upload, setUpload] = React.useState(false);
  const [sortBy, setSortBy] = React.useState("asc");
  const [tempSlide, setTempSlide] = React.useState("");
  const [sortColumnName, setSortColumnName] = React.useState("full_name");
  const [selected, setSelected] = React.useState(false);
  const [freequancyList, setFreequancyList] = React.useState([]);
  const [categoryList, setCategoryList] = React.useState([]);
  const [categoryid, setCategoryID] = React.useState([]);
  const [taskid, setTaskID] = React.useState(0);
  const [mondaysCal, setMondaysCal] = React.useState([]);
  const [currentweekCal, setCurrentWeekCal] = React.useState([]);
  const [startDate, setStartDate] = React.useState(getStartWeek());
  const [endDate, setEndDate] = React.useState(getEndWeek());
  const [clickedId, setClickedId] = useState(null);
  const labelCheck = { inputProps: { "aria-label": "Checkbox demo" } };
  const [toggleId, setToggleId] = useState(null);
  const [selectedMenuItemList, setSelectedMenuItemList] = React.useState([]);
  const [selectedTaskList, setSelectedTaskList] = React.useState();
  const [employeeavailable, setEmployeeAvailable] = React.useState([]);
  const [employeesortavailable, setEmployeeSortAvailable] = React.useState([]);
  const [nav1, setNav1] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slider1, setSlider1] = useState(null);

  const [tempMonday, setTempMonday] = React.useState({
    schedule_date: "",
    employeeid: "",
    weekday: "",
    status: "",
    shifttime: "",
    locationid: "",
    frequency_id: "",
  });

  const [selectedMenuOfSunday, setSelectedMenuOfSunday] = React.useState({
    schedule_date: "",
    employeeid: "",
    weekday: "",
    status: "",
    shifttime: "",
    locationid: "",
  });

  const [selectedMenuOfMonday, setSelectedMenuOfMonday] = React.useState({
    schedule_date: "",
    employeeid: "",
    weekday: "",
    status: "",
    shifttime: "",
    locationid: "",
  });

  const [selectedScheduleOfTuesday, setSelectedScheduleOfTuesday] =
    React.useState({
      schedule_date: "",
      employeeid: "",
      weekday: "",
      status: "",
      shifttime: "",
      locationid: "",
    });

  const [selectedMenuOfWednesday, setSelectedMenuOfWednesday] = React.useState({
    schedule_date: "",
    employeeid: "",
    weekday: "",
    status: "",
    shifttime: "",
    locationid: "",
  });
  const [selectedMenuOfThursday, setSelectedMenuOfThursday] = React.useState({
    schedule_date: "",
    employeeid: "",
    weekday: "",
    status: "",
    shifttime: "",
    locationid: "",
  });
  const [selectedMenuOfFriday, setSelectedMenuOfFriday] = React.useState({
    schedule_date: "",
    employeeid: "",
    weekday: "",
    status: "",
    shifttime: "",
    locationid: "",
  });
  const [selectedMenuOfSaturday, setSelectedMenuOfSaturday] = React.useState({
    schedule_date: "",
    employeeid: "",
    weekday: "",
    status: "",
    shifttime: "",
    locationid: "",
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setTempSlide("");
  };

  function endOfWeek(date) {
    var lastday = date.getDate() - (date.getDay() - 1) + 7;
    return new Date(date.setDate(lastday)).toISOString().slice(0, 10);
  }

  const getCurrentWeek = (date) => {
    const startOfWeek = moment(date).isoWeekday(1); // Set to Monday (1)
    const endOfWeek = moment(date).isoWeekday(7); // Set to Sunday (7)
    const weekDays = [];

    while (startOfWeek.isSameOrBefore(endOfWeek)) {
      weekDays.push({ CurrentWeek: startOfWeek.format("YYYY-MM-DD") });
      startOfWeek.add(1, "day");
    }

    return weekDays;
  };

  function getSelectedWeek(startDate) {
    const currentWeek = getCurrentWeek(startDate);
    setCurrentWeekCal(currentWeek);
  }

  const handleArrowClick = (e, direction) => {
    let newIndex = 0;
    let additionDate;

    if (currentIndex == 0) {
      const datesArrayVal = getArrowDatesArray(startDate, endDate);

      newIndex = direction === "next" ? currentIndex + 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
      direction === "next"
        ? (additionDate = datesArrayVal[newIndex])
        : (additionDate = datesArrayVal[newIndex]);

      const date = moment(additionDate);
      const formattedDate = date.format("MM-DD-YYYY");
      SelectSliders(newIndex, formattedDate);
    } else {
      const datesArrayVal = getArrowDatesArray(startDate, endDate);

      newIndex = direction === "next" ? currentIndex + 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
      direction === "next"
        ? (additionDate = datesArrayVal[newIndex])
        : (additionDate = datesArrayVal[newIndex]);
      const date = moment(additionDate);
      const formattedDate = date.format("MM-DD-YYYY");
      SelectSliders(newIndex, formattedDate);
    }
  };

  const getUpcomingSunday = (mondayDate) => {
    const monday = moment(mondayDate, "MM-DD-YYYY"); // parse Monday date with format
    const sunday = monday.add(6, "days"); // add 6 days to Monday to get Sunday
    return sunday.format("YYYY-MM-DD"); // return Sunday date in YYYY-MM-DD format
  };

  function SelectSliders(idx, obs) {
    var element = document.getElementById("listID-" + tempSlide);
    if (element != null) {
      element.classList.toggle("selectedList");
    }

    var elementexp = document.getElementById("expandId-" + tempSlide);
    if (elementexp != null) {
      elementexp.classList.toggle("expanded");
    }

    setTaskID();
    setTempSlide();

    const monday = moment(obs, "MM-DD-YYYY");
    let day = monday.format("YYYY-MM-DD");
    let lastdays = getUpcomingSunday(obs);
    slider1?.slickGoTo(idx);
    setStartDate(day);
    setEndDate(lastdays);
    getSelectSliderCount(day, lastdays);
    getSelectedWeek(day);
  }

  async function forSelectList(index, id) {
    setTaskID(id);
    setTempSlide(index + "" + id);

    let results = add(selectedTaskList, taskid);

    if (results == false) {
      var element = document.getElementById("listID-" + tempSlide);
      if (element != null && tempSlide != index + "" + id) {
        element.classList.toggle("selectedList");
      }

      var elementexp = document.getElementById("expandId-" + tempSlide);
      if (elementexp != null && tempSlide != index + "" + id) {
        elementexp.classList.toggle("expanded");
      }

      if (tempSlide == index + "" + id) {
        setTempSlide("");
      }
    } else if (results == true) {
      var elementexp = document.getElementById("expandId-" + tempSlide);
      if (elementexp != null && tempSlide != index + "" + id) {
        elementexp.classList.toggle("expanded");
      }

      if (tempSlide == index + "" + id) {
        setTempSlide("");
      }
    } else {
      var element = document.getElementById("listID-" + tempSlide);
      if (element != null && tempSlide != index + "" + id) {
        element.classList.toggle("selectedList");
      }

      var elementexp = document.getElementById("expandId-" + tempSlide);
      if (elementexp != null && tempSlide != index + "" + id) {
        elementexp.classList.toggle("expanded");
      }

      if (tempSlide == index + "" + id) {
        setTempSlide("");
      }
    }

    setToggleId(index);
    setShowLoader(true);

    await getselectedSchedular(id, startDate, endDate);

    let res = add(selectedTaskList, id);
    if (res) {
      var element = document.getElementById("listID-" + index + id);
      element.classList.add("selectedList");
    } else {
      var element = document.getElementById("listID-" + index + id);
      element.classList.toggle("selectedList");
    }

    var elementexp = document.getElementById("expandId-" + index + id);
    elementexp.classList.toggle("expanded");

    setTimeout(() => {
      setShowLoader(false);
    }, 1000);
  }

  function a11yProps(index, ids) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  /*  const useStyles = makeStyles((theme)=>({
          formWrapper:{
            marginTop:theme.spacing(5),
            marginBottom:theme.spacing(8),
          },
        })); */

  const [showLoader, setShowLoader] = React.useState(false);
  const [loginErrMsg, setLoginErrMsg] = React.useState("");

  const initialValues = {
    category_name: "",
  };

  const onSubmit = async (values) => {};

  function handleChangeSortBy() {
    setSelected(!selected);

    if (customSort == 0) {
      setcustomSort(1);
      let empArray = employeesortavailable.sort((a, b) =>
        b.full_name.localeCompare(a.full_name)
      );
      setEmployeeAvailable(empArray);
    } else if (customSort == 1) {
      setcustomSort(0);
      let empArray = employeesortavailable.sort((a, b) =>
        a.full_name.localeCompare(b.full_name)
      );
      setEmployeeAvailable(empArray);
    }
  }

  const validationSchema = Yup.object({
    category_name: Yup.string().required("Required"),
  });

  async function getAllTaskList(searchString) {
    const keyword = searchString.toLowerCase();
    if (keyword) {
      let matchedData;
      if (employeeavailable.length > 0) {
        matchedData = employeeavailable.filter((item) =>
          item.full_name.toLowerCase().includes(keyword)
        );
        setEmployeeAvailable(matchedData);
      } else {
        setEmployeeAvailable([]);
      }
    } else {
      getSelectCount();
    }
  }

  async function updateSchedular(e, shiftindex, employeeid, locationid) {
    console.log(employeeavailable);

    const targetEmployee = employeeavailable.find(
      (employee) => employee.employee_id === employeeid
    );

    if (targetEmployee) {
      targetEmployee.locations.forEach((location) => {
        location.shifttimes.forEach((shifttime) => {
          Object.keys(shifttime.availability).forEach((day) => {
            console.log("My new locations====>" + location.location);
            if (location.location === locationid) {
              console.log("My object shift===>" + shifttime.shifttime);
              console.log("My object day===>" + day);
              console.log(
                "My object DATE===>" + shifttime.availability[day].schedule_date
              );
              console.log(
                "My object assigned===>" +
                  shifttime.availability[day].is_assigned
              );

              makeGetRequest(
                "employeeschedular/deleteautoschedular/" +
                  employeeid +
                  "/'" +
                  shifttime.shifttime +
                  "'/'" +
                  startDate +
                  "'/'" +
                  endDate +
                  "'/'" +
                  locationid +
                  "'/'" +
                  day +
                  "'"
              )
                .then((response) => {
                  console.log("delete", response);

                  if (shifttime.availability[day].is_assigned) {
                    let newMyarray = [];
                    newMyarray = [
                      {
                        emp_id: employeeid,
                        location: locationid,
                        shifttime: shifttime.shifttime,
                        weekday: day,
                        weekdate: shifttime.availability[day].schedule_date,
                        frequency_id: shifttime.Frequencyid,
                      },
                    ];

                    makePostRequest(
                      "employeeschedular/inserttaskautoschedular",
                      newMyarray
                    )
                      .then((response) => {
                        if (response.data.data) {
                          newMyarray = [];
                        }
                      })
                      .catch((err) => {});
                  }
                })
                .catch((err) => {});
            }
          });
        });
      });
      //setEmployeeAvailable([...employeeavailable]);
    }

    setTimeout(() => {
      getEmployeeShiftDetails(
        localStorage.getItem("userId"),
        currentTime,
        loginstartDate,
        currentDay,
        localStorage.getItem("userType")
      );
    }, 2000);

    swal("Success", "The employee schedular added successfully", "success", {
      timer: 3000,
      buttons: false,
    });
  }

  async function getselectedSchedular(empid, startDate, endDate) {
    //setShowLoader(true);

    setSelectedMenuOfMonday({
      schedule_date: "",
      employeeid: "",
      weekday: "",
      status: "",
      shifttime: "",
      locationid: "",
    });

    setSelectedMenuOfSunday({
      schedule_date: "",
      employeeid: "",
      weekday: "",
      status: "",
      shifttime: "",
      locationid: "",
    });

    setSelectedScheduleOfTuesday({
      schedule_date: "",
      employeeid: "",
      weekday: "",
      status: "",
      shifttime: "",
      locationid: "",
    });

    setSelectedMenuOfWednesday({
      schedule_date: "",
      employeeid: "",
      weekday: "",
      status: "",
      shifttime: "",
      locationid: "",
    });

    setSelectedMenuOfThursday({
      schedule_date: "",
      employeeid: "",
      weekday: "",
      status: "",
      shifttime: "",
      locationid: "",
    });

    setSelectedMenuOfFriday({
      employeeid: "",
      weekday: "",
      status: "",
      shifttime: "",
      locationid: "",
    });

    setSelectedMenuOfSaturday({
      schedule_date: "",
      employeeid: "",
      weekday: "",
      status: "",
      shifttime: "",
      locationid: "",
    });

    await makeGetRequest(
      "employeeschedular/getselectedschedular/" +
        empid +
        "/'" +
        startDate +
        "'/'" +
        endDate +
        "'"
    )
      .then((response) => {
        if (response.data.data) {
          let temp = response.data.data.rows;

          if (temp.length > 0) {
            for (var i = 0; i < temp.length; i++) {
              if (temp[i].weekday == "Monday") {
                setSelectedMenuOfMonday(temp[i]);
                setUpload(!upload);
              }
              if (temp[i].weekday == "Tuesday") {
                setSelectedScheduleOfTuesday(temp[i]);
                setUpload(!upload);
              }
              if (temp[i].weekday == "Wednesday") {
                setSelectedMenuOfWednesday(temp[i]);
                setUpload(!upload);
              }
              if (temp[i].weekday == "Thursday") {
                setSelectedMenuOfThursday(temp[i]);
                setUpload(!upload);
              }
              if (temp[i].weekday == "Friday") {
                setSelectedMenuOfFriday(temp[i]);
                setUpload(!upload);
              }
              if (temp[i].weekday == "Saturday") {
                setSelectedMenuOfSaturday(temp[i]);
                setUpload(!upload);
              }
              if (temp[i].weekday == "Sunday") {
                setSelectedMenuOfSunday(temp[i]);
                setUpload(!upload);
              }
            }
          } else {
            setSelectedMenuOfMonday({
              schedule_date: "",
              employeeid: "",
              weekday: "",
              status: "",
              shifttime: "",
              locationid: "",
            });
            setSelectedScheduleOfTuesday({
              schedule_date: "",
              employeeid: "",
              weekday: "",
              status: "",
              shifttime: "",
              locationid: "",
            });
            setSelectedMenuOfWednesday({
              schedule_date: "",
              employeeid: "",
              weekday: "",
              status: "",
              shifttime: "",
              locationid: "",
            });
            setSelectedMenuOfThursday({
              schedule_date: "",
              employeeid: "",
              weekday: "",
              status: "",
              shifttime: "",
              locationid: "",
            });
            setSelectedMenuOfFriday({
              schedule_date: "",
              employeeid: "",
              weekday: "",
              status: "",
              shifttime: "",
              locationid: "",
            });
            setSelectedMenuOfSaturday({
              schedule_date: "",
              employeeid: "",
              weekday: "",
              status: "",
              shifttime: "",
              locationid: "",
            });
            setSelectedMenuOfSunday({
              schedule_date: "",
              employeeid: "",
              weekday: "",
              status: "",
              shifttime: "",
              locationid: "",
            });
          }
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  async function getAllCategoryList(searchString) {
    if (!searchString) searchString = null;

    await makeGetRequest("frequancy/getActiveFrequancyList")
      .then((response) => {
        if (response.data.data) {
          if (response.data.data.length > 0) {
            setCategoryList(response.data.data);
            const frequencyMap = response.data.data.reduce((acc, curr) => {
              acc[curr.frequancyid] = curr.frequancy_name;
              return acc;
            }, {});
            setFrequencyData(frequencyMap);
            //setShowLoader(false);
          } else {
            setCategoryList([]);
            //setShowLoader(false);
          }
          setUpload(!upload);
        } else setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  React.useEffect(() => {
    setNav1(slider1);
  }, [slider1]);

  const isDisabledCheck = (dayValue) => {
    console.log(dayValue);
    var todayDay = moment(todaysDate).format("MM-DD-YYYY");
    return dayValue < todayDay;
  };

  React.useEffect(() => {
    getAllCategoryList();
    const startcDate = getCurrentWeekMonday();
    const endcDate = moment().endOf("year").add(1, "year");

    let stringMonday = getAllMondays(startcDate, endcDate);
    setMondaysCal(stringMonday);

    let entireweek = getEntireWeek();
    setCurrentWeekCal(entireweek);
    console.log(entireweek);
    setCurrentIndex(todayIndex);
    getSelectCount();
  }, []);

  return (
    <>
      <TopBar />
      <div className="wrapper">
        <div className="taskListAdmMain">
          {/* TopBar */}
          <div className="innerPageTopSecBackPatch"></div>
          <div className="innerPageTopSec">
            {/* <div className="forBackArrow forAppDrawer">
              <AppDrawer />
            </div> */}
            <h1>Employee Shift Scheduler</h1>
            {/* <div className="forBackArrow"></div> */}
          </div>
          {/* TopBarEnd */}

          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <div
                className={`mySlik clickAllTile ${
                  scrolling ? "scrolledDate" : ""
                }`}
                style={{ marginTop: -17 }}
              >
                <div className="slider-container">
                  <Slider
                    className="dateSliderOuter"
                    {...settings}
                    ref={(slider) => setSlider1(slider)}
                  >
                    {mondaysCal.map((obs, idx) => (
                      <>
                        <div
                          className="dateSliderInner"
                          onClick={(e) => SelectSliders(idx, obs.Mondaydate)}
                        >
                          <span>Mon</span>
                          <h3 className="underSlikDate">{obs.Mondaydate}</h3>
                        </div>
                      </>
                    ))}
                  </Slider>
                </div>
              </div>
              {/* <div className="dateCustom">
                <img src={calendarIm} />
                <span>Select Week Starting On</span>
                <input type="date" />
              </div> */}
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="daySlider emplShifSlider">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="wrapper" style={{ paddingBottom: 0 }}>
              <div className="listingSearch" style={{ marginBottom: 0 }}>
                <input
                  type="text"
                  placeholder="Search Employee"
                  onChange={(e) => getAllTaskList(e.target.value)}
                />
                <div className="sortingIcoOut">
                  <ToggleButton
                    value="check"
                    selected={selected}
                    onChange={() => {
                      handleChangeSortBy();
                    }}
                  >
                    <ImportExportIcon
                      className="visitorFilterIco"
                      aria-haspopup="true"
                    />
                  </ToggleButton>
                </div>
                <img
                  src={searchEmployee}
                  onClick={() => navigate("/SchedulPreview")}
                />
              </div>
            </div>
          </Grid>
          {employeeavailable.length > 0 ? (
            <>
              {employeeavailable.map((empObj, empindex) => (
                <>
                  <Grid item xs={12} md={12}>
                    <div className="emploShitContMain">
                      <div className="emploShitConTop">
                        <div className="emploShitConTopLft">
                          <div className="emploShitConTopPro">
                            {empObj.profile_pic ? (
                              <img src={empObj.profile_pic} />
                            ) : (
                              <img src={profilePic} />
                            )}
                          </div>
                          <h1>{empObj.full_name}</h1>
                        </div>
                        {/* <p>
                      <b>40</b>/20hrs
                    </p> */}
                      </div>
                      <div
                        className="schedularAccordianMain newSchedAccordin"
                        style={{ marginBottom: 0 }}
                      >
                        {empObj.locations.map((locationObj, shiftindex) => (
                          <>
                            <Accordion
                              expanded={
                                expanded ===
                                "panel" + empindex + "" + shiftindex
                              }
                              onChange={handleChange2(
                                "panel" + empindex + "" + shiftindex
                              )}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                              >
                                <Typography>{locationObj.location}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="weeklyTaskTbl">
                                  <table width="100%">
                                    <thead>
                                      <tr>
                                        <th>
                                          {/* <FormControlLabel
                                            control={<Checkbox />}
                                            label="All"
                                          /> */}
                                        </th>
                                        <th>M</th>
                                        <th>T</th>
                                        <th>W</th>
                                        <th>T</th>
                                        <th>F</th>
                                        <th>S</th>
                                        <th>S</th>
                                      </tr>
                                      <tr>
                                        <th className="checkUpdatB">
                                          <IconButton
                                            onClick={(e) =>
                                              updateSchedular(
                                                e,
                                                shiftindex,
                                                empObj.employee_id,
                                                locationObj.location
                                              )
                                            }
                                          >
                                            <CheckIcon />
                                          </IconButton>
                                        </th>
                                        {currentweekCal.map((currentobs, i) => (
                                          <>
                                            <th>
                                              {manageDate(
                                                currentobs.CurrentWeek
                                              )}
                                            </th>
                                          </>
                                        ))}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {empObj.locations[
                                        shiftindex
                                      ].shifttimes.map(
                                        (shifttimesObj, shifttimesObjindex) => (
                                          <>
                                            <tr>
                                              <td>
                                                <div key={shifttimesObjindex}>
                                                  {categoryList.find(
                                                    (category) =>
                                                      parseInt(
                                                        category.frequancyid
                                                      ) ===
                                                      parseInt(
                                                        shifttimesObj.Frequencyid
                                                      )
                                                  ) ? (
                                                    <p className="weekShifNameLab">
                                                      {
                                                        categoryList.find(
                                                          (category) =>
                                                            parseInt(
                                                              category.frequancyid
                                                            ) ===
                                                            parseInt(
                                                              shifttimesObj.Frequencyid
                                                            )
                                                        ).frequancy_name
                                                      }
                                                    </p>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </div>

                                                <span className="tblTiming">
                                                  <em>
                                                    {shifttimesObj.shifttime}
                                                  </em>
                                                  <SubdirectoryArrowLeftIcon />
                                                </span>
                                              </td>

                                              <td
                                                className={
                                                  shifttimesObj.availability
                                                    ?.Monday !== undefined &&
                                                  shifttimesObj.availability
                                                    .Monday.is_available
                                                    ? "activeAvail"
                                                    : ""
                                                }
                                              >
                                                <Checkbox
                                                  {...label}
                                                  id={
                                                    `mon` +
                                                    empindex +
                                                    "" +
                                                    shiftindex +
                                                    "" +
                                                    empObj.employee_id +
                                                    "" +
                                                    shifttimesObjindex
                                                  }
                                                  checked={
                                                    shifttimesObj.availability
                                                      ?.Monday !== undefined &&
                                                    shifttimesObj.availability
                                                      .Monday.is_assigned
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) =>
                                                    handleChangeWeekDayCheckBox(
                                                      e.target.checked,
                                                      empindex +
                                                        "" +
                                                        shiftindex,
                                                      empObj.employee_id,
                                                      "Monday",
                                                      currentweekCal,
                                                      shifttimesObj.shifttime,
                                                      locationObj.location,
                                                      shifttimesObjindex,
                                                      shifttimesObj.Frequencyid
                                                    )
                                                  }
                                                  disabled={
                                                    shifttimesObj.availability
                                                      ?.Monday !== undefined &&
                                                    shifttimesObj.availability
                                                      .Monday.is_available &&
                                                    new Date(
                                                      currentweekCal[0].CurrentWeek
                                                    ) >= new Date(currentDate)
                                                      ? ""
                                                      : "disabled"
                                                  }
                                                />
                                              </td>
                                              <td
                                                className={
                                                  shifttimesObj.availability
                                                    ?.Tuesday !== undefined &&
                                                  shifttimesObj.availability
                                                    .Tuesday.is_available
                                                    ? "activeAvail"
                                                    : ""
                                                }
                                              >
                                                <Checkbox
                                                  {...label}
                                                  id={
                                                    `tues` +
                                                    empindex +
                                                    "" +
                                                    shiftindex +
                                                    "" +
                                                    empObj.employee_id +
                                                    "" +
                                                    shifttimesObjindex
                                                  }
                                                  checked={
                                                    shifttimesObj.availability
                                                      ?.Tuesday !== undefined &&
                                                    shifttimesObj.availability
                                                      .Tuesday.is_assigned
                                                      ? true
                                                      : false
                                                  }
                                                  // checked={
                                                  //   selectedScheduleOfTuesday.status ||
                                                  //   (shifttimesObj.availability
                                                  //     ?.Tuesday !== undefined &&
                                                  //     shifttimesObj.availability
                                                  //       .Tuesday.is_assigned)
                                                  //     ? true
                                                  //     : false
                                                  // }
                                                  onChange={(e) =>
                                                    handleChangeWeekDayCheckBox(
                                                      e.target.checked,
                                                      empindex +
                                                        "" +
                                                        shiftindex,
                                                      empObj.employee_id,
                                                      "Tuesday",
                                                      currentweekCal,
                                                      shifttimesObj.shifttime,
                                                      locationObj.location,
                                                      shifttimesObjindex,
                                                      shifttimesObj.Frequencyid
                                                    )
                                                  }
                                                  disabled={
                                                    shifttimesObj.availability
                                                      ?.Tuesday !== undefined &&
                                                    shifttimesObj.availability
                                                      .Tuesday.is_available &&
                                                    new Date(
                                                      currentweekCal[1].CurrentWeek
                                                    ) >= new Date(currentDate)
                                                      ? ""
                                                      : "disabled"
                                                  }
                                                />
                                              </td>
                                              <td
                                                className={
                                                  shifttimesObj.availability
                                                    ?.Wednesday !== undefined &&
                                                  shifttimesObj.availability
                                                    .Wednesday.is_available
                                                    ? "activeAvail"
                                                    : ""
                                                }
                                              >
                                                <Checkbox
                                                  {...label}
                                                  id={
                                                    `wed` +
                                                    empindex +
                                                    "" +
                                                    shiftindex +
                                                    "" +
                                                    empObj.employee_id +
                                                    "" +
                                                    shifttimesObjindex
                                                  }
                                                  checked={
                                                    shifttimesObj.availability
                                                      ?.Wednesday !==
                                                      undefined &&
                                                    shifttimesObj.availability
                                                      .Wednesday.is_assigned
                                                      ? true
                                                      : false
                                                  }
                                                  // checked={
                                                  //   selectedMenuOfWednesday.status ||
                                                  //   (shifttimesObj.availability
                                                  //     ?.Wednesday !==
                                                  //     undefined &&
                                                  //     shifttimesObj.availability
                                                  //       .Wednesday.is_assigned)
                                                  //     ? true
                                                  //     : false
                                                  // }

                                                  onChange={(e) =>
                                                    handleChangeWeekDayCheckBox(
                                                      e.target.checked,
                                                      empindex +
                                                        "" +
                                                        shiftindex,
                                                      empObj.employee_id,
                                                      "Wednesday",
                                                      currentweekCal,
                                                      shifttimesObj.shifttime,
                                                      locationObj.location,
                                                      shifttimesObjindex,
                                                      shifttimesObj.Frequencyid
                                                    )
                                                  }
                                                  disabled={
                                                    shifttimesObj.availability
                                                      ?.Wednesday !==
                                                      undefined &&
                                                    shifttimesObj.availability
                                                      .Wednesday.is_available &&
                                                    new Date(
                                                      currentweekCal[2].CurrentWeek
                                                    ) >= new Date(currentDate)
                                                      ? ""
                                                      : "disabled"
                                                  }
                                                />
                                              </td>
                                              <td
                                                className={
                                                  shifttimesObj.availability
                                                    ?.Thursday !== undefined &&
                                                  shifttimesObj.availability
                                                    .Thursday.is_available
                                                    ? "activeAvail"
                                                    : ""
                                                }
                                              >
                                                <Checkbox
                                                  {...label}
                                                  id={
                                                    `thur` +
                                                    empindex +
                                                    "" +
                                                    shiftindex +
                                                    "" +
                                                    empObj.employee_id +
                                                    "" +
                                                    shifttimesObjindex
                                                  }
                                                  checked={
                                                    shifttimesObj.availability
                                                      ?.Thursday !==
                                                      undefined &&
                                                    shifttimesObj.availability
                                                      .Thursday.is_assigned
                                                      ? true
                                                      : false
                                                  }
                                                  // checked={
                                                  //   selectedMenuOfThursday.status ||
                                                  //   (shifttimesObj.availability
                                                  //     ?.Thursday !==
                                                  //     undefined &&
                                                  //     shifttimesObj.availability
                                                  //       .Thursday.is_assigned)
                                                  //     ? true
                                                  //     : false
                                                  // }

                                                  onChange={(e) =>
                                                    handleChangeWeekDayCheckBox(
                                                      e.target.checked,
                                                      empindex +
                                                        "" +
                                                        shiftindex,
                                                      empObj.employee_id,
                                                      "Thursday",
                                                      currentweekCal,
                                                      shifttimesObj.shifttime,
                                                      locationObj.location,
                                                      shifttimesObjindex,
                                                      shifttimesObj.Frequencyid
                                                    )
                                                  }
                                                  disabled={
                                                    shifttimesObj.availability
                                                      ?.Thursday !==
                                                      undefined &&
                                                    shifttimesObj.availability
                                                      .Thursday.is_available &&
                                                    new Date(
                                                      currentweekCal[3].CurrentWeek
                                                    ) >= new Date(currentDate)
                                                      ? ""
                                                      : "disabled"
                                                  }
                                                />
                                              </td>
                                              <td
                                                className={
                                                  shifttimesObj.availability
                                                    ?.Friday !== undefined &&
                                                  shifttimesObj.availability
                                                    .Friday.is_available
                                                    ? "activeAvail"
                                                    : ""
                                                }
                                              >
                                                <Checkbox
                                                  {...label}
                                                  id={
                                                    `fri` +
                                                    empindex +
                                                    "" +
                                                    shiftindex +
                                                    "" +
                                                    empObj.employee_id +
                                                    "" +
                                                    shifttimesObjindex
                                                  }
                                                  checked={
                                                    shifttimesObj.availability
                                                      ?.Friday !== undefined &&
                                                    shifttimesObj.availability
                                                      .Friday.is_assigned
                                                      ? true
                                                      : false
                                                  }
                                                  // checked={
                                                  //   selectedMenuOfFriday.status ||
                                                  //   (shifttimesObj.availability
                                                  //     ?.Friday !== undefined &&
                                                  //     shifttimesObj.availability
                                                  //       .Friday.is_assigned)
                                                  //     ? true
                                                  //     : false
                                                  // }

                                                  onChange={(e) =>
                                                    handleChangeWeekDayCheckBox(
                                                      e.target.checked,
                                                      empindex +
                                                        "" +
                                                        shiftindex,
                                                      empObj.employee_id,
                                                      "Friday",
                                                      currentweekCal,
                                                      shifttimesObj.shifttime,
                                                      locationObj.location,
                                                      shifttimesObjindex,
                                                      shifttimesObj.Frequencyid
                                                    )
                                                  }
                                                  disabled={
                                                    shifttimesObj.availability
                                                      ?.Friday !== undefined &&
                                                    shifttimesObj.availability
                                                      .Friday.is_available &&
                                                    new Date(
                                                      currentweekCal[4].CurrentWeek
                                                    ) >= new Date(currentDate)
                                                      ? ""
                                                      : "disabled"
                                                  }
                                                />
                                              </td>
                                              <td
                                                className={
                                                  shifttimesObj.availability
                                                    ?.Saturday !== undefined &&
                                                  shifttimesObj.availability
                                                    .Saturday.is_available
                                                    ? "activeAvail"
                                                    : ""
                                                }
                                              >
                                                <Checkbox
                                                  {...label}
                                                  id={
                                                    `sat` +
                                                    empindex +
                                                    "" +
                                                    shiftindex +
                                                    "" +
                                                    empObj.employee_id +
                                                    "" +
                                                    shifttimesObjindex
                                                  }
                                                  checked={
                                                    shifttimesObj.availability
                                                      ?.Saturday !==
                                                      undefined &&
                                                    shifttimesObj.availability
                                                      .Saturday.is_assigned
                                                      ? true
                                                      : false
                                                  }
                                                  // checked={
                                                  //   selectedMenuOfSaturday.status ||
                                                  //   (shifttimesObj.availability
                                                  //     ?.Saturday !==
                                                  //     undefined &&
                                                  //     shifttimesObj.availability
                                                  //       .Saturday.is_assigned)
                                                  //     ? true
                                                  //     : false
                                                  // }

                                                  onChange={(e) =>
                                                    handleChangeWeekDayCheckBox(
                                                      e.target.checked,
                                                      empindex +
                                                        "" +
                                                        shiftindex,
                                                      empObj.employee_id,
                                                      "Saturday",
                                                      currentweekCal,
                                                      shifttimesObj.shifttime,
                                                      locationObj.location,
                                                      shifttimesObjindex,
                                                      shifttimesObj.Frequencyid
                                                    )
                                                  }
                                                  disabled={
                                                    shifttimesObj.availability
                                                      ?.Saturday !==
                                                      undefined &&
                                                    shifttimesObj.availability
                                                      .Saturday.is_available &&
                                                    new Date(
                                                      currentweekCal[5].CurrentWeek
                                                    ) >= new Date(currentDate)
                                                      ? ""
                                                      : "disabled"
                                                  }
                                                />
                                              </td>
                                              <td
                                                className={
                                                  shifttimesObj.availability
                                                    ?.Sunday !== undefined &&
                                                  shifttimesObj.availability
                                                    .Sunday.is_available
                                                    ? "activeAvail"
                                                    : ""
                                                }
                                              >
                                                <Checkbox
                                                  {...label}
                                                  id={
                                                    `sunday` +
                                                    empindex +
                                                    "" +
                                                    shiftindex +
                                                    "" +
                                                    empObj.employee_id +
                                                    "" +
                                                    shifttimesObjindex
                                                  }
                                                  checked={
                                                    shifttimesObj.availability
                                                      ?.Sunday !== undefined &&
                                                    shifttimesObj.availability
                                                      .Sunday.is_assigned
                                                      ? true
                                                      : false
                                                  }
                                                  // checked={
                                                  //   selectedMenuOfSunday.status ||
                                                  //   (shifttimesObj.availability
                                                  //     ?.Sunday !== undefined &&
                                                  //     shifttimesObj.availability
                                                  //       .Sunday.is_assigned)
                                                  //     ? true
                                                  //     : false
                                                  // }

                                                  onChange={(e) =>
                                                    handleChangeWeekDayCheckBox(
                                                      e.target.checked,
                                                      empindex +
                                                        "" +
                                                        shiftindex,
                                                      empObj.employee_id,
                                                      "Sunday",
                                                      currentweekCal,
                                                      shifttimesObj.shifttime,
                                                      locationObj.location,
                                                      shifttimesObjindex,
                                                      shifttimesObj.Frequencyid
                                                    )
                                                  }
                                                  disabled={
                                                    shifttimesObj.availability
                                                      ?.Sunday !== undefined &&
                                                    shifttimesObj.availability
                                                      .Sunday.is_available &&
                                                    new Date(
                                                      currentweekCal[6].CurrentWeek
                                                    ) >= new Date(currentDate)
                                                      ? ""
                                                      : "disabled"
                                                  }
                                                />
                                              </td>
                                            </tr>
                                          </>
                                        )
                                      )}

                                      {/* <tr>
                                <td>
                                  <span className="tblTiming">
                                    <em>
                                      09:30am <br />
                                      12:30pm
                                    </em>
                                    <SubdirectoryArrowLeftIcon />
                                  </span>
                                </td>
                                <td>
                                  <Checkbox {...label} disabled />
                                </td>
                                <td>
                                  <Checkbox {...label} disabled />
                                </td>
                                <td className="activeAvail">
                                  <Checkbox {...label} defaultChecked />
                                </td>
                                <td className="activeAvail">
                                  <Checkbox {...label} disabled />
                                </td>
                                <td className="activeAvail">
                                  <Checkbox {...label} defaultChecked />
                                </td>
                                <td>
                                  <Checkbox {...label} disabled />
                                </td>
                                <td>
                                  <Checkbox {...label} disabled />
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <span className="tblTiming">
                                    <em>
                                      09:30am <br />
                                      12:30pm
                                    </em>
                                    <SubdirectoryArrowLeftIcon />
                                  </span>
                                </td>
                                <td className="activeAvail">
                                  <Checkbox {...label} defaultChecked />
                                </td>
                                <td>
                                  <Checkbox {...label} disabled />
                                </td>
                                <td className="activeAvail">
                                  <Checkbox {...label} defaultChecked />
                                </td>
                                <td>
                                  <Checkbox {...label} disabled />
                                </td>
                                <td>
                                  <Checkbox {...label} disabled />
                                </td>
                                <td>
                                  <Checkbox {...label} disabled />
                                </td>
                                <td>
                                  <Checkbox {...label} disabled />
                                </td>
                              </tr> */}
                                    </tbody>
                                  </table>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </>
                        ))}
                      </div>
                    </div>
                  </Grid>
                </>
              ))}
            </>
          ) : (
            <>
              <div class="dataNoF">
                {" "}
                <img src={noPic} />
                <p>Data Not Found</p>
              </div>
            </>
          )}
        </Grid>

        {showLoader ? (
          <div className="loaderNew">
            <div className="loader"></div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <AdminBottomNav />
    </>
  );
}

export default WeeklyTasksNew;
