import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdminSplash from "./screens/admin/AdminSplash/AdminSplash";
import AdminLogin from "./screens/admin/AdminLogin/AdminLogin";
import AddEmployeeAdmin from "./screens/admin/AddEmployeeAdmin/AddEmployeeAdmin";
import AdminTasksList from "./screens/admin/AdminTasksList/AdminTasksList";
import AdminAddTask from "./screens/admin/AdminTasksList/AdminAddTask";
import TasksScheduler from "./screens/admin/TasksScheduler/TasksScheduler";
import TasksSchedulerBackUp from "./screens/admin/TasksScheduler/TasksSchedulerBackUp";
import EditEmployeeAdmin from "./screens/admin/AddEmployeeAdmin/EditEmployeeAdmin";
import EmployeeListing from "./screens/admin/EmployeeListing/EmployeeListing";
import SliderDemo from "./screens/admin/SliderDemo";
import ReportAdmin from "./screens/admin/ReportAdmin/ReportAdmin";
import AdminEditTask from "./screens/admin/AdminTasksList/AdminEditTask";
import WeeklyTasks from "./screens/admin/AdminTasksList/WeeklyTasks";
import TaskDetails from "./screens/admin/AdminTasksList/TaskDetails";
import PersonalTaskDetails from "./screens/admin/AdminTasksList/PersonalTaskDetails";
import SplashEmployee from "./screens/employee/SplashEmployee/SplashEmployee";
import LoginEmployee from "./screens/employee/LoginEmployee/LoginEmployee";
import EmployeeTaskList from "./screens/employee/EmployeeTaskList/EmployeeTaskList";
import AddCategory from "./screens/admin/AdminTasksList/AddCategory";
import AddFrequency from "./screens/admin/AdminTasksList/AddFrequency";
import Login from "./screens/admin/AdminLogin/Login";
import CategoryDetails from "./screens/admin/AdminTasksList/CategoryDetails";
import PersonalCategoryDetails from "./screens/admin/AdminTasksList/PersonalCategoryDetails";
import SuperAdminDashboard from "./screens/admin/SuperAdmin/superAdminDashboard/SuperAdminDashboard";
import AddCompany from "./screens/admin/SuperAdmin/AddCompany";
import AddCompanyAdmin from "./screens/admin/SuperAdmin/AddCompanyAdmin";
import AdminList from "./screens/admin/SuperAdmin/AdminList";
import EditCompanyAdmin from "./screens/admin/SuperAdmin/EditCompanyAdmin";

import CheckTasksAvailability from "./screens/employee/CheckTasksAvailability/CheckTasksAvailability";
import CreateNewSchedule from "./screens/admin/AdminTasksList/CreateNewSchedule";
import MyAvailability from "./screens/employee/MyAvailability/MyAvailability";
import PersonalTasks from "./screens/employee/PersonalTasks/PersonalTasks";
import AddPersonalTasks from "./screens/employee/PersonalTasks/AddPersonalTasks";
import PersonalAddFrequency from "./screens/employee/PersonalTasks/PersonalAddFrequency";
import PersonalAddCategory from "./screens/employee/PersonalTasks/PersonalAddCategory";

import VoiceToTextTask from "./screens/employee/PersonalTasks/VoiceToTextTask";
import PersonalTaskNew from "./screens/employee/PersonalTasks/PersonalTaskNew";
import EmployeeTaskListNew from "./screens/employee/EmployeeTaskList/EmployeeTaskListNew";
import SharedWith from "./screens/employee/PersonalTasks/SharedWith";
import Profile from "./screens/employee/PersonalTasks/Profile";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WeeklyTasksNew from "./screens/admin/AdminTasksList/WeeklyTasksNew";
import SchedulPreview from "./screens/admin/AdminTasksList/SchedulPreview";
import DragNDrop from "./screens/DragNDrop";
import ArchivePage from "./screens/admin/AdminTasksList/ArchivePage";
import AdminTasksViewPreferences from "./screens/admin/AdminTasksList/AdminTasksViewPreferences";
import AdminTasksListDemo from "./screens/admin/AdminTasksList/AdminTasksListDemo";
import ShiftSwapAndOffer from "./screens/employee/MyAvailability/ShiftSwapAndOffer";
import EditTaskList from "./screens/admin/AdminTasksList/EditTaskList";
import DragNDropTabsDemo from "./screens/DragNDropTabsDemo";
import { NotificationProvider } from "./components/NotificationContext";
import TopBar from "./screens/admin/TopBar";
import NotificationPage from "./screens/admin/NotificationPage/NotificationPage";
import ShiftScheduler from "./screens/admin/ShiftScheduler/ShiftScheduler";
import Preference from "./screens/admin/ShiftScheduler/Preference ";
import Availability from "./screens/admin/ShiftScheduler/Availability";
import ShoppingList from "./screens/admin/ShoppingList/ShoppingList";
import MorePage from "./screens/admin/MorePage/MorePage";
import HideAndShowRowDemo from "./screens/HideAndShowRowDemo";
import Location from "./screens/admin/AdminTasksList/Location";
import EditLocation from "./screens/admin/AdminTasksList/EditLocation";
import MoreOptionsEmployee from "./screens/employee/MoreOptionsEmployee/MoreOptionsEmployee";
import PrivacyPolicy from "./screens/employee/MoreOptionsEmployee/PrivacyPolicy";
import AboutUs from "./screens/employee/MoreOptionsEmployee/AboutUs";
import UserGuide from "./screens/employee/MoreOptionsEmployee/UserGuide";
import FAQs from "./screens/employee/MoreOptionsEmployee/FAQs";
import UserGuideAdmin from "./screens/admin/MorePage/UserGuideAdmin";
import PrivacyPolicyAdmin from "./screens/admin/MorePage/PrivacyPolicyAdmin";
import AboutUsAdmin from "./screens/admin/MorePage/AboutUsAdmin";
import FAQsAdmin from "./screens/admin/MorePage/FAQsAdmin";

function App() {
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          {/* Admin Screens */}
          <Route path="/" element={<AdminSplash />} />
          <Route path="EmployeeLogin" element={<AdminLogin />} />
          <Route path="Login" element={<Login />} />
          <Route path="EmployeeListing" element={<EmployeeListing />} />
          <Route path="AddEmployeeAdmin" element={<AddEmployeeAdmin />} />
          <Route
            path="EditEmployeeAdmin/:empId"
            element={<EditEmployeeAdmin />}
          />
          <Route path="AdminTasksList" element={<AdminTasksList />} />
          <Route path="/Location" element={<Location />} /> {/* New Page */}
          <Route path="AdminAddTask" element={<AdminAddTask />} />
          <Route path="AdminEditTask" element={<AdminEditTask />} />
          <Route path="TasksScheduler" element={<TasksScheduler />} />
          <Route
            path="TasksSchedulerBackUp"
            element={<TasksSchedulerBackUp />}
          />
          <Route path="SliderDemo" element={<SliderDemo />} />
          <Route path="ReportAdmin" element={<ReportAdmin />} />
          <Route path="WeeklyTasks" element={<WeeklyTasks />} />
          <Route path="TaskDetails" element={<TaskDetails />} />
          <Route path="VoiceToTextTask" element={<VoiceToTextTask />} />
          <Route
            path="AdminTasksViewPreferences"
            element={<AdminTasksViewPreferences />}
          />
          <Route path="PersonalTaskDetails" element={<PersonalTaskDetails />} />
          <Route path="CategoryDetails" element={<CategoryDetails />} />
          <Route
            path="PersonalCategoryDetails"
            element={<PersonalCategoryDetails />}
          />
          <Route path="AddCategory" element={<AddCategory />} />
          <Route path="EditLocation" element={<EditLocation />} />
          <Route path="AddFrequency" element={<AddFrequency />} />
          <Route
            path="CheckTasksAvailability"
            element={<CheckTasksAvailability />}
          />
          <Route path="CreateNewSchedule" element={<CreateNewSchedule />} />
          <Route path="WeeklyTasksNew" element={<WeeklyTasksNew />} />
          <Route path="SchedulPreview" element={<SchedulPreview />} />
          <Route path="TopBar" element={<TopBar />} />
          <Route path="NotificationPage" element={<NotificationPage />} />
          <Route path="ShiftScheduler" element={<ShiftScheduler />} />
          <Route path="Preference" element={<Preference />} />
          <Route path="Availability" element={<Availability />} />
          <Route path="ShoppingList" element={<ShoppingList />} />
          <Route path="MorePage" element={<MorePage />} />
          <Route path="UserGuideAdmin" element={<UserGuideAdmin />} />
          <Route path="PrivacyPolicyAdmin" element={<PrivacyPolicyAdmin />} />
          <Route path="AboutUsAdmin" element={<AboutUsAdmin />} />
          <Route path="FAQsAdmin" element={<FAQsAdmin />} />
          <Route path="HideAndShowRowDemo" element={<HideAndShowRowDemo />} />
          {/* Employee Screens */}
          <Route path="SplashEmployee" element={<SplashEmployee />} />
          <Route path="LoginEmployee" element={<LoginEmployee />} />
          <Route path="EmployeeTaskList" element={<EmployeeTaskList />} />
          <Route path="MyAvailability" element={<MyAvailability />} />
          <Route path="PersonalTasks" element={<PersonalTasks />} />
          <Route path="AddPersonalTasks" element={<AddPersonalTasks />} />
          <Route
            path="PersonalAddFrequency"
            element={<PersonalAddFrequency />}
          />
          <Route path="PersonalAddCategory" element={<PersonalAddCategory />} />
          <Route path="PersonalTaskNew" element={<PersonalTaskNew />} />
          <Route path="EmployeeTaskListNew" element={<EmployeeTaskListNew />} />
          <Route path="SharedWith" element={<SharedWith />} />
          <Route path="Profile" element={<Profile />} />
          <Route path="ArchivePage" element={<ArchivePage />} />
          {/* <Route path="WeeklyTaskShiftNew" element={<WeeklyTaskShiftNew />} /> */}
          <Route path="AdminTasksListDemo" element={<AdminTasksListDemo />} />
          <Route path="ShiftSwapAndOffer" element={<ShiftSwapAndOffer />} />
          <Route path="EditTaskList" element={<EditTaskList />} />
          <Route path="DragNDropTabsDemo" element={<DragNDropTabsDemo />} />
          <Route path="MoreOptionsEmployee" element={<MoreOptionsEmployee />} />
          <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="AboutUs" element={<AboutUs />} />
          <Route path="UserGuide" element={<UserGuide />} />
          <Route path="FAQs" element={<FAQs />} />
          {/* Super Admin */}
          <Route path="SuperAdminDashboard" element={<SuperAdminDashboard />} />
          <Route path="AddCompany" element={<AddCompany />} />
          <Route path="AddCompanyAdmin" element={<AddCompanyAdmin />} />
          <Route path="EditCompanyAdmin" element={<EditCompanyAdmin />} />
          <Route path="AdminList" element={<AdminList />} />
          <Route path="EditCompany/:id" element={<AddCompany />} />
          <Route path="DragNDrop" element={<DragNDrop />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
